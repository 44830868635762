import React, { useContext, useEffect, useMemo } from "react";
import { Grid, Box, Typography, Chip, Checkbox } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import HtmlTooltip from "../common/HtmlTooltip/HtmlTooltip";
import { formatDate, dateFromNow } from "../../utils/date";
import PatientDetailsTooltip from "../PatientDetailsTooltip/PatientDetailsTooltip";
import PreviewDialog from "../Preview/Preview";
import { PatientContext } from "../../context/PatientsContext";
import authService from "../../services/authService";
import AppContext from "../../context/AppContext";
import styles from "./EpisodeListTable.module.css";
import { INTERUM_STATUS } from "../../constants";
import { CheckedEpisode } from "./EpisodeListPage";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import theme from "../theme";
import AdvancedSearch from "../../model/advanced-search";
import queryString from "query-string";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const Episode: React.FC<any> = ({
  index,
  episode,
  setChecked,
}: {
  index: number;
  episode: CheckedEpisode;
  setChecked: (index: number) => boolean;
}) => {
  const advancedSearch: AdvancedSearch = AdvancedSearch.fromQueryString(queryString.parse(location.search));
  const { providers } = useContext(PatientContext);
  const variables = {
    providers: providers,
    limit: 50,
    adSearch: advancedSearch,
  };
  const isDesktop = useContext(AppContext).isDesktop;
  const isMobility = authService.isMobility();
  const openNewTab = authService.getOpenNewTab();
  const previewMode = useContext(AppContext).previewMode;
  const [checkboxChecked, setCheckboxChecked] = React.useState(episode.checked);
  const [allPatients, setAllPatients] = React.useState(false);
  const [patientClick, setPatientClick] = React.useState(false);
  const [panelClick, setPanelClick] = React.useState("");
  const useStyles = makeStyles(() => ({
    alignRight: {
      textAlign: "right",
    },
  }));

  const classes = useStyles();
  useEffect(() => {
    if (advancedSearch.allpatients !== undefined) {
      setAllPatients(advancedSearch.allpatients);
    }
  }, []);

  const handleChange = () => {
    if (setChecked(index)) {
      setCheckboxChecked(!checkboxChecked);
    }
  };

  const formatDateCreated = (dateCreated: string | null): string => {
    return dayjs(dateCreated || "") >= dayjs().startOf("day").utc()
      ? dateFromNow(dateCreated || "-", true)
      : dayjs(dateCreated || "", "YYYY-MM-DD HH:mm:ssZ") >= dayjs().subtract(1, "days").startOf("day").utc() &&
          dayjs(dateCreated || "", "YYYY-MM-DD HH:mm:ssZ") < dayjs().startOf("day").utc()
        ? "Yesterday"
        : dayjs(dateCreated || "").year() !== dayjs().utc().year()
          ? formatDate(dateCreated || "", "DD MMM  YYYY", false)
          : formatDate(dateCreated || "", "DD MMM", false);
  };

  const checkbox = useMemo(() => {
    return (
      <Checkbox
        color="primary"
        value={episode.patientid + "~" + episode.labnoDigitOnly}
        style={{ margin: 0, padding: 0 }}
        onChange={() => handleChange()}
        checked={checkboxChecked}
      />
    );
  }, [episode, checkboxChecked]);

  const handlePatientDetails = () => {
    setPatientClick(true);
  };

  const handlePanelClick = (panelUrl: string) => {
    setPanelClick(panelUrl);
  };

  useEffect(() => {
    if (patientClick && panelClick === "" && !episode.labnoDigitOnly?.includes("/")) {
      window.open(
        `/patient/${episode.patientid}/${allPatients ? "1" : "0"}/labnumber/${episode.labnoDigitOnly}`,
        `${isDesktop && !isMobility && openNewTab ? "_blank" : "_self"}`,
      );
      setPatientClick(false);
    } else if (panelClick !== "") {
      window.open(`${panelClick}`, `${isDesktop && !isMobility && openNewTab ? "_blank" : "_self"}`);
      setPanelClick("");
      setPatientClick(false);
    }
  }, [patientClick, panelClick]);

  return (
    <Box
      key={index}
      mx={isDesktop ? 3 : 0}
      my={0.2}
      pl={2}
      pr={isDesktop ? 2 : 4}
      py={1}
      className={`patient ${!episode.isRead ? styles.isUnread : ""} ${styles.tableRow} ${
        !isDesktop ? styles.mobileTableRow : ""
      }`}
    >
      <Grid container alignItems="flex-start" key={index}>
        <Grid item xs={"auto"} sm={"auto"} md={"auto"} onClick={handlePatientDetails}>
          <Box mr={2}>
            {episode.isRead ? <DraftsOutlinedIcon color="disabled" /> : <MailOutlinedIcon />}
            {episode.urgentStatus && (
              <PriorityHighIcon htmlColor="#ffffff" fontSize="small" className={styles.urgentEpisode} />
            )}
          </Box>
        </Grid>
        <Grid container item xs sm md>
          <Grid item xs sm md lg={2} onClick={handlePatientDetails}>
            <HtmlTooltip
              classes={{ tooltip: styles.patientDetailTooltip }}
              title={
                <PatientDetailsTooltip patientId={episode.patientid} allPatients={allPatients}></PatientDetailsTooltip>
              }
            >
              {episode.hasAbnormalStatus ? (
                <Typography className={`${!episode.isRead ? "bold" : ""}`} color="error">
                  {episode.fullnameTitleCase}
                </Typography>
              ) : (
                <Typography className={`${!episode.isRead ? "bold" : ""}`}>{episode.fullnameTitleCase}</Typography>
              )}
            </HtmlTooltip>
          </Grid>
          {!isDesktop && (
            <Grid item xs="auto">
              <Typography variant="body2" align="right">
                {formatDateCreated(episode.dateCreated)}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={1} onClick={handlePatientDetails}>
            <Typography variant="body2" color="grey.100">
              {formatDate(episode.dob, "DD MMM YYYY", true)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={1} onClick={handlePatientDetails}>
            <Typography variant="body2" color="grey.100">
              {episode.labnoDigitOnly}
            </Typography>
          </Grid>
          <Grid item xs={12} lg onClick={isDesktop ? handlePatientDetails : undefined}>
            <Typography component={"span"} color="grey.100">
              {isDesktop && episode.panels && episode.panels.length > 0 && !authService.getHidePanel() ? (
                (episode.panels as any[]).map((panel: any, index: number) => {
                  return (
                    <HtmlTooltip
                      classes={{ tooltip: styles.reportDetailTooltip }}
                      enterDelay={1000}
                      enterNextDelay={1000}
                      key={index}
                      title={
                        previewMode ? (
                          <PreviewDialog
                            ids={[panel.reportid]}
                            previewData={{
                              patientid: episode.patientid.toString(),
                              labNumber: episode.labnoDigitOnly || "",
                              allpatient: allPatients ? "1" : "0",
                            }}
                            variables={variables}
                            panel={panel}
                          ></PreviewDialog>
                        ) : (
                          ""
                        )
                      }
                      disableInteractive={false}
                    >
                      <Chip
                        label={
                          <Typography
                            variant="caption"
                            color={panel.hasAbnormalStatus && panel.isRead ? "error" : "inherit"}
                          >
                            {panel.testname}{" "}
                            {panel.urgentStatus && (
                              <PriorityHighIcon
                                htmlColor={panel.isRead ? theme.colorSchemes.light.palette.error.main : "#ffffff"}
                                fontSize="small"
                                className={styles.urgentPanel}
                              />
                            )}
                          </Typography>
                        }
                        size="small"
                        className={
                          panel.interimStatus !== INTERUM_STATUS.Final &&
                          panel.interimStatus !== INTERUM_STATUS.FinalCorrected &&
                          panel.interimStatus !== INTERUM_STATUS.Preliminary
                            ? styles.chipPending
                            : panel.isRead
                              ? styles.chipRead
                              : panel.hasAbnormalStatus
                                ? styles.chipUnreadAbnormal
                                : styles.chipUnread
                        }
                        style={panel.urgentStatus ? { paddingRight: "5px" } : {}}
                        onClick={() => {
                          let uri = `/patient/${episode.patientid}/${allPatients ? "1" : "0"}/labnumber/${
                            episode.labnoDigitOnly
                          }`;
                          if (!episode.labnoDigitOnly?.includes("/")) {
                            uri += `/${panel.labnumber}`;
                          }
                          handlePanelClick(uri);
                        }}
                      />
                    </HtmlTooltip>
                  );
                })
              ) : isDesktop && episode.panels && episode.panels.length > 0 && authService.getHidePanel() ? (
                (episode.panels as any[]).map((panel: any, index: number) => {
                  return (
                    <Chip
                      key={index}
                      label={
                        <Typography
                          variant="caption"
                          color={panel.hasAbnormalStatus && panel.isRead ? "error" : "inherit"}
                        >
                          {panel.interimStatus !== INTERUM_STATUS.Final &&
                          panel.interimStatus !== INTERUM_STATUS.FinalCorrected &&
                          panel.interimStatus !== INTERUM_STATUS.Preliminary
                            ? "Pending"
                            : panel.hasAbnormalStatus
                              ? "Abnormal"
                              : "Normal"}
                        </Typography>
                      }
                      size="small"
                      className={
                        panel.interimStatus !== INTERUM_STATUS.Final &&
                        panel.interimStatus !== INTERUM_STATUS.FinalCorrected &&
                        panel.interimStatus !== INTERUM_STATUS.Preliminary
                          ? styles.chipPending
                          : panel.isRead
                            ? styles.chipRead
                            : panel.hasAbnormalStatus
                              ? styles.chipUnreadAbnormal
                              : styles.chipUnread
                      }
                      style={panel.urgentStatus ? { paddingRight: "5px" } : {}}
                      onClick={() => {
                        handlePanelClick(
                          `/patient/${episode.patientid}/${allPatients ? "1" : "0"}/labnumber/${
                            episode.labnoDigitOnly
                          }/${panel.labnumber}`,
                        );
                      }}
                    />
                  );
                })
              ) : (
                <Box
                  width={"100%"}
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  flexWrap={"nowrap"}
                >
                  <div
                    className="lineClamp"
                    style={{
                      maxWidth: isDesktop ? window.innerWidth * 0.4 : window.innerWidth * 0.7,
                    }}
                  >
                    {!authService.getHidePanel() ? episode.allEpisodeTests : ""}
                  </div>
                  <Grid item sm={1} md={1} lg={1} className={classes.alignRight}>
                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                      Print:
                      {checkbox}
                    </Box>
                  </Grid>
                </Box>
              )}
            </Typography>
          </Grid>

          {isDesktop && (
            <>
              <Grid item xs={12} lg={1} onClick={handlePatientDetails}>
                <Typography variant="body2" color="textSecondary">
                  <div>{episode.referrer || ""}</div>
                </Typography>
              </Grid>
              <Grid item sm={2} md={2} lg={1} onClick={handlePatientDetails}>
                <Typography variant="body2" color="textSecondary" className={`${!episode.isRead ? "bold" : ""}`}>
                  {formatDateCreated(episode.dateCreated)}
                </Typography>
              </Grid>
              <Grid item sm={2} md={2} lg={1} onClick={handlePatientDetails}>
                <Typography variant="body2" color="textSecondary">
                  {episode.status}
                </Typography>
              </Grid>
              <Grid item sm={1} md={1} lg={1}>
                <Box display={"flex"} flexDirection={"row"} justifyContent={"right"}>
                  {checkbox}
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {/* </a> */}
    </Box>
  );
};

export default Episode;
