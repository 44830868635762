import authService from "../services/authService";
// Error messages
export const NO_DB_CONNECTION = `There was a problem with your request. Please wait a few minutes, refresh your page and try again. If the problem persists contact us on ehealth.helpdesk@clinicallabs.com.au.`;
export const COMMON = NO_DB_CONNECTION;
import AuditAction from "./audit-action";
import { isTenantVet } from "../services/tenantService";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

// User guide pdf
const USER_GUIDE_CLINICAL_TRIALS_PDF = "https://go.clinicallabs.com.au/ct-guide";

const USER_GUIDE_STANDARD_PDF = "https://go.clinicallabs.com.au/userguide";

const USER_GUIDE_STANDARD_VET_PDF = "https://go.clinicallabs.com.au/gri-guide";

export function getUserGuide(): string {
  if (isTenantVet()) {
    return USER_GUIDE_STANDARD_VET_PDF;
  }
  const clinicalTrial = authService.getClinicalTrial();
  if (clinicalTrial !== CLINCAL_TRIAL_USER_TYPE.Standard) {
    return USER_GUIDE_CLINICAL_TRIALS_PDF;
  }
  return USER_GUIDE_STANDARD_PDF;
}
// GraphQL Cache Expiry for some queries
export const DEFAULT_CACHE_DURATION = dayjs.duration(15, "minute");

// Delivery Mode used for downloading, printing and sending to queue
export const DELIVERY_MODE_DOWNLOAD = "Download";
export const DELIVERY_MODE_PRINT = "Print";
export const DELIVERY_MODE_SEND = "Send";
export const DELIVERY_MODE_ADDON = "Add-on";
export const DELIVERY_MODE_EORDER = "e-order";
export const DELIVERY_MODE_SHARE = "Share";

export enum APPROVAL_STATUS {
  New = 1,
  Approved = 2,
  Printed = 3,
  Pending = 4,
  Ready_For_Partial_Review = 5,
  Partially_Reviewed = 6,
  Ready_For_Review = 7,
  Offline = 8,
}

export enum CLINCAL_SIGNIFICANCE_STATUS {
  ClinicalSignificant = 1,
  NotClinicalSignificant = 2,
  Indeterminate = 3,
}

export enum CLINCAL_TRIAL_USER_TYPE {
  Standard = "0",
  Viewer = "1",
  Approver = "2",
}

export enum INTERUM_STATUS {
  Pending = "I",
  Cancelled = "X",
  Preliminary = "P",
  Final = "F",
  FinalCorrected = "C",
}

export { AuditAction };

export const DIABETES_RECALL_LETTER_EXAMPLE =
  "https://ehealth-service-static-files.s3.ap-southeast-2.amazonaws.com/media/cpd/Diabetes_Review_Letter_woRef-ACLMAR-BF-NAT-0092.10-MOCKUP.PDF";
export const DIABETES_REFERRAL_FORM_EXAMPLE =
  "https://ehealth-service-static-files.s3.ap-southeast-2.amazonaws.com/media/cpd/Diabetes_Letter_With_Referral-MOCKUP.PDF";
