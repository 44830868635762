import React, { useEffect } from "react";
import get from "lodash/get";
import {
  GetPrintableCombineCtAtomicAuditLog_patient as Patient,
  GetPrintableCombineCtAtomicAuditLog_ctcombineAtomic,
  GetPrintableCombineCtAtomicAuditLog_getAuditLogs,
} from "./types/GetPrintableCombineCtAtomicAuditLog";
import useAxios from "axios-hooks";
import Loading from "../Loading/Loading";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";
import InnerHTML from "dangerously-set-html-content";
import authService from "../../services/authService";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(timezone);

type PrintableCombineAtomicProps = {
  atomics: (GetPrintableCombineCtAtomicAuditLog_ctcombineAtomic | null)[];
  patient: Patient;
  downloadMode: number;
  auditLog: boolean;
  auditLogs: (GetPrintableCombineCtAtomicAuditLog_getAuditLogs | null)[];
};

const PrintableCombineAtomic: React.FC<PrintableCombineAtomicProps> = ({
  atomics,
  patient,
  downloadMode,
  auditLog,
  auditLogs,
}) => {
  const latestReport = atomics.reduce((p, c) => {
    const p_reportid = get(p, "reportId");
    const c_reportid = get(c, "reportId");
    if (p_reportid && c_reportid) {
      return p_reportid > c_reportid ? p : c;
    } else {
      return null;
    }
  });
  const user = authService.getName();

  // eslint-disable-next-line
  const [{ data, loading, error:fetchError }] = useAxios({
    url: `${import.meta.env.VITE_ACL_REPORT_URL}/ctreport`,
    method: "POST",
    data: {
      output: "html",
      data: {
        tz: dayjs.tz.guess(),
        user: { name: user },
        patient: patient,
        reports: atomics,
        auditlogs: {
          auditlog: auditLog,
          auditlogs: auditLogs,
        },
      },
      template: "ctreport-template.html",
    },
  });

  // TEXT
  useEffect(() => {
    const iframe: any = window.parent.document.getElementById("printFrame");
    if (iframe && iframe.contentWindow && data && data.data && downloadMode === 0) {
      if (downloadMode === 0) {
        setTimeout(() => {
          window.parent.postMessage("eResults2.0 Print Ready", "*");
          iframe.scrolling = "no";
          iframe.contentWindow.focus();
          iframe.contentWindow.document.title = `Report ${get(latestReport, "reportLabNumberDigitOnly")} for ${
            patient.fullnameTitleCase
          }`;
          if (document.queryCommandSupported("print")) {
            iframe.contentWindow.document.execCommand("print", false, null);
          } else {
            iframe.contentWindow.print();
          }
        }, 200);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) return <Loading />;

  if (fetchError) {
    return (
      <ErrorPopover
        errorMessage={`There has been an error. Please refresh the page to try again ${fetchError.message}`}
      />
    );
  } else {
    return <InnerHTML html={data.data} />;
  }
};

export default PrintableCombineAtomic;
