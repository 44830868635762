import { gql } from "@apollo/client";

export default gql`
  query getHospitalReports(
    $reportid: [Float!]
    $pageSize: Float
    $page: Float
    $filename: String
    $reporttype: Float
    $reportyear: Float
    $sort: [String!]
  ) {
    getAssignedHospitalReports {
      id
      name
    }
    getReportTypes {
      id
      name
    }
    getReportYears {
      id
      reportyear
    }
    getUserHospitalReports(
      reportid: $reportid
      pageSize: $pageSize
      page: $page
      filename: $filename
      reporttype: $reporttype
      reportyear: $reportyear
      sort: $sort
    ) {
      rows {
        id
        filename
        reportcreated
      }
      rowCount
    }
  }
`;
