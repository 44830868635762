import { gql } from "@apollo/client";
export default gql`
  mutation ResetPassword($input: ChangePasswordTokenInput!) {
    changePasswordForToken(input: $input) {
      accessToken
      username
      expired
      providers
      allowAllPatients
      showDeletedResults
      defaultWardCode
      sharedaccount
      hospid
      wards {
        id
        hospid
        code
        name
      }
      lastlogin
      hospitalAdmin
      hospitalReport
      hospitalReportList
      state
      resultNotification
      notificationEmail
      email
      clinicalTrial
      userId
      smscEnabled
      name
      restrictedClinicalStatuses
      dcfWorkflowEnabled
      actionWorkflowEnabled
      mobileNumber
      repeatAtomicComment
      discoverable
    }
  }
`;
