import React, { useContext, useState, Fragment, useEffect, useLayoutEffect } from "react";
import { withRouter, RouteComponentProps, useHistory } from "react-router";
import PatientDetailHeader from "../PatientDetailHeader/PatientDetailHeader";
import PatientEpisodes from "../PatientEpisodes/PatientEpisodes";
import {
  Box,
  Select,
  MenuItem,
  Checkbox,
  CircularProgress,
  Switch,
  Typography,
  Theme,
  SelectChangeEvent,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import GetPatient from "./GetPatient";
import { useQuery } from "@apollo/client";
import { GetPatient as GetPatientQueryType } from "../PatientDetailsPage/types/GetPatient";
import { GetPatient_patient } from "./types/GetPatient";
import AppContext from "../../context/AppContext";
import PatientReportsFull from "../PatientReportsFull/PatientReportsFull";
import Loading from "../Loading/Loading";
import { EpisodeReportsProps } from "../ReportViewer/EpisodeReportsProps";
import { CumulativeContext } from "../../context/CumulativeContext";
import CumulativePanel from "../CumulativePanel/CumulativePanel";
import TestSearch from "../TestSearch/TestSearch";
import { PatientDetailsContext } from "../../context/PatientDetailsContext";
import authService from "../../services/authService";
import Scrollbars from "react-custom-scrollbars-2";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";
import { PrintMenuIcon, DownloadMenuIcon, ShareMenuIcon } from "../PatientReportsFull/PrintMenu/PrintComponents";
import sortby from "./sort_by.json";
import HtmlTooltip from "../common/HtmlTooltip/HtmlTooltip";
import { useLazyQuery } from "@apollo/client";
import {
  GetPrintableReports,
  GetPrintableReportsVariables,
  GetPrintableReports_episodereports_reports as dReport,
  GetPrintableReports_patient as dPatient,
} from "../PrintableReports/types/GetPrintableReports";
import getPrintableReports from "../PrintableReports/GetPrintableReportQuery";
import axios from "axios";
import get from "lodash/get";
import * as Constants from "../../constants";
import { setVerticalScrollbar } from "../../utils/setVerticalScrollbar";
import SuccessPopover from "../common/SuccessPopover/SuccessPopover";
import PrintFrame from "../ReportViewer/PrintFrame";
import { isTenantVet } from "../../services/tenantService";
import thm from "../theme";
import EorderDialog from "../EorderDialog/EorderDialog";
import eOrderLogoMobile from "../../assets/eOrderButtonMobile.svg";
import eOrderLogoVetMobile from "../../assets/eOrderButtonVetMobile.svg";
import { EORDER_FEATURE, EORDER_BLACKLIST, getSiteOption, showEorderButton } from "../../utils/siteOptions";
import { validateProvider } from "../../utils/provider";
import { downloadHelper } from "../../utils/downloadHelper";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import DialogBoxShare from "../DialogBox/DialogBoxShare";

dayjs.extend(timezone);

type PathParams = {
  id: string;
  allPatients: string;
  labnoDigitOnly: string | null;
  labnumber: string;
  sortBy: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },

  menuItem: {
    backgroundColor: thm.colorSchemes.light.palette.primary.light,
    borderBottomColor: "white",
  },

  selectInput: {
    borderRadius: "4px",
    border: "solid 1px #979797",
    backgroundColor: "#f7f7f7",
    height: "40px",
    marginTop: "8px",
    width: "100%",
  },

  selectInputAuto: {
    borderRadius: "4px",
    border: "solid 1px #979797",
    backgroundColor: "#f7f7f7",
    height: "40px",
    marginTop: "8px",
    width: "auto",
  },

  selectInputMobile: {
    borderRadius: "4px",
    border: "solid 1px #979797",
    backgroundColor: "#f7f7f7",
    height: "40px",
    marginTop: "8px",
    width: "98%",
    maxWidth: "365px",
  },

  hr: {
    marginTop: "15px;",
    width: "98%",
    border: "solid 1px " + thm.colorSchemes.light.palette.primary.light,
  },

  btnPrint: {
    borderRadius: "25px",
    height: "35px",
  },
  eOrderLogoMobile: {
    width: "45px",
    margin: "-5px 0 -4px 0",
  },
  eOrderButtonSmall: {
    backgroundColor: "#ffffff !important",
    borderRadius: "20px !important",
    marginTop: "3px",
    fontSize: "1em !important",
    textTransform: "none",
    border: "solid 2px #753bbd !important",
    height: "32px !important",
    "&:hover": {
      backgroundColor: "#ede1f7 !important",
      color: "white !important",
    },
  },
}));

const useWindowSize = () => {
  const [height, setHeight] = useState(0);
  useLayoutEffect(() => {
    function updateHeight() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", updateHeight);
    updateHeight();
    return () => window.removeEventListener("resize", updateHeight);
  }, []);
  return height;
};

const PatientDetailsPage: React.FC<RouteComponentProps> = (props) => {
  const { match } = props;
  const history = useHistory();

  const pathParams = match.params as PathParams;
  const id: number = parseInt(pathParams.id);
  const isDesktop = useContext(AppContext).isDesktop;
  const { error, setError } = useContext(AppContext);
  const { isCumulative } = useContext(CumulativeContext);
  const allPatients =
    pathParams.allPatients === "1" &&
    authService.getAllowAllPatients() &&
    authService.getAllowAllPatientsAcknownledged();

  const [urno, setUrno] = useState("");
  const [ward, setWard] = useState("");
  const [referringDoctor, setReferringDoctor] = useState("");
  const mobility = authService.isMobility();

  const { initialLabNo, setInitialLabNo, labnoDigitOnly, setLabnoDigitOnly } = useContext(PatientDetailsContext);

  const getCookie = (cname: string) => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  const [getSortBy] = useState<any>(JSON.parse(JSON.stringify(sortby)));
  const defSortEpisode = getCookie("sortEpisode") === "" ? "dateCollected" : getCookie("sortEpisode");
  const [sortBy, setSortBy] = useState(defSortEpisode);
  const [sortData, setSortData] = useState("");
  const [reportIdSelected, setReportIdSelected] = useState<any[]>([]);
  const [allReportIds, setAllReportIds] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [rescroll, setRescroll] = useState(true);
  const [panel, setPanel] = useState(-1);
  const [print, setPrint] = useState(false);
  const height = useWindowSize();
  // const [isQueuedLoading, setIsQueuedLoading] = useState(false);
  // const setSuccess = useContext(AppContext).setSuccess;
  const [openEorderDialog, setOpenEorderDialog] = React.useState(false);
  const [ready, setReady] = React.useState(false);
  const [showGenEorderButton, setShowGenEorderButton] = React.useState(false);
  const [openShare, setOpenShare] = useState(false);

  const eOrderFeature = (getSiteOption(EORDER_FEATURE) as string) === "ON";
  const siteOptions = getSiteOption(EORDER_BLACKLIST) as string[];

  const [allReportFetch, setAllReportFetch] = useState(false);

  const [patientInfo, setPatientInfo] = useState<GetPatient_patient | undefined>();

  useEffect(() => {
    authService.getProviders().map((value) => {
      if (showEorderButton(siteOptions, value) && validateProvider(value as string)) {
        setShowGenEorderButton(true);
      }
    });
  }, []);

  const selectSort = (event: SelectChangeEvent<string>) => {
    setSortBy(event.target.value as string);
    if (event.target.value !== "") {
      setSortData(event.target.value as string);
      setLabnoDigitOnly("UNKNOWN");
      setInitialLabNo("");
    } else {
      setSortData("");
    }
    document.cookie = "sortEpisode=" + (event.target.value as string) + ";expires=Fri, 31 Dec 9999 23:59:59 GMT";
  };

  const [showRemovedPanels, setShowRemovedPanels] = useState(authService.getShowDeletedPanels());

  const classes = useStyles();

  /* if (labnoDigitOnly === "UNKNOWN") {
    setLabnoDigitOnly(pathParams.labnoDigitOnly? pathParams.labnoDigitOnly : "UNKNOWN");
  }*/

  useEffect(() => {
    // Anything in here is fired on component mount.
    window.addEventListener("load", () => {
      setVerticalScrollbar(document.documentElement.clientHeight);
    });

    window.addEventListener("resize", () => {
      setVerticalScrollbar(document.documentElement.clientHeight);
    });

    if (initialLabNo === "") {
      setInitialLabNo(pathParams.labnumber);
    }
    return () => {
      // Anything in here is fired on component unmount.
      document.body.style.overflowY = "auto";
    };
  }, []);

  useEffect(() => {
    setInitialLabNo(pathParams.labnumber);

    if (labnoDigitOnly === "UNKNOWN") {
      setLabnoDigitOnly(pathParams.labnoDigitOnly ? pathParams.labnoDigitOnly : "UNKNOWN");
    }
    if (pathParams.sortBy !== undefined && pathParams.sortBy !== "" && pathParams.sortBy !== "undefined") {
      setSortBy(pathParams.sortBy);
      setSortData(pathParams.sortBy);
    } else {
      if (defSortEpisode !== "") {
        setSortData(defSortEpisode);
      } else {
        setSortData("dateCollected"); // Default ones here..
      }
    }
  }, [
    pathParams.labnumber,
    setInitialLabNo,
    pathParams.sortBy,
    defSortEpisode,
    setLabnoDigitOnly,
    pathParams.labnoDigitOnly,
    labnoDigitOnly,
  ]);

  const queryVariables = (): GetPrintableReportsVariables => ({
    patientId: id,
    providers: authService.getProviders(),
    includePdf: true,
    allPatients: allPatients,
    ids: reportIdSelected.map((r) => parseInt(r)),
  });

  const [getPrintableReportsQuery, { data, error: printError }] = useLazyQuery<GetPrintableReports>(
    getPrintableReports,
    {
      variables: queryVariables(),
    },
  );

  const {
    data: patientData,
    loading: patientLoading,
    error: patientError,
  } = useQuery<GetPatientQueryType>(GetPatient, {
    variables: { patientId: id },
  });

  const patient: GetPatient_patient | undefined = patientData ? patientData.patient : undefined;
  useEffect(() => {
    if (isDownloadLoading) {
      const hasValidData = (testData: GetPrintableReports | undefined) =>
        testData && testData.episodereports && testData.episodereports.reports;

      if (hasValidData(data)) {
        const url = `${import.meta.env.VITE_ACL_REPORT_URL}/report`;
        const dreports = get(data, "episodereports.reports") || ([] as dReport[]);
        const dpatient: dPatient = get(data, "patient") || ({} as dPatient);

        const downRepData: any = {};
        downRepData.patient = dpatient;
        downRepData.reports = dreports.filter(
          (r: { reportid: any }) => reportIdSelected.find((p) => p === (r.reportid || 0).toString()) !== undefined,
        );
        downRepData.tz = dayjs.tz.guess();

        axios
          .post<any>(
            url,
            {
              output: "pdf",
              data: downRepData,
              template: isTenantVet() ? "gribbles-template.html" : "report-template.html",
            },
            { responseType: "blob" },
          )
          .then((response) => {
            downloadHelper(response.data, `reports-${labnoDigitOnly}.pdf`);
            setIsDownloadLoading(false);
            setReportIdSelected([]);
            setError("");
          })
          .catch((e) => {
            setIsDownloadLoading(false);
            setError(e.message ? e.message : e);
          });
      }
    }

    if (patient) {
      setPatientInfo(patient);
    }
    //eslint-disable-next-line
  }, [data, isDownloadLoading, reportIdSelected]);

  useEffect(() => {
    if (patientData?.patient) {
      setPatientInfo(patientData?.patient);
    }
  }, [patientData?.patient]);

  if (patientLoading) {
    return <Loading />;
  }

  if (patientError || printError) {
    return <ErrorPopover />;
  }

  if (!patient) {
    return null;
  } else {
    document.title = `${patient.fullnameTitleCase} - eResults Viewer`;

    // setPatientInfo(patient);
  }

  const setUpdatedCheckEpisode = (reportId: string) => {
    if (reportIdSelected.includes(reportId)) {
      const index = reportIdSelected.indexOf(reportId);
      if (index > -1) {
        const updatedReportIdSelected = reportIdSelected.slice();
        updatedReportIdSelected.splice(index, 1);
        setReportIdSelected(updatedReportIdSelected);
      }
    } else {
      //add here
      const updatedReportIdSelected = [...reportIdSelected, reportId];
      setReportIdSelected(updatedReportIdSelected);
    }
    //setRescroll(false);
  };

  const reportProps: EpisodeReportsProps = {
    patientId: id,
    labnoDigitOnly: labnoDigitOnly,
  };

  const receiveMessage = (event: any): void => {
    if (event.data && event.data === "eResults2.0 Print Ready") {
      window.removeEventListener("message", receiveMessage, false);
      setIsLoading(false);
      setIsDownloadLoading(false);
    }
  };

  const printReports = () => {
    setIsLoading(true);
    window.addEventListener("message", receiveMessage, false);

    // Defer until rerender, prevents jank
    if (!authService.fetchToken()?.accessToken) {
      history.push("/login", { from: history.location });
    } else {
      setTimeout(() => {
        //Marked all report id as viewed..
        setPrint(true);

        const reportsCsv: string = reportIdSelected.join(",");
        const iframe: any = document.querySelector("#printFrame");
        iframe.src = `/patient-print/${id}/1/labnumber/0?ids=${reportsCsv}&frame=printFrame`;
      }, 200);
    }
  };

  if (error) {
    const errorMessage = Constants.NO_DB_CONNECTION;

    setError(errorMessage);
  }

  const downloadReports = () => {
    setIsDownloadLoading(true);
    getPrintableReportsQuery({ variables: queryVariables() });
  };

  // const onQueueDownloads =
  //   (reportIds: number[], queueDownloads: MutationTuple<QueueDownloads, QueueDownloadsVariables>[0]) => async () => {
  //     try {
  //       setIsQueuedLoading(true);

  //       const result = await queueDownloads({
  //         variables: {
  //           input: {
  //             reportIds,
  //           },
  //         },
  //       });

  //       if (get(result, "data.queueDownloads") === "Queued report(s)") {
  //         setIsQueuedLoading(false);
  //         setSuccess(
  //           "Report(s) queued for download. <br> Please note that only this report will be sent. Any future updates will not be received.",
  //         );
  //         setReportIdSelected([]);
  //         return <SuccessPopover />;
  //       }
  //     } catch {
  //       setIsQueuedLoading(false);
  //     }
  //   };

  const handleShowRemovedPanelsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // //Set the cookie here..
    // document.cookie =
    //   "showDeleted=" +
    //   (event.target.checked ? "true" : "false") +
    //   ";expires=Fri, 31 Dec 9999 23:59:59 GMT";
    authService.setFlagUserHasChangedShowDeletedPanel();
    authService.setShowDeletedPanels(event.target.checked);
    setShowRemovedPanels(event.target.checked);
  };

  const handleEorderDialogClose = () => {
    setOpenEorderDialog(false);
  };

  const handleEorderDialogOpen = () => {
    setOpenEorderDialog(true);
    console.log(ready);
  };

  const headerHeightDesktop = mobility ? 165 : 144;
  const testSearchHeight = 160;
  return (
    <>
      <Box display="flex" flexDirection="column" minHeight={isDesktop ? "99vh" : "150vh"} overflow={"hidden"}>
        <PatientDetailHeader
          patient={patient}
          latestUrNumber={urno}
          ward={ward}
          referringDoctor={referringDoctor}
        ></PatientDetailHeader>
        {isDesktop ? (
          <Box display="flex" height={height - headerHeightDesktop} width={"100%"} overflow={"hidden"}>
            <Box display={"inline"} minWidth={"400px"}>
              <Box mt={2} mb={1} mx={2}>
                <TestSearch patientId={id} labnoDigitOnly={labnoDigitOnly} allPatients={allPatients} />
                <div className={classes.root}>
                  <Box m={1} display="flex" justifyContent="space-between" flexWrap="wrap" alignItems="center">
                    <Box>
                      <HtmlTooltip
                        title={
                          <Typography display={"inline"} variant={"inherit"}>
                            Removed panels are extra panels that have been performed as a necessary step in obtaining a
                            patient test result.
                          </Typography>
                        }
                      >
                        <Typography display="inline" variant="body1">
                          <Switch
                            checked={showRemovedPanels}
                            onChange={handleShowRemovedPanelsChange}
                            name="showRemovedPanels"
                            color="primary"
                          />
                          Show Removed Panels
                        </Typography>
                      </HtmlTooltip>
                    </Box>
                  </Box>
                </div>
                {allReportIds.length > 0 ? (
                  <div className={classes.root}>
                    <Box m={1} display="flex" justifyContent="space-between" flexWrap="wrap" alignItems="center">
                      <Box>
                        <Checkbox
                          checked={reportIdSelected.length === allReportIds.length ? true : false}
                          onChange={() => {
                            const updatedReportIdSelected =
                              reportIdSelected.length === allReportIds.length ? [] : allReportIds.slice();

                            setReportIdSelected(updatedReportIdSelected);
                          }}
                          name="checkAllEpisode"
                          color="primary"
                          indeterminate={
                            reportIdSelected.length > 0 && reportIdSelected.length < allReportIds.length ? true : false
                          }
                          // className={styles.episodeCheckbox}
                        />{" "}
                        <Typography display="inline" variant="body1">
                          Select All
                        </Typography>
                      </Box>

                      <Box display="flex" flexDirection="row">
                        {!allPatients ? (
                          <Box textAlign="center" p={1}>
                            <ShareMenuIcon
                              onClick={() => {
                                setOpenShare(true);
                              }}
                              disabled={reportIdSelected.length === 0}
                            />
                          </Box>
                        ) : (
                          ""
                        )}

                        {/* {isSmscEnabled ? (
                          <Box textAlign="center" p={1}>
                            {isQueuedLoading ? (
                              <CircularProgress size={16} />
                            ) : (
                              <SendMenuIcon
                                onClick={onQueueDownloads(
                                  reportIdSelected.map((r) => parseInt(r)),
                                  queueDownloads,
                                )}
                                disabled={isQueuedLoading || reportIdSelected.length === 0}
                              />
                            )}
                          </Box>
                        ) : null} */}
                        <Box textAlign="center" p={1}>
                          {isDownloadLoading ? (
                            <CircularProgress size={16} />
                          ) : (
                            <DownloadMenuIcon
                              onClick={downloadReports}
                              title={"Download Selected"}
                              disabled={isDownloadLoading || reportIdSelected.length === 0}
                            />
                          )}
                        </Box>

                        {!mobility ? (
                          <Box textAlign="center" p={1}>
                            {isLoading ? (
                              <CircularProgress size={16} />
                            ) : (
                              <PrintMenuIcon
                                onClick={printReports}
                                title={"Print Selected"}
                                disabled={isLoading || reportIdSelected.length === 0}
                              />
                            )}
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                  </div>
                ) : (
                  ""
                )}
                <hr className={classes.hr}></hr>
                <div className={classes.root}>
                  <Box>
                    <Select
                      id="sort_by"
                      value={sortBy}
                      onChange={selectSort}
                      variant="outlined"
                      className={`${isDesktop ? classes.selectInput : classes.selectInputMobile}`}
                    >
                      {/* <MenuItem value={0}>Sort By</MenuItem> */}
                      {getSortBy["sort_by"].map((item: any) => {
                        return (
                          <MenuItem className={classes.menuItem} key={item.id} value={item.value}>
                            {item.value === sortBy ? "Sort By : " : ""}
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Box>
                </div>
              </Box>

              <Box height={height - headerHeightDesktop - testSearchHeight} mx={0}>
                {/* <CustomScrollbar height={window.innerHeight - headerHeightDesktop - testSearchHeight} ml={2}> */}
                <PatientEpisodes
                  history={props.history}
                  patientId={id}
                  setUrno={setUrno}
                  setWard={setWard}
                  setReferringDocotr={setReferringDoctor}
                  setLabnoDigitOnly={setLabnoDigitOnly}
                  labnoDigitOnly={labnoDigitOnly}
                  setInitialLabNo={setInitialLabNo}
                  allPatients={allPatients}
                  sortBy={sortData}
                  setChangeSelected={setReportIdSelected}
                  reportIdSelected={reportIdSelected}
                  setCheckEpisode={setUpdatedCheckEpisode}
                  allReportIds={allReportIds}
                  setAllReportIds={setAllReportIds}
                  allReportFetch={allReportFetch}
                  setAllReportFetch={setAllReportFetch}
                  showRemovedPanels={showRemovedPanels}
                  rescroll={rescroll}
                  setRescroll={setRescroll}
                  panel={panel}
                  setPanel={setPanel}
                  isDesktop={isDesktop}
                  print={print}
                  setPrint={setPrint}
                  patient={patientInfo}
                />
                {/* </CustomScrollbar> */}
              </Box>
            </Box>
            <Box display={"inline"} width={"100%"}>
              {isCumulative ? (
                <CumulativePanel
                  allPatients={allPatients}
                  sortBy={sortBy}
                  showRemovedPanels={showRemovedPanels}
                  height={height - headerHeightDesktop}
                  patient={patientInfo}
                />
              ) : (
                <PatientReportsFull
                  reportsProps={reportProps}
                  initialLabNo={initialLabNo}
                  setInitialLabNo={setInitialLabNo}
                  allPatients={allPatients}
                  sortBy={sortBy}
                  patient={patientInfo}
                />
              )}
            </Box>
          </Box>
        ) : (
          <Fragment>
            <Box mt={2} mb={0} ml={1} mr={1}>
              <TestSearch patientId={id} labnoDigitOnly={labnoDigitOnly} allPatients={allPatients} />
              <div className={classes.root}>
                <Box ml={1} display="flex" justifyContent="space-between" flexWrap="wrap" alignItems="center">
                  <Box>
                    <HtmlTooltip
                      title={
                        <Typography display={"inline"} variant={"inherit"}>
                          Removed panels are extra panels that have been performed as a necessary step in obtaining a
                          patient test result.
                        </Typography>
                      }
                    >
                      <Typography display="inline" variant="body2">
                        <Switch
                          checked={showRemovedPanels}
                          onChange={handleShowRemovedPanelsChange}
                          name="showRemovedPanels"
                          color="primary"
                        />
                        Show Removed Panels
                      </Typography>
                    </HtmlTooltip>
                  </Box>
                </Box>
              </div>
              {allReportIds.length > 0 ? (
                <div className={classes.root}>
                  <Box ml={1} display="flex" justifyContent="space-between" flexWrap="wrap" alignItems="center">
                    <Box>
                      <Checkbox
                        checked={reportIdSelected.length === allReportIds.length ? true : false}
                        onChange={() => {
                          const updatedReportIdSelected =
                            reportIdSelected.length === allReportIds.length ? [] : allReportIds.slice();

                          setReportIdSelected(updatedReportIdSelected);
                        }}
                        name="checkAllEpisode"
                        color="primary"
                        indeterminate={
                          reportIdSelected.length > 0 && reportIdSelected.length < allReportIds.length ? true : false
                        }
                        // className={styles.episodeCheckbox}
                      />{" "}
                      <Typography display="inline" variant="body2">
                        Select All
                      </Typography>
                    </Box>

                    <Box display="flex" flexDirection="row">
                      {eOrderFeature && showGenEorderButton && (
                        <>
                          <Button className={classes.eOrderButtonSmall} onClick={handleEorderDialogOpen}>
                            <img
                              src={isTenantVet() ? eOrderLogoVetMobile : eOrderLogoMobile}
                              alt="eorder logo"
                              className={classes.eOrderLogoMobile}
                            />
                          </Button>
                          <EorderDialog
                            open={openEorderDialog}
                            fullscreen={false}
                            onClose={handleEorderDialogClose}
                            patient={patientInfo as GetPatient_patient}
                            setReady={setReady}
                          />
                        </>
                      )}
                      {!allPatients ? (
                        <Box textAlign="center" p={1}>
                          <ShareMenuIcon
                            onClick={() => {
                              setOpenShare(true);
                            }}
                            disabled={reportIdSelected.length === 0}
                          />
                        </Box>
                      ) : (
                        ""
                      )}

                      {/* {isSmscEnabled ? (
                        <Box textAlign="center" p={1}>
                          {isQueuedLoading ? (
                            <CircularProgress size={16} />
                          ) : (
                            <SendMenuIcon
                              onClick={onQueueDownloads(
                                reportIdSelected.map((r) => parseInt(r)),
                                queueDownloads,
                              )}
                              disabled={isQueuedLoading || reportIdSelected.length === 0}
                            />
                          )}
                        </Box>
                      ) : null} */}

                      <Box textAlign="center" p={1}>
                        {isDownloadLoading ? (
                          <CircularProgress size={16} />
                        ) : (
                          <DownloadMenuIcon
                            onClick={downloadReports}
                            title={"Download Selected"}
                            disabled={isLoading || reportIdSelected.length === 0}
                          />
                        )}
                      </Box>

                      {!mobility ? (
                        <Box textAlign="center" p={1}>
                          {isLoading ? (
                            <CircularProgress size={16} />
                          ) : (
                            <PrintMenuIcon
                              onClick={printReports}
                              title={"Print Selected"}
                              disabled={isLoading || reportIdSelected.length === 0}
                            />
                          )}
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                </div>
              ) : (
                ""
              )}
              <hr className={classes.hr}></hr>
              <div className={classes.root}>
                <Box m={1}>
                  <Select
                    id="sort_by"
                    value={sortBy}
                    onChange={selectSort}
                    variant="outlined"
                    className={`${isDesktop ? classes.selectInput : classes.selectInputMobile}`}
                  >
                    {/* <MenuItem value={0}>Sort By</MenuItem> */}
                    {getSortBy["sort_by"].map((item: any) => {
                      return (
                        <MenuItem className={classes.menuItem} key={item.id} value={item.value}>
                          {item.value === sortBy ? "Sort By : " : ""}
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
              </div>
            </Box>
            <Box height="100vh" mx={1}>
              <Scrollbars>
                <PatientEpisodes
                  history={props.history}
                  patientId={id}
                  setUrno={setUrno}
                  setWard={setWard}
                  setReferringDocotr={setReferringDoctor}
                  setLabnoDigitOnly={setLabnoDigitOnly}
                  labnoDigitOnly={labnoDigitOnly}
                  setInitialLabNo={setInitialLabNo}
                  allPatients={allPatients}
                  sortBy={sortData}
                  setChangeSelected={setReportIdSelected}
                  reportIdSelected={reportIdSelected}
                  setCheckEpisode={setUpdatedCheckEpisode}
                  allReportIds={allReportIds}
                  setAllReportIds={setAllReportIds}
                  allReportFetch={allReportFetch}
                  setAllReportFetch={setAllReportFetch}
                  showRemovedPanels={showRemovedPanels}
                  rescroll={rescroll}
                  setRescroll={setRescroll}
                  panel={panel}
                  setPanel={setPanel}
                  isDesktop={isDesktop}
                  print={print}
                  setPrint={setPrint}
                  patient={patientInfo}
                />
              </Scrollbars>
            </Box>
          </Fragment>
        )}
        <DialogBoxShare
          open={openShare}
          onClose={() => setOpenShare(false)}
          onSetOpenShare={(st: boolean) => setOpenShare(st)}
          reports={Object.fromEntries(reportIdSelected.map((item) => [item, true]))}
          patient={id}
          labno={labnoDigitOnly}
        />
        {patientError || printError ? <ErrorPopover /> : null}
        <SuccessPopover />
      </Box>

      <PrintFrame />
    </>
  );
};

export default withRouter(PatientDetailsPage);
