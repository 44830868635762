import React, { Fragment, useContext } from "react";
import styles from "./MainNavigation.module.css";
import authService from "../../services/authService";
import { Box, useMediaQuery, Link, IconButton, Theme } from "@mui/material";
import { useTheme } from "@mui/styles";
import imageEresults from "../../assets/eResults Logo - White.png";
import imageEresultsG from "../../assets/eResults Logo - White-green.svg";
import imageAcl from "../../assets/clinicallabs logo.png";
import imageVet from "../../assets/vet-logo.png";
import TopMenu from "../TopMenu/TopMenu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { animateScroll } from "react-scroll";
import { setSearchText } from "../PatientListPage/PatientListPage";
import MainNavigationHeader from "../MainNavigationHeader/MainNavigationHeader";
import { useHistory, useLocation } from "react-router-dom";
import advancedSearch from "../../model/advanced-search";
import NotificationInfoMenu from "../NotificationInfoMenu/NotificationInfoMenu";
import AppContext from "../../context/AppContext";
import { isTenantVet } from "../../services/tenantService";
import thm from "../theme";
import GoBackButton from "../GeneralHeader/GoBackButton";

type MainNavigationProps = {
  scrollCount?: number;
  hideTopMenu?: boolean;
  isAnnouncementAvai?: boolean;
  showBackButton?: boolean;
  hideSeparator?: boolean;
  goBack?: boolean;
  backPath?: string;
  backText?: string;
  showEorderButton?: boolean;
  mainHeader?: JSX.Element | string;
  subHeader?: string;
  showRefreshButton?: boolean;
  hideProviders?: boolean;
  isCpd?: boolean;
  displayACL?: boolean;
  isHospitalReports?: boolean;
};

export const MainNavigation: React.FC<MainNavigationProps> = (props) => {
  const theme = useTheme<Theme>();
  const matches: boolean = useMediaQuery(theme.breakpoints.up("sm"));
  const showWelcomeBar = "block";
  const history = useHistory();
  const location = useLocation<{ search: string }>();
  const mobility = authService.isMobility();
  const isDesktop = useContext(AppContext).isDesktop;

  let showTopMenu = authService.isAuthenticated();
  if (showTopMenu && props.hideTopMenu) {
    showTopMenu = false;
  }

  const scrollToAnnouncement = () => {
    animateScroll.scrollToBottom();
  };

  const onBack = () => {
    if (location.state) {
      history.push(`/patients${location.state.search}`);
    } else if (props.goBack) {
      history.goBack();
    } else if (props.backPath) {
      history.push(props.backPath);
    } else {
      history.push(`/patients`);
    }
  };

  let from = authService.isClinicalTrial() && !authService.isMobility() ? "/patient-episodes" : "/patients";
  let newquerystring = "";
  if (!props.hideTopMenu && authService.getDefaultWardCode()) {
    const wards = authService.getWards();

    const AdSearch: advancedSearch = new advancedSearch();
    AdSearch.wardCode = wards.find((w) => w.code === authService.getDefaultWardCode());
    newquerystring = `?${AdSearch.toQueryString()}`;
    // eslint-disable-next-line no-const-assign
    from = `/patients${newquerystring}`;
  }

  const fullRefresh = () => {
    if (!props.hideTopMenu) {
      setSearchText("");
      history.push(from);
    }
  };

  return (
    <Fragment>
      <Box display={showWelcomeBar}>
        <Box
          display="flex"
          justifyContent="space-between"
          align-content="strech"
          width={1}
          className={mobility ? styles.welcomeBgGradMobility : styles.welcomeBgGrad}
          pb={props.isCpd ? 2 : 0}
        >
          <Box display="flex" flexDirection="column" flexGrow={1} mt={2} ml={4}>
            {props.showBackButton && (
              <Link onClick={onBack}>
                <GoBackButton backText={props.backText || "Back"} />
              </Link>
            )}
            {props.mainHeader ? (
              props.mainHeader
            ) : (
              <a href={from} onClick={fullRefresh}>
                <img
                  src={isTenantVet() ? imageEresultsG : imageEresults}
                  alt="acl logo"
                  className={`${matches ? styles.logo_135px : styles.logo_100px}`}
                />
              </a>
            )}
          </Box>
          <Box sx={{ display: { xs: "none", lg: "block" } }} mt={2} ml={4}>
            {isDesktop ? <NotificationInfoMenu /> : ""}
          </Box>
          <Box mt={3} ml={3} display="flex">
            {props.displayACL && (
              <Box display="inline">
                <a href={from} onClick={fullRefresh}>
                  <img
                    src={isTenantVet() ? imageVet : imageAcl}
                    alt="acl logo"
                    className={isTenantVet() ? styles.logo_156px : styles.logo_185px}
                  />
                </a>
              </Box>
            )}
          </Box>
          <Box ml={6} mt={3} mr={2}>
            {showTopMenu && <TopMenu />}
            {props.hideTopMenu && props.isAnnouncementAvai && (
              <IconButton
                onClick={() => {
                  scrollToAnnouncement();
                }}
              >
                <NotificationsIcon className={styles.notify} />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
      {authService.isAuthenticated() && !props.hideTopMenu ? (
        <Box
          bgcolor={thm.colorSchemes.light.palette.primary.main}
          justifyContent="space-between"
          align-content="strech"
          py={[props.isCpd ? 0 : 1]}
        >
          <MainNavigationHeader
            showEorderButton={props.showEorderButton}
            subHeader={props.subHeader}
            showRefreshButton={props.showRefreshButton}
            hideProviders={props.hideProviders}
            isCpd={props.isCpd}
            isHospitalReports={props.isHospitalReports}
          />
        </Box>
      ) : (
        ""
      )}

      {!props.hideSeparator && <div className={styles.separator}></div>}
    </Fragment>
  );
};

export default MainNavigation;
