import gql from "graphql-tag";
import { WardInput } from "../graphql/types/gql-global-types";
import jwt from "jsonwebtoken";
import * as MFATypes from "../model/MFATypes";
import { get } from "lodash";
import { CLINCAL_TRIAL_USER_TYPE } from "../constants";
import { GetOptions_getOptions } from "../components/App/types/GetOptions";
import ExpiredStorage from "expired-storage";
import { validateProvideNumber } from "@ehealth/ehealthutils";

const COGNITO_ACCESS_TOKEN = "cognito-access-token";
const COGNITO_IDTOKEN = "cognito-idtoken";
const COGNITO_REFRESH_TOKEN = "cognito-refresh-token";
const ACCESS_TOKEN = "token";
const EXPIRED = "expired-password";
const PROVIDERS = "providers";
const CPD_PROVIDERS = "cpd-providers";
const WARDS = "wards";
const USERNAME = "username";
const USERID = "user_id";
const ALLOW_ALL_PATIENTS = "allow-all-patients";
const ALLOW_ALL_PATIENTS_ACK = "allow-all-patients-acknowledged";
const DEFAULT_WARD_CODE = "defaultWardCode";
const SHARED_ACCOUNT = "shared-account";
const SHOW_DELETED_PANELS = "show-deleted-panels";
const LASTLOGIN = "lastlogin";
const PROVIDERSELECTED = "providerSelected";
const CPD_PROVIDERSELECTED = "cpdProviderSelected";
const USER_CHANGED_SHOW_DELETED_PANEL = "user-changed-deleted-panel";
const MOBILITY = "mobility";
const HOSPITAL_ADMIN = "hosp-admin";
const HOSPITAL_REPORT = "hosp-report";
const HOSPITAL_REPORT_LIST = "hosp-report-list";
const HOSPID = "hospid";
const RESTRICTED_CLINICAL_STATUSES = "RestrictedClinicalStatuses";
const DCF_WORKFLOW_ENABLED = "dcfWorkflowEnabled";
const ACTION_WORKFLOW_ENABLED = "actionWorkflowEnabled";
const RESULT_NOTIFICATION = "1";
const NOTIFICATION_EMAIL = "notification-email";
const REMEMBER_ME = "remember-username";
const DISCOVERABLE = "discoverable";
const STATE = "state";
const PRIMARY_EMAIL = "primary-email";
const MOBILE_NUMBER = "mobile-number";
const ERESULTS_MOBILE_PASS = "x-object";
const CLINICAL_TRIAL = "clinical_trial";
const REPORT_VIEW = "report_view";
const OPEN_NEW_TAB = "OpenNewTab";
const ADOPEN = "ad-open";
const SMSC_ENABLED = "smsc-enabled";
const DELEGATED_USER = "delegated-user";
const NAME = "name";
const LAST_VIEWED = "last-viewed";
const SITE_OPTIONS = "site-options";
const REPORTS_BLOB = "reports-blob";
const REPEAT_ATOMIC_COMMENT = "repeatAtomicComment";
const FGP = "user-fgp";
const SEARCHTEXT = "searchtext";
const MOBILE_DEVICE_ID = "mobileDeviceId";
const HIDE_PANEL = "hidePanel";
const AUTO_SEARCH = "autoSearch";

export const SET_AUTH = gql`
  query SetAuth {
    auth {
      isAuth
    }
  }
`;

export const persistToken = (token: string): void => {
  sessionStorage.setItem(ACCESS_TOKEN, token);
};

export const persistCognitoToken = (accessToken: string, idToken: string, refreshToken?: string): void => {
  sessionStorage.setItem(COGNITO_ACCESS_TOKEN, accessToken);
  sessionStorage.setItem(COGNITO_IDTOKEN, idToken);
  sessionStorage.setItem(COGNITO_REFRESH_TOKEN, refreshToken || "");
};
export const fetchToken = (): {
  accessToken: string | null;
  cognitoToken: { COGNITO_ACCESS_TOKEN?: string; COGNITO_IDTOKEN?: string; COGNITO_REFRESH_TOKEN?: string };
} | null => {
  return {
    accessToken: sessionStorage.getItem(ACCESS_TOKEN),
    cognitoToken: {
      COGNITO_ACCESS_TOKEN: sessionStorage.getItem(COGNITO_ACCESS_TOKEN) || "",
      COGNITO_IDTOKEN: sessionStorage.getItem(COGNITO_IDTOKEN) || "",
      COGNITO_REFRESH_TOKEN: sessionStorage.getItem(COGNITO_REFRESH_TOKEN) || "",
    },
  };
};

export const fetchCognitoToken = (): {
  accessToken: string | null;
  refreshToken: string | null;
  idToken: string | null;
  eResults: any;
} | null => {
  if (sessionStorage.getItem(COGNITO_ACCESS_TOKEN)) {
    const idToken: any = sessionStorage.getItem(COGNITO_IDTOKEN);
    const idTokenObj = jwt.decode(idToken) as any;
    if (idToken && idTokenObj["eresults"]) {
      const eResults = JSON.parse(idTokenObj["eresults"]);
      return {
        accessToken: sessionStorage.getItem(COGNITO_ACCESS_TOKEN),
        refreshToken: sessionStorage.getItem(COGNITO_REFRESH_TOKEN),
        idToken: sessionStorage.getItem(COGNITO_IDTOKEN),
        eResults: eResults,
      };
    }
  }
  return null;
};
export const isCognito = (): boolean => {
  if (sessionStorage.getItem(COGNITO_ACCESS_TOKEN)) {
    return true;
  } else {
    return false;
  }
};
export const isAuthenticated = (): boolean => {
  return !!fetchToken()?.accessToken || !!isCognito();
};

export const clearToken = (): void => {
  sessionStorage.removeItem(ACCESS_TOKEN);
  sessionStorage.removeItem(COGNITO_ACCESS_TOKEN);
  sessionStorage.removeItem(COGNITO_IDTOKEN);
  sessionStorage.removeItem(COGNITO_REFRESH_TOKEN);
};

export const getLastViewed = (): string => localStorage.getItem(LAST_VIEWED) || "";

export const setLastViewed = (page: string): void => localStorage.setItem(LAST_VIEWED, page);

export const clearLastViewed = (): void => localStorage.removeItem(LAST_VIEWED);

export const getDelegated = (): boolean => {
  const token = jwt.decode(fetchToken()?.accessToken || "") as any;
  if (token != null && token.delegated) {
    return true;
  }
  return false;
};

export const getLastChangePassword = (): number => {
  const token = jwt.decode(fetchToken()?.accessToken || "") as any;
  if (token != null) {
    return Number(token.lastChangePasswordDays);
  }
  return 200; //Make it over 180
};

export const getPasswordExpiryWarning = (): number => {
  const token = jwt.decode(fetchToken()?.accessToken || "") as any;
  if (token != null && token.passwordGraceDays) {
    return Number(token.passwordGraceDays);
  }
  return 0;
};

export const getPasswordWindowDays = (): number => {
  const token = jwt.decode(fetchToken()?.accessToken || "") as any;
  if (token != null && token.passwordWindowDays) {
    return Number(token.passwordWindowDays);
  }
  return 0;
};

export const getExpiredIn = (): number => {
  const token = jwt.decode(fetchToken()?.accessToken || "") as any;
  if (token != null && token.expiredIn) {
    return Math.floor(Number(token.expiredIn));
  }
  return 50;
};

export const getForceAuth = (): boolean => {
  const mfaToken = jwt.decode(fetchToken()?.accessToken || "") as MFATypes.MFAToken;
  if (
    mfaToken != null &&
    mfaToken.userMfaSetup.mfaEnrolled &&
    mfaToken.userMfaSetup.enrolments.filter((el) => el.mfaId > 1 && el.status === 2).length > 0
  ) {
    return true;
  }
  return false;
};

export const getForceEnrol = (): boolean => {
  const mfaToken = jwt.decode(fetchToken()?.accessToken || "") as MFATypes.MFAToken;
  if (mfaToken != null && !mfaToken.userMfaSetup.mfaEnrolled) {
    return true;
  }
  return false;
};

export const getHospitalForceEnrol = (): boolean => {
  const mfaToken = jwt.decode(fetchToken()?.accessToken || "") as MFATypes.MFAToken;
  if (mfaToken != null && mfaToken.hospitalMfaSetup.mfaEnforced) {
    return true;
  }
  return false;
};

export const getHomepageEnforced = (): boolean => {
  const mfaToken = jwt.decode(fetchToken()?.accessToken || "") as MFATypes.MFAToken;
  if (mfaToken != null && mfaToken.hospitalMfaSetup.homepageUserEnforced) {
    return true;
  }
  return false;
};

export const getExpired = (): boolean => localStorage.getItem(EXPIRED) === "true";

export const setExpired = (): void => localStorage.setItem(EXPIRED, "true");

export const clearExpired = (): void => localStorage.removeItem(EXPIRED);

export const getOnlySkipMFAenrolled = (): boolean => {
  const mfaToken = jwt.decode(fetchToken()?.accessToken || "") as MFATypes.MFAToken;
  if (
    mfaToken != null &&
    ((mfaToken.userMfaSetup.enrolments.filter((el) => el.mfaId == 1 && el.status === 2).length > 0 &&
      mfaToken.userMfaSetup.enrolments.filter((el) => el.mfaId > 1).length == 0) ||
      mfaToken.userMfaSetup.enrolments.length == 0)
  ) {
    return true;
  }
  return false;
};
export const getMFAenrolled = (): boolean => {
  const mfaToken = jwt.decode(fetchToken()?.accessToken || "") as MFATypes.MFAToken;
  if (mfaToken != null && mfaToken.userMfaSetup.mfaEnrolled == true) {
    return true;
  }
  return false;
};

export const getOtpVerfiedEnrolled = (): boolean => {
  const mfaToken = jwt.decode(fetchToken()?.accessToken || "") as MFATypes.MFAToken;
  if (mfaToken != null && mfaToken.otpVerified == true) {
    return true;
  }
  return false;
};

export const getIpRestricted = (): boolean => {
  const token = jwt.decode(fetchToken()?.accessToken || "") as any;
  if (token != null && token.ipRestricted == true) {
    return true;
  }
  return false;
};

export const getProviders = (): string[] => {
  const providerJson = localStorage.getItem(PROVIDERS);

  if (providerJson != null) {
    return JSON.parse(providerJson);
  } else {
    return [];
  }
};

export const setProviders = (providers: string[]): void => localStorage.setItem(PROVIDERS, JSON.stringify(providers));

export const clearProviders = (): void => localStorage.removeItem(PROVIDERS);

export const getCpdProviders = (): string[] => {
  const providerJson = localStorage.getItem(CPD_PROVIDERS);

  if (providerJson != null) {
    return JSON.parse(providerJson);
  } else {
    return [];
  }
};

export const setCpdProviders = (providers: string[]): void =>
  localStorage.setItem(CPD_PROVIDERS, JSON.stringify(providers));

export const clearCpdProviders = (): void => localStorage.removeItem(CPD_PROVIDERS);

export const getUsername = (): string => localStorage.getItem(USERNAME) || "";

export const setUsername = (username: string): void => localStorage.setItem(USERNAME, username);

export const clearUsername = (): void => localStorage.removeItem(USERNAME);

export const getUserId = (): string => localStorage.getItem(USERID) || "";

export const setUserId = (userId: string): void => localStorage.setItem(USERID, userId);

export const clearUserId = (): void => localStorage.removeItem(USERID);

export const getAllowAllPatients = (): boolean => localStorage.getItem(ALLOW_ALL_PATIENTS) === "true";

export const setAllowAllPatients = (): void => localStorage.setItem(ALLOW_ALL_PATIENTS, "true");

export const clearAllowAllPatients = (): void => localStorage.removeItem(ALLOW_ALL_PATIENTS);

export const getShowDeletedPanels = (): boolean => localStorage.getItem(SHOW_DELETED_PANELS) === "true";

export const setShowDeletedPanels = (value = true): void => {
  if (value) localStorage.setItem(SHOW_DELETED_PANELS, "true");
  else localStorage.setItem(SHOW_DELETED_PANELS, "false");
};

export const clearShowDeletedPanels = (): void => {
  localStorage.removeItem(SHOW_DELETED_PANELS);
  localStorage.removeItem(USER_CHANGED_SHOW_DELETED_PANEL);
};

export const getFlagUserHasChangedShowDeletedPanel = (): boolean =>
  localStorage.getItem(USER_CHANGED_SHOW_DELETED_PANEL) === "true";

export const setFlagUserHasChangedShowDeletedPanel = (): void =>
  localStorage.setItem(USER_CHANGED_SHOW_DELETED_PANEL, "true");

export const getAllowAllPatientsAcknownledged = (): boolean => localStorage.getItem(ALLOW_ALL_PATIENTS_ACK) === "true";

export const setAllowAllPatientsAcknownledged = (value: boolean): void =>
  localStorage.setItem(ALLOW_ALL_PATIENTS_ACK, value ? "true" : "false");

export const clearAllowAllPatientsAcknownledged = (): void => localStorage.removeItem(ALLOW_ALL_PATIENTS_ACK);

export const getDefaultWardCode = (): string => localStorage.getItem(DEFAULT_WARD_CODE) || "";

export const setDefaultWardCode = (defaultwardcode: string): void =>
  localStorage.setItem(DEFAULT_WARD_CODE, defaultwardcode);

export const clearDefaultWardCode = (): void => localStorage.removeItem(DEFAULT_WARD_CODE);

export const setSharedAccount = (value: boolean): void =>
  localStorage.setItem(SHARED_ACCOUNT, value ? "true" : "false");

export const clearSharedAccount = (): void => localStorage.removeItem(SHARED_ACCOUNT);

export const getSharedAccount = (): boolean => (localStorage.getItem(SHARED_ACCOUNT) || "") === "true";

export const getWards = (): WardInput[] => {
  const providerJson = localStorage.getItem(WARDS);

  if (providerJson != null) {
    return JSON.parse(providerJson);
  } else {
    return [];
  }
};

export const getOpenNewTab = (): boolean => !isMobility() && localStorage.getItem(OPEN_NEW_TAB) === "true";

export const setOpenNewTab = (value: boolean): void => localStorage.setItem(OPEN_NEW_TAB, value ? "true" : "false");

export const clearOpenNewTab = (): void => localStorage.removeItem(OPEN_NEW_TAB);

export const setWards = (wards: WardInput[]): void => localStorage.setItem(WARDS, JSON.stringify(wards));

export const clearWards = (): void => localStorage.removeItem(WARDS);

export const setLastlogin = (llogin: string): void => localStorage.setItem(LASTLOGIN, llogin);

export const getLastLogin = (): string => localStorage.getItem(LASTLOGIN) || "";

export const clearLastlogin = (): void => localStorage.removeItem(LASTLOGIN);

export const setProviderSelected = (selProvider: string): void => localStorage.setItem(PROVIDERSELECTED, selProvider);

export const getProviderSelected = (): string => localStorage.getItem(PROVIDERSELECTED) || "0";

export const clearProviderSelected = (): void => localStorage.removeItem(PROVIDERSELECTED);

export const setCpdProviderSelected = (selProvider: string): void =>
  localStorage.setItem(CPD_PROVIDERSELECTED, selProvider);

export const getCpdProviderSelected = (): string => localStorage.getItem(CPD_PROVIDERSELECTED) || "0";

export const clearCpdProviderSelected = (): void => localStorage.removeItem(CPD_PROVIDERSELECTED);

export const getCpdProviderValues = (): string[] =>
  getCpdProviderSelected() != "0" ? [getCpdProviderSelected()] : getCpdProviders();

export const getAdOpen = (): boolean => (localStorage.getItem(ADOPEN) || "") === "true";

export const setAdOpen = (value: boolean): void => localStorage.setItem(ADOPEN, value ? "true" : "false");

export const clearAdOpen = (): void => localStorage.removeItem(ADOPEN);

export const clearHospAdmin = (): void => localStorage.removeItem(HOSPITAL_ADMIN);

export const clearHospID = (): void => localStorage.removeItem(HOSPID);

export const clearRestrictedClinicalStatuses = (): void => localStorage.removeItem(RESTRICTED_CLINICAL_STATUSES);

export const clearActionWorkflowEnabled = (): void => localStorage.removeItem(ACTION_WORKFLOW_ENABLED);

export const clearDcfWorkflowEnabled = (): void => localStorage.removeItem(DCF_WORKFLOW_ENABLED);

export const clearRepeatAtomicComment = (): void => localStorage.removeItem(REPEAT_ATOMIC_COMMENT);

export const getHospID = (): string => localStorage.getItem(HOSPID) || "";

export const setHospID = (hospid: string): void => {
  localStorage.setItem(HOSPID, hospid);
};

export const getRestrictedClinicalStatuses = (): any => {
  const statuses = localStorage.getItem(RESTRICTED_CLINICAL_STATUSES) || "";
  // rules
  //  -if there are no restricted status show all
  //  -if there is one or more only show those specified
  const availableClinicalStatuses = { allowCS: true, allowNCS: true, allowIndeterminate: true };
  if (statuses.length) {
    const statusArray = statuses.split(",");
    availableClinicalStatuses.allowCS = statusArray.includes("1");
    availableClinicalStatuses.allowNCS = statusArray.includes("2");
    availableClinicalStatuses.allowIndeterminate = statusArray.includes("3");
  }
  return availableClinicalStatuses;
};

export const setRestrictedClinicalStatuses = (restrictedClinicalStatuses: string): void => {
  localStorage.setItem(RESTRICTED_CLINICAL_STATUSES, restrictedClinicalStatuses);
};

export const getDcfWorkflowEnabled = (): boolean => (localStorage.getItem(DCF_WORKFLOW_ENABLED) || "") === "true";

export const setDcfWorkflowEnabled = (value: boolean): void =>
  localStorage.setItem(DCF_WORKFLOW_ENABLED, value ? "true" : "false");

export const getActionWorkflowEnabled = (): boolean => (localStorage.getItem(ACTION_WORKFLOW_ENABLED) || "") === "true";

export const setActionWorkflowEnabled = (value: boolean): void =>
  localStorage.setItem(ACTION_WORKFLOW_ENABLED, value ? "true" : "false");

export const getRepeatAtomicComment = (): boolean => (localStorage.getItem(REPEAT_ATOMIC_COMMENT) || "") === "true";

export const setRepeatAtomicComment = (value: boolean): void =>
  localStorage.setItem(REPEAT_ATOMIC_COMMENT, value ? "true" : "false");

export const clearResultNotification = (): void => localStorage.removeItem(RESULT_NOTIFICATION);

export const getResultNotification = (): string => localStorage.getItem(RESULT_NOTIFICATION) || "1";

export const setResultNotification = (rnotify: string): void => localStorage.setItem(RESULT_NOTIFICATION, rnotify);

export const clearNotificationEmail = (): void => localStorage.removeItem(NOTIFICATION_EMAIL);

export const getNotificationEmail = (): string => localStorage.getItem(NOTIFICATION_EMAIL) || "";

export const setNotificationEmail = (notEmail: string): void => localStorage.setItem(NOTIFICATION_EMAIL, notEmail);

export const clearPrimaryEmail = (): void => localStorage.removeItem(PRIMARY_EMAIL);

export const getPrimaryEmail = (): string => localStorage.getItem(PRIMARY_EMAIL) || "";

export const setPrimaryEmail = (notEmail: string): void => localStorage.setItem(PRIMARY_EMAIL, notEmail);

export const getMobileDeviceId = (): string => localStorage.getItem(MOBILE_DEVICE_ID) || "";

export const clearMobileNumber = (): void => localStorage.removeItem(MOBILE_NUMBER);

export const getMobileNumber = (): string => localStorage.getItem(MOBILE_NUMBER) || "";

export const setMobileNumber = (notMobile: string): void => localStorage.setItem(MOBILE_NUMBER, notMobile || "");

export const getRememberMe = (): string => localStorage.getItem(REMEMBER_ME) || "";

export const setRememberMe = (value: string): void => localStorage.setItem(REMEMBER_ME, value);

export const setDiscoverable = (discoverable: boolean): void =>
  localStorage.setItem(DISCOVERABLE, String(discoverable));

export const getDiscoverable = (): boolean => (localStorage.getItem(DISCOVERABLE) || "") === "true";

export const clearRememberMe = (): void => localStorage.removeItem(REMEMBER_ME);

export const getState = (): string => localStorage.getItem(STATE) || "";

export const setState = (state: string): void => localStorage.setItem(STATE, state);

export const clearState = (): void => localStorage.removeItem(STATE);

export const getClinicalTrial = (): CLINCAL_TRIAL_USER_TYPE =>
  isMobility()
    ? CLINCAL_TRIAL_USER_TYPE.Standard
    : (localStorage.getItem(CLINICAL_TRIAL) as CLINCAL_TRIAL_USER_TYPE) || CLINCAL_TRIAL_USER_TYPE.Standard;

export const setClinicalTrial = (clinicalTrial: string): void => localStorage.setItem(CLINICAL_TRIAL, clinicalTrial);

export const clearClinicalTrial = (): void => localStorage.removeItem(CLINICAL_TRIAL);

export const getReportView = (): string => localStorage.getItem(REPORT_VIEW) || "1";

export const setReportView = (reportView: string): void => localStorage.setItem(REPORT_VIEW, reportView);

export const clearReportView = (): void => localStorage.removeItem(REPORT_VIEW);

export const clearEresultsMobilePass = (): void => localStorage.removeItem(ERESULTS_MOBILE_PASS);

export const getDelegatedUser = (): boolean => (localStorage.getItem(DELEGATED_USER) || "") === "true";

export const setDelegatedUser = (value: boolean): void =>
  localStorage.setItem(DELEGATED_USER, value ? "true" : "false");

export const clearDelegatedUser = (): void => localStorage.removeItem(DELEGATED_USER);

export const getName = (): string => localStorage.getItem(NAME) || "";

export const setName = (name: string): void => localStorage.setItem(NAME, name);

export const clearName = (): void => localStorage.removeItem(NAME);

export const getFingerprint = (): string => localStorage.getItem(FGP) || "";

export const setFingerprint = (fingerprint: string): void => localStorage.setItem(FGP, fingerprint);

export const clearFingerprint = (): void => localStorage.removeItem(FGP);

export const clearSearchText = (): void => localStorage.removeItem(SEARCHTEXT);

export const getCookieValue = (name: string): any => {
  return document.cookie
    .split("; ")
    .find((row) => row.startsWith(name + "="))
    ?.split("=")[1];
};

export const clearCustomItems = (items: string[]): void => {
  items.forEach((item: string) => {
    localStorage.removeItem(item);
  });
};

export const getHidePanel = (): boolean =>
  localStorage.getItem(HIDE_PANEL) && localStorage.getItem(HIDE_PANEL) === "true" ? true : false;

export const setHidePanel = (hidePanel: string): void => localStorage.setItem(HIDE_PANEL, hidePanel);

export const getAutoSearch = (): boolean =>
  localStorage.getItem(AUTO_SEARCH) && localStorage.getItem(AUTO_SEARCH) === "true" ? true : false;

export const setAutoSearch = (autoSearch: string): void => localStorage.setItem(AUTO_SEARCH, autoSearch);

export const clearAll = (): void => {
  clearToken();
  clearExpired();
  clearProviders();
  clearCpdProviders();
  clearUsername();
  clearRestrictedClinicalStatuses();
  clearDcfWorkflowEnabled();
  clearActionWorkflowEnabled();
  clearRepeatAtomicComment();
  clearAllowAllPatients();
  clearAllowAllPatientsAcknownledged();
  clearDefaultWardCode();
  clearSharedAccount();
  clearWards();
  clearLastlogin();
  clearProviderSelected();
  clearCpdProviderSelected();
  clearHospAdmin();
  clearHospitalReport();
  clearHospitalReportList();
  clearHospID();
  clearAdOpen();
  clearNotificationEmail();
  clearResultNotification();
  clearPrimaryEmail();
  clearEresultsMobilePass();
  clearClinicalTrial();
  clearSmscEnabled();
  clearDelegatedUser();
  clearName();
  clearUserId();
  clearState();
  clearLastViewed();
  clearSiteOptions();
  clearReportsBlob();
  clearMobileNumber();
  clearFingerprint();
  clearSearchText();
  clearCustomItems([
    "CytologyReportPeriod",
    "CytologyReportProvider",
    "cytoReportEnd",
    "cytoReportStart",
    "EmailFilter",
    "NameFilter",
    "ProviderFilter",
    "skinReportEnd",
    "skinReportStart",
    "UserFilter",
    "YearFilter",
    "yearFilterText",
  ]);
};

export const isMobility = (): boolean => {
  return (sessionStorage.getItem(MOBILITY) || "") === "true";
};

export const setMobility = (mobility: boolean): void =>
  mobility ? sessionStorage.setItem(MOBILITY, "true") : sessionStorage.removeItem(MOBILITY);

export const isHospitalAdmin = (): boolean => {
  return (localStorage.getItem(HOSPITAL_ADMIN) || "") === "true";
};

export const showHospitalReport = (): boolean => {
  return (localStorage.getItem(HOSPITAL_REPORT) || "") === "true";
};

export const setHospitalReport = (hospitalReport: boolean): void =>
  hospitalReport ? localStorage.setItem(HOSPITAL_REPORT, "true") : localStorage.removeItem(HOSPITAL_REPORT);

export const clearHospitalReport = (): void => localStorage.removeItem(HOSPITAL_REPORT);

export const getHospitalReportList = (): number[] => {
  const hospitalReportListJson = localStorage.getItem(HOSPITAL_REPORT_LIST);

  if (hospitalReportListJson != null) {
    return JSON.parse(hospitalReportListJson);
  } else {
    return [];
  }
};

export const setHospitalReportList = (hospitalReportList: number[]): void =>
  localStorage.setItem(HOSPITAL_REPORT_LIST, JSON.stringify(hospitalReportList));

export const clearHospitalReportList = (): void => localStorage.removeItem(HOSPITAL_REPORT_LIST);

export const isClinicalTrial = (): boolean => {
  return getClinicalTrial() !== CLINCAL_TRIAL_USER_TYPE.Standard;
};

export const setHospitalAdmin = (hospitalAdmin: boolean): void =>
  hospitalAdmin ? localStorage.setItem(HOSPITAL_ADMIN, "true") : localStorage.removeItem(HOSPITAL_ADMIN);

export const isSmscEnabled = (): boolean => {
  return (localStorage.getItem(SMSC_ENABLED) || "") === "true";
};

export const setSmscEnabled = (smscEnabled: boolean): void =>
  smscEnabled ? localStorage.setItem(SMSC_ENABLED, "true") : localStorage.removeItem(SMSC_ENABLED);

export const clearSmscEnabled = (): void => localStorage.removeItem(SMSC_ENABLED);

export const getSiteOptions = (): GetOptions_getOptions[] => {
  const optionsJson = localStorage.getItem(SITE_OPTIONS);

  if (optionsJson != null) {
    return JSON.parse(optionsJson);
  } else {
    return [];
  }
};

export const setSiteOptions = (options: GetOptions_getOptions[]): void =>
  localStorage.setItem(SITE_OPTIONS, JSON.stringify(options));

export const clearSiteOptions = (): void => localStorage.removeItem(SITE_OPTIONS);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setReportsBlob = (reports: any): void =>
  new ExpiredStorage(localStorage).setItem(REPORTS_BLOB, JSON.stringify(reports), 180);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getReportsBlob = (): any => JSON.parse(localStorage.getItem(REPORTS_BLOB) || "{}");

export const clearReportsBlob = (): void => new ExpiredStorage(localStorage).removeItem(REPORTS_BLOB);

export const getCPDEnrolled = (): Array<number> => {
  const cognitoToken = fetchCognitoToken();
  let token = null;
  if (cognitoToken) {
    token = cognitoToken["eResults"];
  } else {
    token = jwt.decode(fetchToken()?.accessToken || "") as any;
  }
  if (!token) {
    return [];
  }
  return token.cpdEnrolments ?? null;
};

export const validProviders = (): boolean => {
  const providers = getProviders();
  let result = false;
  providers.forEach((el) => {
    if (validateProvideNumber(el) == true) {
      result = true;
    }
  });
  return result;
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const persistAllValues = (authResponse: any): void => {
  const fingerprint = getFingerprint();
  clearAll();
  setFingerprint(fingerprint);

  const token = get(authResponse, "accessToken");
  persistToken(token);

  if (get(authResponse, "expired")) {
    setExpired();
  } else {
    clearExpired();
  }

  const providers: string[] = get(authResponse, "providers");
  if (providers) {
    setProviders(providers);
  } else {
    clearProviders();
  }

  const lastlogin: string = get(authResponse, "lastlogin");

  if (lastlogin) {
    setLastlogin(lastlogin);
  } else {
    clearLastlogin();
  }

  if (get(authResponse, "allowAllPatients")) {
    setAllowAllPatients();
    setAllowAllPatientsAcknownledged(false);
  } else {
    clearAllowAllPatients();
  }

  if (get(authResponse, "showDeletedauthResponses")) {
    if (!getFlagUserHasChangedShowDeletedPanel()) {
      setShowDeletedPanels();
    }
  }

  setSharedAccount(get(authResponse, "sharedaccount"));

  if (get(authResponse, "notificationEmail")) {
    setNotificationEmail(get(authResponse, "notificationEmail"));
  }

  if (get(authResponse, "email")) {
    setPrimaryEmail(get(authResponse, "email"));
  }

  if (get(authResponse, "mobileNumber")) {
    setMobileNumber(get(authResponse, "mobileNumber"));
  }

  if (get(authResponse, "resultNotification")) {
    setResultNotification(get(authResponse, "resultNotification"));
  }

  setUsername(get(authResponse, "username"));
  setUserId(get(authResponse, "userId"));

  if (get(authResponse, "wards")) {
    setWards(get(authResponse, "wards"));
  } else {
    clearWards();
  }

  const clinicalTrial = get(authResponse, "clinicalTrial") || 0;

  setClinicalTrial(clinicalTrial);

  const reportView = clinicalTrial !== "0" ? "2" : "1";

  setReportView(reportView);

  const defaultward: string = get(authResponse, "defaultWardCode");
  setDefaultWardCode(defaultward);

  const hospAdmin = get(authResponse, "hospitalAdmin") || false;
  setHospitalAdmin(hospAdmin);

  const hospReport = get(authResponse, "hospitalReport") || false;
  setHospitalReport(hospReport);

  const hospReportList = get(authResponse, "hospitalReportList") || [];
  setHospitalReportList(hospReportList);

  const hospid = get(authResponse, "hospid") || "";
  setHospID(hospid);

  setState(get(authResponse, "state"));
  setOpenNewTab(getOpenNewTab());

  const smscEnabled = get(authResponse, "smscEnabled") || false;
  setSmscEnabled(smscEnabled);

  const name = get(authResponse, "name") || "";
  setName(name);

  const restrictedClinicalStatuses = get(authResponse, "restrictedClinicalStatuses") || "";
  setRestrictedClinicalStatuses(restrictedClinicalStatuses);

  const dcfWorkflowEnabled = get(authResponse, "dcfWorkflowEnabled") || "";
  setDcfWorkflowEnabled(dcfWorkflowEnabled);

  const actionWorkflowEnabled = get(authResponse, "actionWorkflowEnabled") || "";
  setActionWorkflowEnabled(actionWorkflowEnabled);

  const repeatAtomicComment = get(authResponse, "repeatAtomicComment") || "";
  setRepeatAtomicComment(repeatAtomicComment);

  const hidePanel = get(authResponse, "hidePanel") || false;
  setHidePanel(hidePanel ? "true" : "false");

  const autoSearch = get(authResponse, "autoSearch") || false;
  setAutoSearch(autoSearch ? "true" : "false");
};

const exportObj = {
  persistToken,
  fetchToken,
  isAuthenticated,
  clearToken,
  getExpired,
  setExpired,
  clearExpired,
  setProviders,
  getProviders,
  clearProviders,
  setCpdProviders,
  getCpdProviders,
  clearCpdProviders,
  getUsername,
  setUsername,
  clearUsername,
  getUserId,
  setUserId,
  clearUserId,
  getAllowAllPatients,
  setAllowAllPatients,
  clearAllowAllPatients,
  getShowDeletedPanels,
  setShowDeletedPanels,
  clearShowDeletedPanels,
  getFlagUserHasChangedShowDeletedPanel,
  setFlagUserHasChangedShowDeletedPanel,
  getAllowAllPatientsAcknownledged,
  setAllowAllPatientsAcknownledged,
  clearAllowAllPatientsAcknownledged,
  getDefaultWardCode,
  setDefaultWardCode,
  clearDefaultWardCode,
  setSharedAccount,
  getSharedAccount,
  clearSharedAccount,
  setWards,
  getWards,
  clearWards,
  setLastlogin,
  getLastLogin,
  clearLastlogin,
  setProviderSelected,
  getProviderSelected,
  clearProviderSelected,
  setCpdProviderSelected,
  getCpdProviderSelected,
  clearCpdProviderSelected,
  getCpdProviderValues,
  clearAll,
  isMobility,
  setMobility,
  setAdOpen,
  getAdOpen,
  clearAdOpen,
  isHospitalAdmin,
  setHospitalAdmin,
  showHospitalReport,
  setHospitalReport,
  clearHospitalReport,
  getHospitalReportList,
  setHospitalReportList,
  clearHospitalReportList,
  getHospID,
  setHospID,
  getRestrictedClinicalStatuses,
  setRestrictedClinicalStatuses,
  getDcfWorkflowEnabled,
  setDcfWorkflowEnabled,
  getActionWorkflowEnabled,
  setActionWorkflowEnabled,
  getRepeatAtomicComment,
  setRepeatAtomicComment,
  getRememberMe,
  setRememberMe,
  clearRememberMe,
  getState,
  setState,
  setNotificationEmail,
  getNotificationEmail,
  setResultNotification,
  getResultNotification,
  setPrimaryEmail,
  getPrimaryEmail,
  getMobileDeviceId,
  getClinicalTrial,
  setClinicalTrial,
  clearClinicalTrial,
  getReportView,
  setReportView,
  clearReportView,
  getOpenNewTab,
  setOpenNewTab,
  clearOpenNewTab,
  isSmscEnabled,
  setSmscEnabled,
  getDelegatedUser,
  setDelegatedUser,
  clearDelegatedUser,
  getName,
  setName,
  clearName,
  getForceAuth,
  getHospitalForceEnrol,
  getHomepageEnforced,
  getForceEnrol,
  getLastViewed,
  setLastViewed,
  clearLastViewed,
  getMFAenrolled,
  getOtpVerfiedEnrolled,
  getOnlySkipMFAenrolled,
  isClinicalTrial,
  getSiteOptions,
  setSiteOptions,
  clearSiteOptions,
  persistAllValues,
  setReportsBlob,
  getReportsBlob,
  clearReportsBlob,
  getMobileNumber,
  setMobileNumber,
  clearSearchText,
  getCookieValue,
  getDelegated,
  getLastChangePassword,
  getPasswordExpiryWarning,
  getExpiredIn,
  getPasswordWindowDays,
  getIpRestricted,
  getCPDEnrolled,
  validProviders,
  getHidePanel,
  setHidePanel,
  getAutoSearch,
  setAutoSearch,
  isCognito,
  persistCognitoToken,
  getDiscoverable,
  setDiscoverable,
};

export default exportObj;
