import React, { useState } from "react";
import { AppBar, Box, Link, Typography } from "@mui/material";
import { withRouter, RouteComponentProps } from "react-router";
import PatientEpisodeHeader from "../PatientEpisodeHeader/PatientEpisodeHeader";
import {
  GetPatientEpisodes_patientepisodes_episodes,
  GetPatientEpisodes_patientepisodes_episodes_panels,
} from "../PatientDetailsPage/types/GetPatientEpisodes";
import { useQuery } from "@apollo/client";
import authService from "../../services/authService";
import GetEpisodeReports from "./GetEpisodeReports";
import {
  GetEpisodeReports as GetEpisodeReportsQueryType,
  GetEpisodeReports_episodereports_reports,
  GetEpisodeReportsVariables as Episode,
  GetEpisodeReports_episodereports_histoImages,
} from "./types/GetEpisodeReports";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link as RouterLink, useLocation } from "react-router-dom";
import styles from "./PatientReportsCondense.module.css";
import ReportViewer from "../ReportViewer/ReportViewer";
import Loading from "../Loading/Loading";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";
import PrintMenu from "../PatientReportsFull/PrintMenu/PrintMenu";
import * as Constants from "../../constants";
import { useCacheWithExpiration } from "../../utils/useCacheWithExpiration";
import printMenuStyles from "../PatientReportsFull/PrintMenu/PrintMenu.module.css";
import theme from "../theme";
import DialogBoxShare from "../DialogBox/DialogBoxShare";

type PathParams = {
  id: string;
  allPatients: string;
  labnoDigitOnly: string;
  initialLabNo: string;
  sortBy: string;
};

const PatientReportsCondense: React.FC<RouteComponentProps> = (props) => {
  const { match } = props;
  const params = match.params as PathParams;
  const location = useLocation();

  const [reportsSelected, setReportsSelected] = React.useState<any>({}); //reports selected by reort select menu
  const [openShare, setOpenShare] = useState(false);

  const fetchPolicy = useCacheWithExpiration(
    Constants.DEFAULT_CACHE_DURATION,
    `GetEpisodeReports_${params.labnoDigitOnly}`,
  );

  if (document.readyState === "complete") {
    // need to reset the overflowY on body that is set by the previous page (see setVerticalScrollbar )
    document.body.style.overflowY = "auto";
  }

  const printEpisodeVar: Episode = {
    patientId: parseInt(params.id),
    providers: authService.getProviders(),
    labnoDigitOnly: params.labnoDigitOnly,
    allPatients:
      params.allPatients === "1" && authService.getAllowAllPatients() && authService.getAllowAllPatientsAcknownledged(),
  };

  const {
    data: episodeReportsData,
    loading,
    error: fetchError,
  } = useQuery<GetEpisodeReportsQueryType>(GetEpisodeReports, {
    variables: printEpisodeVar,
    fetchPolicy: fetchPolicy,
  });

  if (loading) {
    return <Loading />;
  }

  if (fetchError) {
    return <ErrorPopover errorMessage={fetchError} />;
  }

  const reports: GetEpisodeReports_episodereports_reports[] | undefined = episodeReportsData
    ? episodeReportsData.episodereports.reports
    : undefined;

  const histoImages: GetEpisodeReports_episodereports_histoImages[] | undefined = episodeReportsData
    ? (episodeReportsData.episodereports.histoImages as GetEpisodeReports_episodereports_histoImages[] | undefined)
    : undefined;

  const episode: GetPatientEpisodes_patientepisodes_episodes = {
    __typename: "PatientEpisode",
    labnoDigitOnly: params.labnoDigitOnly,
    panels: reports
      ? reports.map<GetPatientEpisodes_patientepisodes_episodes_panels>((r) => {
          const panel: GetPatientEpisodes_patientepisodes_episodes_panels = {
            __typename: "ReportPanel",
            reportid: r.reportid || 0,
            labnumber: r.labnumber || "",
            testnameProperCase: r.testname || "",
            dateCollected: r.dateCollected,
            dateCreated: r.dateCreated,
            dateReferred: r.dateReferred,
            dateViewed: r.dateViewed,
            hasAbnormalStatus: r.hasAbnormalStatus,
            interimStatus: r.interimStatus,
            testcode: r.testcode,
            testname: r.testname,
            urno: "",
            ward: "",
            referringDoctor: "",
            referredProviderNo: r.referredProviderNo,
            suburb: "",
            address: "",
            phone: "",
            reportingProviderNo: r.reportingProviderNo,
            urgentStatus: r.urgentStatus,
            labid: r.labid as number,
          };
          return panel;
        })
      : [],
  };

  return (
    <Box>
      <AppBar elevation={0} position="sticky">
        <div className={styles.navigation__feature}></div>
        <Box
          pt={[2, 3]}
          pl={[2, 3]}
          bgcolor={theme.colorSchemes.light.palette.primary.main}
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"row"}
        >
          <Link
            component={RouterLink}
            to={{
              pathname:
                params.initialLabNo && params.initialLabNo.includes("/")
                  ? `/patient/${params.id}/${params.allPatients}/labnumber/${params.initialLabNo}/`
                  : `/patient/${params.id}/${params.allPatients}/labnumber/${params.labnoDigitOnly}/${params.initialLabNo}/`,
              state: location.state,
            }}
          >
            <Typography variant="body2" className={styles.backButton}>
              <ArrowBackIosIcon fontSize="small" className={styles.iconMobile} />
              Back to episodes
            </Typography>
          </Link>
          <Box display={"flex"} mr={2}>
            <Box display={"flex"} mr={3}>
              {reports && reports.length > 0 && (
                <PrintMenu
                  className={printMenuStyles.printcomponentwhite}
                  reports={reports ? reports : []}
                  episode={printEpisodeVar}
                  ctReport={[]}
                  allPatients={
                    params.allPatients === "1" &&
                    authService.getAllowAllPatients() &&
                    authService.getAllowAllPatientsAcknownledged()
                  }
                  deliveryMode={Constants.DELIVERY_MODE_SHARE}
                  patientId={parseInt(params.id)}
                  sortBy={params.sortBy}
                  printCombined={false}
                  reportingProviderNo={episode.panels[0].reportingProviderNo || ""}
                  onClickAction={() => {
                    setOpenShare(true);
                  }}
                  setReportsSelected={setReportsSelected}
                />
              )}
            </Box>
            <Box display={"flex"} mr={3}>
              {reports && reports.length > 0 && (
                <PrintMenu
                  className={printMenuStyles.printcomponentwhite}
                  reports={reports ? reports : []}
                  episode={printEpisodeVar}
                  ctReport={[]}
                  allPatients={
                    params.allPatients === "1" &&
                    authService.getAllowAllPatients() &&
                    authService.getAllowAllPatientsAcknownledged()
                  }
                  deliveryMode={Constants.DELIVERY_MODE_ADDON}
                  patientId={parseInt(params.id)}
                  sortBy={params.sortBy}
                  printCombined={false}
                  smscEnabled={authService.isSmscEnabled()}
                  isAddon
                  reportingProviderNo={episode.panels[0].reportingProviderNo || ""}
                />
              )}
            </Box>
            <Box display={"flex"} mr={3}>
              {reports && reports.length > 0 && (
                <PrintMenu
                  className={printMenuStyles.printcomponentwhite}
                  reports={reports ? reports : []}
                  episode={printEpisodeVar}
                  ctReport={[]}
                  allPatients={
                    params.allPatients === "1" &&
                    authService.getAllowAllPatients() &&
                    authService.getAllowAllPatientsAcknownledged()
                  }
                  deliveryMode={Constants.DELIVERY_MODE_DOWNLOAD}
                  patientId={parseInt(params.id)}
                  sortBy={params.sortBy}
                  printCombined={false}
                  smscEnabled={authService.isSmscEnabled()}
                  reportingProviderNo={episode.panels[0].reportingProviderNo || ""}
                />
              )}
            </Box>
            {reports && reports.length > 0 && (
              <PrintMenu
                className={printMenuStyles.printcomponentwhite}
                reports={reports ? reports : []}
                episode={printEpisodeVar}
                ctReport={[]}
                allPatients={
                  params.allPatients === "1" &&
                  authService.getAllowAllPatients() &&
                  authService.getAllowAllPatientsAcknownledged()
                }
                deliveryMode={Constants.DELIVERY_MODE_PRINT}
                patientId={parseInt(params.id)}
                sortBy={params.sortBy}
                printCombined={false}
                reportingProviderNo={episode.panels[0].reportingProviderNo || ""}
              />
            )}
          </Box>
        </Box>
        {reports && reports.length > 0 && (
          <PatientEpisodeHeader
            active={true}
            mostRecent={false}
            episode={episode}
            sortBy={params.sortBy}
            showRemovedPanels={false}
            isDesktop={true}
          />
        )}
      </AppBar>
      {reports && reports.length > 0 ? (
        <>
          <ReportViewer
            labnoDigitOnly={params.labnoDigitOnly}
            patientId={parseInt(params.id)}
            reports={reports}
            histoImages={histoImages}
            initialLabNo={params.initialLabNo}
            allPatients={
              params.allPatients === "1" &&
              authService.getAllowAllPatients() &&
              authService.getAllowAllPatientsAcknownledged()
            }
            sortBy={params.sortBy}
          />
          <DialogBoxShare
            open={openShare}
            onClose={() => setOpenShare(false)}
            onSetOpenShare={(st: boolean) => setOpenShare(st)}
            reports={reportsSelected}
            patient={parseInt(params.id)}
            labno={params.labnoDigitOnly}
          />
        </>
      ) : (
        <Box mb={[0, 0, 2]} display="flex" justifyContent="center">
          <Typography variant="h4">No Reports Found!</Typography>
        </Box>
      )}
    </Box>
  );
};

export default withRouter(PatientReportsCondense);
