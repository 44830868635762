import React, { useState, useEffect } from "react";
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import PatientDetailsPage from "../PatientDetailsPage/PatientDetailPage";
import PatientListPage from "../PatientListPage/PatientListPage";
import LoginPage from "../LoginPage/LoginPage";
import CognitoLoginPage from "../LoginPage/CognitoLoginPage";
import CognitoLogoutPage from "../LoginPage/CognitoLogoutPage";
import ForgotPasswordPage from "../ForgotPasswordPage/ForgotPasswordPage";
import ChangePasswordPage from "../ChangePasswordPage/ChangePasswordPage";
import ChangeEmailPage from "../ChangeEmailPage/ChangeEmailPage";
import NotificationSettingsPage from "../NotificationSettingsPage/NotificationSettingsPage";
import ProtectedRoute, { PrivateRoute, SwitchingRoute } from "../ProtectedRoute";
import AppContext from "../../context/AppContext";
import { useMediaQuery } from "@mui/material";
import theme from "../theme";
import PatientReportsCondense from "../PatientReportsCondense/PatientReportsCondense";
import { useApolloClient } from "@apollo/client";
import authService, { SET_AUTH } from "../../services/authService";
import CumulativeContextProvider from "../../context/CumulativeContext";
import PatientDetailsContextProvider from "../../context/PatientDetailsContext";
import CumulativeMobilePage from "../CumulativeMobilePage/CumulativeMobilePage";
import PrintableReportsPage from "../PrintableReports/PrintableReportsPage";
import FoodPrintReport from "../PrintableReports/FoodPrintReport";
import CheckEmailPage from "../ForgotPasswordPage/CheckEmailPage";
import UserGuidePage from "../UserGuide/UserGuidePage";
import ResetPasswordPage from "../ForgotPasswordPage/ResetPasswordPage";
import ContactSupportPage from "../ForgotPasswordPage/ContactSupportPage";
import LinkExpiredPage from "../ForgotPasswordPage/LinkExpiredPage";
import PrintableCombineAtomicPage from "../PrintableReports/PrintableCombineAtomicPage";
import PrintableCombineCtAtomicPage from "../PrintableReports/PrintableCombineCtAtomicPage";
import HospAdminUserListPage from "../HospAdminUserListPage/HospAdminUserListPage";
import Page from "./page";
import HistoImagePage from "../HistoImagePage/HistoImagePage";
import MFAEnrolmentPage from "../MFAEnrolment/MFAEnrolmentPage";
import MFAAuthenticationPage from "../MFAAuthentication/MFAAuthenticationPage";
import PreviousTestRequests from "../PreviousTestRequests/PreviousTestRequests";
import StudyPage from "../study/StudyPage";
import EpisodeListPage from "../PatientEpisodesPage/EpisodeListPage";
import PrintablePatientsPage from "../PrintableReports/PrintablePatientsPage";
import { PatientsContextProvider } from "../../context/PatientsContext";
import PrintablePatientsDelegationPage from "../PrintableReports/PrintablePatientsDelegationsPage";
import CPDprogramsPage from "../CPDprograms/CPDprogramsPage";
import CPDcytologyPage from "../CPDprograms/CPDcytologyPage";
import CPDdiabetePages from "../CPDprograms/CPDdiabetesPage";
import CPDskinPage from "../CPDprograms/CPDskinPage";
import CPDcytologyDetailPage from "../CPDprograms/CPDcytologyDetailPage";
import DeleteAccountPage from "../DeleteAccountPage/DeleteAccountPage";
import HospitalReportsPage from "../HospitalReports/HospitalReportsPage";

const App: React.FC = () => {
  const mainTitle = `eResults`;
  const isDesktop: boolean = useMediaQuery(theme.breakpoints.up("lg"));
  const [error, setError] = useState("");
  const [testName, setTestName] = useState("");
  const [prevError, setPrevError] = useState("");
  const [isAuth, setIsAuth] = useState(
    !!authService.fetchToken()?.accessToken || !!authService.fetchToken()?.cognitoToken,
  );
  const [isCognito] = useState(authService.isCognito());
  const [errorAcknowledged, setErrorAcknowledged] = useState(false);
  const client = useApolloClient();
  const [refreshResult, setRefreshResult] = useState(false);
  const [autoRefreshResult, setAutoRefreshResult] = useState(
    localStorage.getItem("AutoRefreshResult") === "true" ? true : false,
  );
  const [previewMode, setPreviewMode] = useState(localStorage.getItem("PreviewMode") === "true" ? true : false);
  const [success, setSuccess] = useState("");
  const [prevSuccess, setPrevSuccess] = useState("");
  const [successAcknowledged, setSuccessAcknowledged] = useState(false);

  useEffect(() => {
    if (error !== prevError) {
      setErrorAcknowledged(false);
      setPrevError(error);
    }
  }, [error, prevError, setPrevError, errorAcknowledged]);

  useEffect(() => {
    if (success !== prevSuccess) {
      setSuccessAcknowledged(false);
      setPrevSuccess(success);
    }
  }, [success, prevSuccess, setPrevSuccess, successAcknowledged]);

  client.cache.watch({
    query: SET_AUTH,
    optimistic: false,
    callback: ({ complete, result }) => {
      if (complete && result.auth.isAuth) {
        setIsAuth(true);
      } else if (complete && !result.auth.isAuth) {
        setIsAuth(false);
      }
    },
  });

  // let refresh = false;

  // window.onbeforeunload = () => {
  //   if (!refresh) {
  //     authService.clearAll();
  //     setSearchText("");
  //     localStorage.removeItem("searchtext");
  //   }
  // };

  // const KeyCheck = (e: any) => {
  //   var key =  e.keyCode;
  //   if(key === 116 || key === 62) { //F5 or Ctrl+F5
  //     refresh = true;
  //   }
  // }

  // document.onkeydown = KeyCheck;

  return (
    <AppContext.Provider
      value={{
        isDesktop,
        error,
        setError,
        errorAcknowledged,
        setErrorAcknowledged,
        testName,
        setTestName,
        refreshResult,
        setRefreshResult,
        autoRefreshResult,
        setAutoRefreshResult,
        previewMode,
        setPreviewMode,
        success,
        setSuccess,
        successAcknowledged,
        setSuccessAcknowledged,
      }}
    >
      <Router>
        <Switch>
          <Redirect exact from="/" to="/login" />
          <Route
            path="/login"
            exact
            render={(props) => (
              <Page title={mainTitle}>
                <LoginPage {...props} />
              </Page>
            )}
          />

          <Route
            path="/login-sso"
            exact
            render={(props) => (
              <Page title={mainTitle}>
                <CognitoLoginPage {...props} />
              </Page>
            )}
          />

          <Route path="/logout-sso" exact component={CognitoLogoutPage} />
          <Route path="/forgot" exact component={ForgotPasswordPage} />
          <Route path="/check-email/:email" exact component={CheckEmailPage} />
          <Route path="/userguide" exact component={UserGuidePage} />
          <Route path="/reset/:token" exact component={ResetPasswordPage} />
          <SwitchingRoute path="/contact-support" exact component={ContactSupportPage} />
          <Route path="/expired" exact component={LinkExpiredPage} />

          <ProtectedRoute
            isAuth={isAuth}
            isCognito={isCognito}
            exact
            path="/change-password"
            component={ChangePasswordPage}
            title={mainTitle}
          />

          <ProtectedRoute
            isAuth={isAuth}
            isCognito={isCognito}
            exact
            path="/patient/:id/:reportid/histo-image"
            component={HistoImagePage}
            title={mainTitle}
          />

          <ProtectedRoute
            isAuth={isAuth}
            isCognito={isCognito}
            exact
            path="/account-settings"
            component={ChangeEmailPage}
            title={mainTitle}
          />

          <ProtectedRoute
            isAuth={isAuth}
            isCognito={isCognito}
            exact
            path="/previous-test-requests"
            component={PreviousTestRequests}
            title={mainTitle}
          />

          <ProtectedRoute
            isAuth={isAuth}
            isCognito={isCognito}
            exact
            path="/notification-settings"
            component={NotificationSettingsPage}
            title={mainTitle}
          />

          <ProtectedRoute
            isAuth={isAuth}
            isCognito={isCognito}
            exact
            path="/delete-account"
            component={DeleteAccountPage}
            title={mainTitle}
          />

          <ProtectedRoute
            isAuth={isAuth}
            isCognito={isCognito}
            exact
            path="/enrolment"
            component={MFAEnrolmentPage}
            title={mainTitle}
          />

          <ProtectedRoute
            isAuth={isAuth}
            isCognito={isCognito}
            exact
            path="/mfaauthentication"
            component={MFAAuthenticationPage}
            title={mainTitle}
          />

          <ProtectedRoute
            isAuth={isAuth}
            isCognito={isCognito}
            exact
            path="/patients"
            component={PatientListPage}
            title={mainTitle}
          />

          <ProtectedRoute
            isAuth={isAuth}
            isCognito={isCognito}
            exact
            path="/study"
            component={StudyPage}
            title={mainTitle}
          />

          <ProtectedRoute
            isAuth={isAuth}
            isCognito={isCognito}
            exact
            path="/groupadmin"
            component={HospAdminUserListPage}
            title={mainTitle}
          />
          <ProtectedRoute
            isAuth={isAuth}
            isCognito={isCognito}
            exact
            path="/cpd-skin"
            component={CPDskinPage}
            title={mainTitle}
          />
          <ProtectedRoute
            isAuth={isAuth}
            isCognito={isCognito}
            exact
            path="/cpd-diabetes/:tabpage?"
            component={CPDdiabetePages}
            title={mainTitle}
          />
          <ProtectedRoute
            isAuth={isAuth}
            isCognito={isCognito}
            exact
            path="/cpd-cytology"
            component={CPDcytologyPage}
            title={mainTitle}
          />
          <ProtectedRoute
            isAuth={isAuth}
            isCognito={isCognito}
            exact
            path="/cpd-cytology-report/:id"
            component={CPDcytologyDetailPage}
            title={mainTitle}
          />
          <ProtectedRoute
            isAuth={isAuth}
            isCognito={isCognito}
            exact
            path="/cpd-programs"
            component={CPDprogramsPage}
            title={mainTitle}
          />
          <ProtectedRoute
            isAuth={isAuth}
            isCognito={isCognito}
            exact
            path="/hospital-reports"
            component={HospitalReportsPage}
            title={mainTitle}
          />
          <PrivateRoute
            isAuth={isAuth}
            isCognito={isCognito}
            exact
            path="/patient-print/:id/:allPatients/labnumber/:labnoDigitOnly+"
          >
            <PrintableReportsPage />
          </PrivateRoute>

          <PrivateRoute
            isAuth={isAuth}
            isCognito={isCognito}
            exact
            path="/combine-atomic-print/patient/:patientId/labnumber/:labnoDigitOnly+"
          >
            <PrintableCombineAtomicPage />
          </PrivateRoute>

          <PrivateRoute isAuth={isAuth} isCognito={isCognito} exact path="/combine-atomic-print/patients">
            <PrintablePatientsPage />
          </PrivateRoute>

          <PrivateRoute isAuth={isAuth} isCognito={isCognito} exact path="/combine-atomic-delegate-print/patients">
            <PrintablePatientsDelegationPage />
          </PrivateRoute>

          <PrivateRoute isAuth={isAuth} isCognito={isCognito} exact path="/patient/foodprint/:labnumber+">
            <FoodPrintReport />
          </PrivateRoute>

          <PrivateRoute
            isAuth={isAuth}
            isCognito={isCognito}
            path={[
              "/patient",
              "/patient-episodes",
              "/report",
              "/atomic",
              "/ct-combine-atomic",
              "/ct-combine-atomic-print",
            ]}
            title={mainTitle}
          >
            <PatientsContextProvider>
              <ProtectedRoute
                isAuth={isAuth}
                isCognito={isCognito}
                exact
                path="/patient-episodes"
                component={EpisodeListPage}
                title={mainTitle}
              />
            </PatientsContextProvider>
            <PatientDetailsContextProvider>
              <CumulativeContextProvider>
                <PrivateRoute
                  isAuth={isAuth}
                  isCognito={isCognito}
                  exact
                  path="/ct-combine-atomic-print/patient/:patientId/labnumber/:labnoDigitOnly+"
                >
                  <PrintableCombineCtAtomicPage />
                </PrivateRoute>

                {/* ACL Route */}
                <PrivateRoute
                  isAuth={isAuth}
                  isCognito={isCognito}
                  exact
                  path="/patient/:id/:allPatients/labnumber/:labnoDigitOnly([0-9]{2}-[0-9]{7,8})/:labnumber([0-9]{2}-[0-9]{7,8}-[A-Z0-9]{2,3}-[0-9]{1,2})/:sortBy?"
                >
                  <PatientDetailsPage />
                </PrivateRoute>
                {/* ACL Route */}
                <PrivateRoute
                  isAuth={isAuth}
                  isCognito={isCognito}
                  exact
                  path="/patient/:id/:allPatients/labnumber/:labnoDigitOnly([0-9]{2}-[0-9]{7,8})"
                >
                  <PatientDetailsPage />
                </PrivateRoute>

                {/* SSP Route */}
                <PrivateRoute
                  isAuth={isAuth}
                  isCognito={isCognito}
                  exact
                  path="/patient/:id/:allPatients/labnumber/:labnoDigitOnly([0-9]{2}/[SQR][0-9]{6})"
                >
                  <PatientDetailsPage />
                </PrivateRoute>

                {/* ACL Route */}
                <PrivateRoute
                  isAuth={isAuth}
                  isCognito={isCognito}
                  exact
                  path="/report/:id/:allPatients/labnumber/:labnoDigitOnly([0-9]{2}-[0-9]{7,8})/initial/:initialLabNo([0-9]{2}-[0-9]{7,8}-[A-Z0-9]{2,3}-[0-9]{1,2})/:sortBy?"
                >
                  <PatientReportsCondense />
                </PrivateRoute>

                <PrivateRoute
                  isAuth={isAuth}
                  isCognito={isCognito}
                  exact
                  path="/report/:id/:allPatients/labnumber/:labnoDigitOnly([0-9]{2}/[SQR][0-9]{6})/initial/:initialLabNo([0-9]{2}/[SQR][0-9]{6})/:sortBy?"
                >
                  <PatientReportsCondense />
                </PrivateRoute>
                {/* SSP Route */}
                <PrivateRoute
                  isAuth={isAuth}
                  isCognito={isCognito}
                  exact
                  path="/report/:id/:allPatients/labnumber/:labnoDigitOnly([0-9]{2}/[SQR][0-9]{6})"
                >
                  <PatientReportsCondense />
                </PrivateRoute>

                <PrivateRoute
                  isAuth={isAuth}
                  isCognito={isCognito}
                  exact
                  path="/atomic/:patientId/:allPatients/:panelId/:labnoDigitOnly+"
                >
                  <CumulativeMobilePage />
                </PrivateRoute>
              </CumulativeContextProvider>
            </PatientDetailsContextProvider>
          </PrivateRoute>

          <Route path="/version" exact>
            Version: {import.meta.env.VITE_VERSION}
          </Route>
          <Route path="*" render={() => "404 NOT FOUND"} />
        </Switch>
      </Router>
    </AppContext.Provider>
  );
};

export default App;
