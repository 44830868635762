import { LazyQueryExecFunction, MutationTuple, OperationVariables } from "@apollo/client";
import authService from "../../services/authService";
import { UpdateUserDeviceSettings, UpdateUserDeviceSettingsVariables } from "./types/UpdateUserDeviceSettings";
import { getCpdProviders } from "./types/getCpdProviders";

const getOperatingSystem = () => {
  let operatingSystem = "Not known";
  if (navigator.appVersion.indexOf("Win") !== -1) {
    operatingSystem = "Windows";
  }
  if (navigator.appVersion.indexOf("Mac") !== -1) {
    operatingSystem = "Mac";
  }
  if (navigator.appVersion.indexOf("X11") !== -1) {
    operatingSystem = "UNIX";
  }
  if (navigator.appVersion.indexOf("Linux") !== -1) {
    operatingSystem = "Linux";
  }

  return operatingSystem;
};

const getBrowser = () => {
  let currentBrowser = "Not known";
  if (navigator.userAgent.indexOf("Chrome") !== -1) {
    currentBrowser = "Google Chrome";
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    currentBrowser = "Mozilla Firefox";
  } else if (navigator.userAgent.indexOf("MSIE") !== -1) {
    currentBrowser = "Internet Explorer";
  } else if (navigator.userAgent.indexOf("Edge") !== -1) {
    currentBrowser = "Edge";
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    currentBrowser = "Safari";
  } else if (navigator.userAgent.indexOf("Opera") !== -1) {
    currentBrowser = "Opera";
  } else {
    console.log("Others");
  }

  return currentBrowser;
};

export async function storeMobileDeviceToken(
  updateUsersDeviceConfig: MutationTuple<UpdateUserDeviceSettings, UpdateUserDeviceSettingsVariables>[0],
  cpdProviders: LazyQueryExecFunction<getCpdProviders, OperationVariables>,
): Promise<void> {
  await cpdProviders({
    onCompleted: (data) => {
      authService.setCpdProviders(data.getCpdProviders);
    },
  });

  if (authService.isMobility()) {
    // Check and update device notification settings
    const devicevalue = authService.getMobileDeviceId();
    const device = devicevalue ? devicevalue.split("|") : [];
    if (device.length >= 4) {
      const userid = Number(authService.getUserId());
      const deviceid = device[0];
      const friendlyname = device[1];
      const platform = device[2];
      const enabled = device[3] === "true";

      await updateUsersDeviceConfig({
        variables: {
          input: {
            userid,
            deviceid,
            friendlyname,
            platform,
            enabled,
          },
        },
      });
    }
  } else {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then((registration: any) => {
        const applicationServerKey = import.meta.env.VITE_VAPID_PUBLIC_KEY;

        registration.pushManager.getSubscription().then(async (existedSubscription: any) => {
          const userid = Number(authService.getUserId());
          const friendlyname = `${getBrowser()} on ${getOperatingSystem()}`;
          const platform = "web";
          const enabled = true;

          if (existedSubscription === null) {
            console.log("No subscription detected, make a request.");

            registration.pushManager
              .subscribe({
                applicationServerKey: applicationServerKey,
                userVisibleOnly: true,
              })
              .then(async (newSubscription: any) => {
                console.log("New subscription added.");

                const deviceid = btoa(JSON.stringify(newSubscription));

                await updateUsersDeviceConfig({
                  variables: {
                    input: {
                      userid,
                      deviceid,
                      friendlyname,
                      platform,
                      enabled,
                    },
                  },
                });
              })
              .catch((error: any) => {
                if (Notification.permission !== "granted") {
                  console.log("Permission was not granted.");
                  console.log(Notification);
                  console.log(error);
                  console.log(applicationServerKey);
                } else {
                  console.error("An error ocurred during the subscription process.", error);
                }
              });
          } else {
            console.log("Existing subscription detected.");

            const deviceid = btoa(JSON.stringify(existedSubscription));

            await updateUsersDeviceConfig({
              variables: {
                input: {
                  userid,
                  deviceid,
                  friendlyname,
                  platform,
                  enabled,
                },
              },
            });
          }
        });
      });
    }
  }
}
