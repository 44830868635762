import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import styles from "./EditHospitalUser.module.css";
import get from "lodash/get";
import { Formik } from "formik";
import { UpdateUser } from "../HospAdminUserListTable/types/UpdateUser";
import { MutationTuple, useMutation } from "@apollo/client";
import { GraphQLError } from "graphql";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";
import { cloneDeep } from "lodash";
import UpdateUserHospitalReportsMutation from "../HospAdminUserListTable/UpdateUserHospitalReportsMutation";
import { UpdateUserHospitalReportsVariables } from "../HospAdminUserListTable/types/UpdateUserHospitalReports";
import Loading from "../Loading/Loading";

export type editUserHospitalReportsInputValues = {
  username: string;
  hospital_report_id: number[];
};

interface EditHospitalUserPageProps {
  open: boolean;
  fullscreen: boolean;
  onClose: (username: string, hospitalReportId: number[], isUpdate: boolean) => void;
  userHospitalReports: any;
}

const EditUserHospitalReportsDialog: React.FC<EditHospitalUserPageProps> = (props) => {
  const { userHospitalReports } = props;
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [userReports, setUserReports] = useState<number[]>([]);

  useEffect(() => {
    if (userHospitalReports.userHospitalReports && userHospitalReports.userHospitalReports.length > 0) {
      setUserReports(userHospitalReports.userHospitalReports);
    } else {
      setUserReports([]);
    }
  }, [userHospitalReports]);

  const handleClose = () => {
    setFeedbackMessage("");
    props.onClose(userHospitalReports.username, userReports, false);
  };

  const handleChangeEnabled = (id: number, checked: boolean) => {
    let userReps = cloneDeep(userReports);

    if (checked) {
      userReps.push(id);
    } else {
      userReps = userReps.filter((v) => v !== id);
    }

    setUserReports(userReps);
  };

  const initialValues: editUserHospitalReportsInputValues = {
    username: userHospitalReports.username,
    hospital_report_id: userReports,
  };

  const [editUserHospitalReports, { loading, error }] = useMutation<UpdateUser, UpdateUserHospitalReportsVariables>(
    UpdateUserHospitalReportsMutation,
  );

  if (error) {
    console.error("Error", error);
  }

  const submit = () => {
    onSubmit(userHospitalReports.username, userReports, editUserHospitalReports);
  };

  const onSubmit = async (
    username: string,
    hospitalReportId: number[],
    editUserHospitalReports: MutationTuple<UpdateUser, UpdateUserHospitalReportsVariables>[0],
  ) => {
    try {
      const result = await editUserHospitalReports({
        variables: {
          input: {
            username,
            hospitalReportId,
          },
        },
      });

      if (get(result, "data.updateUserHospitalReports")) {
        if (get(result, "data.updateUserHospitalReports.error")) {
          setFeedbackMessage(get(result, "data.updateUserHospitalReports.message", ""));
        } else {
          setFeedbackMessage("User Hospital Reports updated!");

          setTimeout(() => {
            props.onClose(userHospitalReports.username, userReports, true);
            setFeedbackMessage("");
          }, 500);
        }
      }
    } catch (e) {
      if (
        e.graphQLErrors &&
        (e.graphQLErrors as GraphQLError[]).find((m) => m.message === "Cannot submit") !== undefined
      ) {
        window.location.reload();
      } else {
        if (e.message) {
          setFeedbackMessage(e.message);
        }
      }
    }
  };

  return (
    <Dialog
      fullScreen={props.fullscreen}
      open={props.open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Formik initialValues={initialValues} onSubmit={submit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>
              <DialogBoxTitle
                title="Assign Hospital Reports to"
                supplementaryTitle={userHospitalReports.username}
                smallSupplementaryTitle
                width={"auto"}
                marginLeft={-3}
                closeButton
                handleClose={handleClose}
              />
            </DialogTitle>
            <DialogContent>
              <Box m={3}>
                {userHospitalReports.hospitalReports.map((h: any, i: number) => (
                  <Box display="flex" justifyContent="flex-start" align-content="center" key={i}>
                    <Checkbox
                      name="enabled"
                      color="primary"
                      checked={userReports.find((id: number) => id === h.id) ? true : false}
                      onChange={(_, checked) => handleChangeEnabled(h.id, checked)}
                    />
                    <Typography mt={1.2}>{h.name}</Typography>
                  </Box>
                ))}
              </Box>
              <Box m={3}>
                <Typography className={styles.MenuItem2}>{feedbackMessage}</Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <Box ml={3} mb={3} display="flex" justifyContent="space-between" align-content="strech">
                <Button
                  className={styles.spaceRight}
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={loading}
                >
                  {loading ? <Loading iconStyle={{ width: "24px", height: "24px", color: "grey" }} mt={0} /> : "Submit"}
                </Button>
              </Box>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditUserHospitalReportsDialog;
