import { ApolloError, MutationTuple, useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Link,
} from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import authService from "../../services/authService";
import AuthLayout from "../common/AuthLayout/AuthLayout";
import MainNavigation from "../MainNavigation/MainNavigation";
import styles from "./NotificationEmailPage.module.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import { get, filter, find } from "lodash";
import { NotificationEmailFormValues } from "./EmailValidation";
import AppContext from "../../context/AppContext";
import { UsersEmailConfigVariables, UsersEmailConfig } from "./types/UsersEmailConfig";
import { GetNotificationTypes } from "./types/GetNotificationTypes";
import getNotificationTypesQuery from "./GetNotificationTypesQuery";
import getUsersEmailConfig from "./NotificationSettingsQuery";
import getUserEmailMobile from "./UserEmailMobileQuery";
import UsersEmailConfigMutation from "./NotificationSettingsMutation";
import { GetUsersEmailConfig } from "./types/GetUsersEmailConfig";
import { userConfig, userEmail, userMobile, smsDelivery } from "../../graphql/types/gql-global-types";
import Loading from "../Loading/Loading";
import { GetUserEmailMobile, GetUserEmailMobile_getUserDevice_data } from "./types/GetUserEmailMobile";
import MobileNumberField from "../common/InputField/MobileNumberField";
import SettingsIcon from "@mui/icons-material/Settings";

type NotificationEmailBoxType = {
  onSubmit: (
    notificationConfig: NotificationType[],
    notificationEmail: userEmail[],
    notificationMobile: userMobile[],
    smsDelivery: smsDelivery,
  ) => Promise<void>;
  error?: ApolloError;
  loading?: boolean;
  submitSuccess: boolean;
  errorMessage?: string;
};

type NotificationTimeFrames = {
  type: string;
  minute: number;
  checked: boolean;
};

type NotificationType = {
  id: number;
  type: string;
  checked: boolean;
  timeframe: NotificationTimeFrames[];
  pendingEmail: boolean;
};

export const NotificationEmailBox: React.FC<NotificationEmailBoxType> = (props) => {
  const { error, loading, submitSuccess } = props;

  const initialValues: NotificationEmailFormValues = {
    message: "",
    newEmail: "",
    mobile: "",
  };

  const isDesktop = useContext(AppContext).isDesktop;
  const mobility = authService.isMobility();
  const hist = useHistory();

  const [emailNotifications, setEmailNotifications] = useState<userEmail[]>([]);
  const [mobileNotifications, setMobileNotifications] = useState<userMobile[]>([]);
  const [devices, setDevices] = useState<GetUserEmailMobile_getUserDevice_data[]>([]);
  const [hideme, setHideme] = useState("none");
  const [confirmNotification, setConfirmNotification] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [deliveryId, setDeliveryId] = useState(0);
  const [onlyBusinessHours, setOnlyBusinessHours] = useState(false);
  const [includeWeekends, setIncludeWeekends] = useState(false);
  const client = useApolloClient();

  const notificationTimeFrames: NotificationTimeFrames[] = [
    { type: "10 minutes", minute: 10, checked: false },
    { type: "hourly", minute: 60, checked: false },
    { type: "daily", minute: 1440, checked: false },
    { type: "none", minute: 0, checked: true },
  ];
  //        { type: 'none', checked: false },
  const notificationTypes: NotificationType[] = [];

  const [notificationConfig, setNotificationConfig] = useState<NotificationType[]>(notificationTypes);

  const { data: nconfig, loading: configLoading } = useQuery<GetNotificationTypes>(getNotificationTypesQuery);

  const { data: dataNConfigs, loading: emailConfigLoading } = useQuery<GetUsersEmailConfig>(getUsersEmailConfig, {
    variables: { userid: parseInt(authService.getUserId()) },
    fetchPolicy: "network-only",
  });

  const { data: dataUsersEmailMobile, loading: usersEmailMobileLoading } = useQuery<GetUserEmailMobile>(
    getUserEmailMobile,
    {
      variables: { userid: parseInt(authService.getUserId()) },
      fetchPolicy: "network-only",
    },
  );

  useEffect(() => {
    if (nconfig) {
      if (nconfig.getNotificationTypes && nconfig.getNotificationTypes.data) {
        let summaryChecked = false;
        nconfig.getNotificationTypes.data.map((notType: any) => {
          const notifyTimeFrame = JSON.parse(JSON.stringify(notificationTimeFrames)); //Old ways to deep clone without changing const.
          let mainChecked = false;
          let pendingChecked = false;
          if (dataNConfigs) {
            const usersEmailConfigList = dataNConfigs.getUsersEmailConfig.config;
            const fNotifyConfig = usersEmailConfigList.find((f: any) => f.notificationTypeId === notType.id);
            if (fNotifyConfig) {
              const timeFrameId = notifyTimeFrame.findIndex((obj: any) => obj.minute === fNotifyConfig.duration);
              pendingChecked = fNotifyConfig.pendingEmail === "true";
              if (notType.id === 1) {
                summaryChecked = true;
                notifyTimeFrame[timeFrameId].checked = true;
                notifyTimeFrame[notifyTimeFrame.length - 1].checked = false; //reset the none to false
                mainChecked = fNotifyConfig.notificationTypeId === notType.id;
              } else {
                if (!summaryChecked) {
                  notifyTimeFrame[timeFrameId].checked = true;
                  notifyTimeFrame[notifyTimeFrame.length - 1].checked = false; //reset the none to false
                  mainChecked = fNotifyConfig.notificationTypeId === notType.id;
                }
              }
            }
          }
          notificationTypes.push({
            id: notType.id,
            type: notType.typename,
            checked: mainChecked,
            timeframe: notifyTimeFrame,
            pendingEmail: pendingChecked,
          });

          return notificationTypes;
        });
        setNotificationConfig(notificationTypes);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nconfig, dataNConfigs]);

  useEffect(() => {
    if (dataUsersEmailMobile) {
      const usersEmailList = dataUsersEmailMobile.getUserEmail.data;
      setEmailNotifications(
        usersEmailList.map((u) => ({
          id: u.id || 0,
          email: u.email || "",
          enabled: u.enabled || false,
        })),
      );

      const usersMobileList = dataUsersEmailMobile.getUserMobile.data;
      setMobileNotifications(
        usersMobileList.map((u) => ({
          id: u.id || 0,
          mobile: u.mobile || "",
          enabled: u.enabled || false,
        })),
      );

      const smsDelivery = dataUsersEmailMobile.getSmsDelivery.data;
      setDeliveryId((smsDelivery.id as number) || 0);
      setOnlyBusinessHours((smsDelivery.onlyBusinessHours as boolean) || false);
      setIncludeWeekends((smsDelivery.includeWeekends as boolean) || false);

      setDevices(dataUsersEmailMobile.getUserDevice.data);

      setHideme("block");
    } else {
      setHideme("none");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUsersEmailMobile]);

  const submit = () => {
    const checkConfig = filter(notificationConfig, (config) => {
      const isNotiTimeframeFalse = find(config.timeframe, ["checked", true]);
      return config.checked === true && isNotiTimeframeFalse !== undefined;
    });

    const isNotiTimeframeFalse1 = find(checkConfig, {
      timeframe: ["checked", false],
    });

    if (isNotiTimeframeFalse1 !== undefined) {
      setErrorMessage("Please select at least one notification config");
      return;
    }

    if (!confirmNotification) {
      setErrorMessage("Please confirm notification settings");
      return;
    }

    const smsDelivery: smsDelivery = {
      id: deliveryId,
      onlyBusinessHours: onlyBusinessHours,
      includeWeekends: onlyBusinessHours ? includeWeekends : false,
    };

    setErrorMessage("");
    props.onSubmit(notificationConfig, emailNotifications, mobileNotifications, smsDelivery);
  };

  const handleEmailNotificationText = (i: any) => (e: any) => {
    const emailnotify = [...emailNotifications];
    const updatedEmailNotify = emailnotify.map((x, idx) => (idx === i ? { ...x, email: e.target.value.trim() } : x));
    setEmailNotifications(updatedEmailNotify);
  };

  const handleEmailNotificationEnabled = (i: any) => (e: any) => {
    const emailnotify = [...emailNotifications];
    const updatedEmailNotify = emailnotify.map((x, idx) => (idx === i ? { ...x, enabled: e.target.checked } : x));
    setEmailNotifications(updatedEmailNotify);
  };

  const handleDeleteEmailNotification = (i: any) => (e: any) => {
    e.preventDefault();
    const emailnotify = [...emailNotifications.slice(0, i), ...emailNotifications.slice(i + 1)];
    setEmailNotifications(emailnotify);
  };

  const handleAddEmailNotification = (e: any) => {
    e.preventDefault();
    if (hideme === "block") {
      const emailnotify = emailNotifications.concat([{ id: 0, email: "", enabled: true }]);
      setEmailNotifications(emailnotify);
    }

    setHideme("block");
  };

  const handleMobileNotificationText = (i: any, value: any) => {
    const mobileNotify = [...mobileNotifications];
    const onlyNums = value.replace(/[^0-9]/g, "");

    const updatedMobileNotify = mobileNotify.map((x, idx) => (idx === i ? { ...x, mobile: onlyNums.trim() } : x));
    setMobileNotifications(updatedMobileNotify);
  };

  const handleMobileNotificationEnabled = (i: any) => (e: any) => {
    const mobileNotify = [...mobileNotifications];
    const updatedMobileNotify = mobileNotify.map((x, idx) => (idx === i ? { ...x, enabled: e.target.checked } : x));
    setMobileNotifications(updatedMobileNotify);
  };

  const handleDeleteMobileNotification = (i: any) => (e: any) => {
    e.preventDefault();
    const mobileNotify = [...mobileNotifications.slice(0, i), ...mobileNotifications.slice(i + 1)];
    setMobileNotifications(mobileNotify);
  };

  const handleAddMobileNotification = (e: any) => {
    e.preventDefault();
    if (hideme === "block") {
      const mobileNotify = mobileNotifications.concat([{ id: 0, mobile: "", enabled: true }]);
      setMobileNotifications(mobileNotify);
    }

    setHideme("block");
  };

  const handlePendingEmailCheck = (e: any, c: any) => {
    setNotificationConfig(
      notificationConfig.map((ntype) => {
        const updatedConfig = { ...ntype };
        const ty = ntype.id;
        if (parseInt(e.target.name.split("-")[1]) === ty) {
          updatedConfig.pendingEmail = c;
        }

        return updatedConfig;
      }),
    );
  };
  const handleAddNotificationConfig = (e: any) => {
    setNotificationConfig(
      notificationConfig.map((ntype) => {
        const updatedConfig = { ...ntype };

        const ty = ntype.id;
        if (ty === 1) {
          //Only for summary to set it to none

          updatedConfig.timeframe = ntype.timeframe.map((time) => {
            const times = { ...time };
            if (`${ty}-${time.type}` === `${ty}-none`) times.checked = e.target.checked;
            else times.checked = false;

            return times;
          });
        }
        if (parseInt(e.target.value.split("-")[0]) === ty) {
          updatedConfig.timeframe = ntype.timeframe.map((time) => {
            const times = { ...time };
            if (`${ty}-${time.type}` === e.target.value) times.checked = e.target.checked;
            else times.checked = false;

            return times;
          });
        }

        return updatedConfig;
      }),
    );
  };

  const handleSummaryNotification = (e: any) => {
    setNotificationConfig(
      notificationConfig.map((ntype) => {
        const updatedConfig = { ...ntype };
        const ty = ntype.id;
        if (parseInt(e.target.value.split("-")[0]) === ty) {
          updatedConfig.timeframe = ntype.timeframe.map((time) => {
            const times = { ...time };
            if (`${ty}-${time.type}` === e.target.value) times.checked = e.target.checked;
            else times.checked = false;
            return times;
          });
        } else {
          updatedConfig.pendingEmail = false;
          updatedConfig.timeframe = ntype.timeframe.map((time) => {
            const times = { ...time };
            if (`${ty}-${time.type}` === `${ty}-none`) times.checked = e.target.checked;
            else times.checked = false;
            return times;
          });
        }

        return updatedConfig;
      }),
    );
  };

  const handleBack = () => {
    if (authService.getExpired()) {
      authService.clearAll();
      // TODO: shouldn't need to explicitly navigate to '/' if we are behind a <PrivateRoute />
      client.resetStore();
      hist.push("/login");
    } else if (get(hist.location, "state.from")) {
      hist.push(get(hist.location, "state.from", ""));
    } else {
      hist.goBack();
    }
  };

  return (
    <Grid container className={isDesktop ? styles.mainBox : styles.mainBoxM}>
      <Grid item xs={12}>
        <AuthLayout title="Notification Settings" maxWidth={"900px"}>
          <Box mt={5}>
            <Box textAlign={"right"} mb={5} mt={-8}>
              <Link color="primary" onClick={handleBack} className={styles.backLink}>
                Back
              </Link>
            </Box>
            <Formik initialValues={initialValues} onSubmit={submit}>
              {({ errors, handleSubmit, touched }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <span>
                      <Box>
                        {error &&
                          error.graphQLErrors.map(({ message }, i) => (
                            <Typography key={i} color="error">
                              {message}
                            </Typography>
                          ))}
                      </Box>
                      <Box>
                        {errors.message && touched.newEmail && <Typography color="error">{errors.message}</Typography>}
                      </Box>
                      <Box>{errorMessage && <Typography color="error">{errorMessage}</Typography>}</Box>
                      <Box mt={2} className={styles.notifyEmailHeader}>
                        <Typography>Notification Types</Typography>
                      </Box>
                      <Box className={styles.notifyBox}>
                        {configLoading || emailConfigLoading ? (
                          <Loading />
                        ) : (
                          notificationConfig.map((ntype) => {
                            return (
                              <>
                                <Grid container spacing={1} alignItems="center" justifyContent="center">
                                  <Grid item xs={12} md={4}>
                                    <Typography>{ntype.type}</Typography>
                                  </Grid>
                                  <Grid item xs={12} md={8}>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-radio-buttons-group-label"
                                      name="radio-buttons-group"
                                    >
                                      {ntype.timeframe.map((timef, index) => {
                                        return (
                                          <FormControlLabel
                                            value={`${ntype.id}-${timef.type}`}
                                            key={index}
                                            control={
                                              <Radio
                                                checked={timef.checked}
                                                color="primary"
                                                onChange={
                                                  ntype.id === 1
                                                    ? handleSummaryNotification
                                                    : handleAddNotificationConfig
                                                }
                                              />
                                            }
                                            label={timef.type}
                                          />
                                        );
                                      })}
                                      <Box>
                                        <Checkbox
                                          name={`pending-${ntype.id}`}
                                          color="primary"
                                          checked={ntype.pendingEmail}
                                          onChange={(e, checked) => {
                                            handlePendingEmailCheck(e, checked);
                                          }}
                                        />
                                        inc. pending
                                      </Box>
                                    </RadioGroup>
                                  </Grid>
                                </Grid>
                                {(!isDesktop || mobility) && <hr />}
                              </>
                            );
                          })
                        )}
                      </Box>
                      <Box style={{ marginTop: "20px" }}>
                        <hr />
                      </Box>
                      <Box>
                        <Typography color="primary" className={styles.subHeading}>
                          Email Notifications
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className={styles.paddingTop} color="grey.100">
                          Your email will be used for new results notifications.
                        </Typography>
                      </Box>
                      <Box className={styles.notifyEmailHeader}>
                        <Typography>Add or remove emails</Typography>
                      </Box>
                      <Box className={styles.notifyEmailBox}>
                        {usersEmailMobileLoading ? (
                          <Loading />
                        ) : (
                          <>
                            <Button
                              variant="contained"
                              size="small"
                              className={styles.button}
                              startIcon={<AddCircleOutlineIcon />}
                              onClick={handleAddEmailNotification}
                            >
                              Add Email
                            </Button>
                            <br />
                            <Box
                              id="emailN"
                              //display={hideme}
                              className={styles.paddingTop}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                                key={99999}
                              >
                                <Box sx={{ flexGrow: 8, flexBasis: "10%" }}></Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexGrow: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography>Remove</Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexGrow: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography>Active</Typography>
                                </Box>
                              </Box>
                              {emailNotifications.map((emailNotification, index) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                  key={index}
                                >
                                  <Box sx={{ flexGrow: 8, flexBasis: "10%" }}>
                                    <TextField
                                      key={"email-" + index}
                                      label="Email"
                                      placeholder="Input Email"
                                      variant="outlined"
                                      required={hideme === "block" ? true : false}
                                      className={styles.specialInput}
                                      onChange={handleEmailNotificationText(index)}
                                      size="small"
                                      value={emailNotification.email}
                                      type="email"
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexGrow: 1,
                                      alignContent: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <IconButton
                                      color="secondary"
                                      aria-label="Delete Email"
                                      component="span"
                                      size="small"
                                      onClick={handleDeleteEmailNotification(index)}
                                    >
                                      <HighlightOffIcon />
                                    </IconButton>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexGrow: 1,
                                      alignContent: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Checkbox
                                      key={"enabled-" + index}
                                      color="primary"
                                      checked={emailNotification.enabled ? emailNotification.enabled : false}
                                      onChange={handleEmailNotificationEnabled(index)}
                                    />
                                  </Box>
                                </Box>
                              ))}
                            </Box>
                          </>
                        )}
                      </Box>
                      <Box style={{ marginTop: "20px" }}>
                        <hr />
                      </Box>
                      <Box>
                        <Typography color="primary" className={styles.subHeading}>
                          SMS Notifications
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className={styles.paddingTop} color="grey.100">
                          Your mobile number will be used for new results notifications.
                        </Typography>
                      </Box>
                      <Box className={styles.notifyEmailHeader}>
                        <Typography>Add or remove mobile numbers</Typography>
                      </Box>
                      <Box className={styles.notifyEmailBox}>
                        {usersEmailMobileLoading ? (
                          <Loading />
                        ) : (
                          <>
                            <Button
                              variant="contained"
                              size="small"
                              className={styles.button}
                              startIcon={<AddCircleOutlineIcon />}
                              onClick={handleAddMobileNotification}
                            >
                              Add Mobile Number
                            </Button>
                            <br />
                            <Box id="mobileN" className={styles.paddingTop}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                                key={99999}
                              >
                                <Box sx={{ flexGrow: 8, flexBasis: "10%" }}></Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexGrow: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography>Remove</Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexGrow: 1,
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography>Active</Typography>
                                </Box>
                              </Box>
                              {mobileNotifications.map((mobileNotification, index) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                  key={index}
                                >
                                  <Box sx={{ flexGrow: 8, flexBasis: "10%" }}>
                                    <MobileNumberField
                                      key={"mobile-" + index}
                                      id={"mobile-" + index}
                                      error={touched.mobile && Boolean(errors.mobile)}
                                      helperText={touched.mobile && errors.mobile}
                                      inputChange={(value: any) => {
                                        handleMobileNotificationText(index, value);
                                      }}
                                      initState={mobileNotification.mobile}
                                      required
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexGrow: 1,
                                      alignContent: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <IconButton
                                      color="secondary"
                                      aria-label="Delete Mobile Number"
                                      component="span"
                                      size="small"
                                      onClick={handleDeleteMobileNotification(index)}
                                    >
                                      <HighlightOffIcon />
                                    </IconButton>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexGrow: 1,
                                      alignContent: "center",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Checkbox
                                      key={"enabled-" + index}
                                      color="primary"
                                      checked={mobileNotification.enabled ? mobileNotification.enabled : false}
                                      onChange={handleMobileNotificationEnabled(index)}
                                    />
                                  </Box>
                                </Box>
                              ))}
                            </Box>
                          </>
                        )}
                      </Box>
                      <Box style={{ marginTop: "20px" }}>
                        <hr className={styles.dashed} />
                      </Box>
                      <Box>
                        <Typography color="primary" className={styles.subHeading}>
                          SMS Delivery Options
                        </Typography>
                        <Box mt={2} display="flex" alignItems="center">
                          <Checkbox
                            checked={onlyBusinessHours}
                            name="onlyBusinessHours"
                            color="primary"
                            onChange={(_, checked) => {
                              setOnlyBusinessHours(checked);
                            }}
                          />
                          <Typography>Only send during business hours (8:00am - 6:00pm)</Typography>
                        </Box>
                        <Box mt={2} display="flex" alignItems="center" style={{ margin: "0 30px" }}>
                          <Checkbox
                            checked={includeWeekends}
                            name="includeWeekends"
                            color="primary"
                            disabled={!onlyBusinessHours}
                            onChange={(_, checked) => {
                              setIncludeWeekends(checked);
                            }}
                          />
                          <Typography>Include weekends</Typography>
                        </Box>
                        <Box style={{ marginTop: "20px" }}>
                          <hr />
                        </Box>
                      </Box>
                      <Box>
                        <Typography color="primary" className={styles.subHeading}>
                          Web Push Notifications
                        </Typography>
                        <Box mt={2} display="flex" alignItems="center">
                          <Typography>
                            You have {devices.filter((device) => device.platform === "web").length} web browser(s)
                            enabled to receive notifications
                          </Typography>
                        </Box>
                        <Box mt={2} display="flex" alignItems="center">
                          <Typography>
                            To receive notifications, verify that notifications for eResults are enabled in your
                            browser(s) settings <SettingsIcon fontSize="inherit" />
                          </Typography>
                        </Box>
                        <Box style={{ marginTop: "20px" }}>
                          <hr />
                        </Box>
                      </Box>
                      {mobility && (
                        <Box>
                          <Typography color="primary" className={styles.subHeading}>
                            Push Notifications
                          </Typography>
                          <Box mt={2} display="flex" alignItems="center">
                            <Typography>
                              You have {devices.filter((device) => device.platform !== "web").length} connected
                              device(s)
                            </Typography>
                          </Box>
                          <Box mt={2} display="flex" alignItems="center">
                            <Typography>
                              To receive notifications, notifications for the eResults App must be enabled on your
                              device(s). Please check your device Settings <SettingsIcon fontSize="inherit" />
                            </Typography>
                          </Box>
                          <Box style={{ marginTop: "20px" }}>
                            <hr />
                          </Box>
                        </Box>
                      )}
                    </span>
                    <Box mt={2} display="block" justifyContent="space-between" alignItems="center">
                      <Typography color="primary" className={styles.subHeading}>
                        Please confirm your notification settings:
                      </Typography>
                      <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                        <Checkbox
                          checked={confirmNotification}
                          name="previewMode"
                          color="primary"
                          onChange={(_, checked) => {
                            setConfirmNotification(checked);
                          }}
                        />
                        <Typography>
                          The Notifications feature should not be a complete replacement for checking eResults for
                          results, as there may be rare circumstances which prevent you from receiving your
                          notifications. Please add noreply@clinicallabs.com.au to your safe senders list.
                        </Typography>
                      </Box>
                    </Box>
                    <Box mt={2} display="flex" justifyContent="flex-end" alignItems="center">
                      {submitSuccess && <Box className={styles.successMessage}>Successfully saved your changes.</Box>}
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={
                          loading ||
                          configLoading ||
                          emailConfigLoading ||
                          usersEmailMobileLoading ||
                          !confirmNotification
                        }
                      >
                        Save
                      </Button>
                    </Box>
                  </form>
                );
              }}
            </Formik>
          </Box>
        </AuthLayout>
      </Grid>
    </Grid>
  );
};

const NotificationSettingsPage: React.FC<any> = () => {
  const [success, setSuccess] = useState(false);
  const [usersEmailConfig, { loading, error }] = useMutation<UsersEmailConfig, UsersEmailConfigVariables>(
    UsersEmailConfigMutation,
  );

  if (error) {
    console.error("Error", error);
  }

  const onSubmit =
    (usersEmailConfigFunc: MutationTuple<UsersEmailConfig, UsersEmailConfigVariables>[0]) =>
    async (
      notificationConfig: NotificationType[],
      notificationEmails: userEmail[],
      notificationMobiles: userMobile[],
      smsDelivery: smsDelivery,
    ) => {
      try {
        setSuccess(false);

        const varNotificationConfig: userConfig[] = [];

        for (const cfg of notificationConfig) {
          let duration = 0;

          for (const time of cfg.timeframe) {
            if (time.checked) {
              duration = time.minute;
              break;
            }
          }

          varNotificationConfig.push({
            userid: parseInt(authService.getUserId()),
            notificationTypeId: cfg.id,
            duration: duration,
            pendingEmail: cfg.pendingEmail,
          });
        }

        await usersEmailConfigFunc({
          variables: {
            input: {
              userid: parseInt(authService.getUserId()),
              userConfigs: [...varNotificationConfig],
              userEmails: [...notificationEmails],
              userMobiles: [...notificationMobiles],
              smsDelivery: smsDelivery,
            },
          },
        });

        if (!error) {
          setSuccess(true);
        }
      } catch {
        setTimeout(function () {
          setSuccess(false);
        }, 3000);
      }
    };

  return (
    <Fragment>
      <MainNavigation hideTopMenu />
      <NotificationEmailBox
        onSubmit={onSubmit(usersEmailConfig)}
        error={error}
        loading={loading}
        submitSuccess={success}
      />
    </Fragment>
  );
};

export default NotificationSettingsPage;
