import { gql } from "@apollo/client";

export default gql`
  query getHospitalReportLink($id: Int!) {
    getHospitalReportLink(id: $id) {
      url
      expiredAt
    }
  }
`;
