import { gql } from "@apollo/client";
export default gql`
  mutation ChangeEmail($input: ChangeEmailInput!) {
    changeEmail(input: $input) {
      username
      resultNotification
      notificationEmails
      email
      resetMfa
      mobileNumber
      discoverable
    }
  }
`;
