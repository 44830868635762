import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, Divider, Button, CircularProgress, useMediaQuery } from "@mui/material";
import {
  GetEpisodeReports_episodereports_reports as Report,
  GetEpisodeReportsVariables as Episode,
} from "../../PatientReportsCondense/types/GetEpisodeReports";
import PrintCheckBox from "./PrintCheckbox";
import styles from "./PrintMenu.module.css";
import {
  PrintMenuIcon,
  PrintPopover,
  DownloadMenuIcon,
  SendMenuIcon,
  PlusMenuIcon,
  EorderMenuIcon,
  NewEorderMenuButton,
  ShareMenuIcon,
} from "./PrintComponents";
import axios from "axios";
import AppContext from "../../../context/AppContext";
import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import {
  GetPrintableReports,
  GetPrintableReportsVariables,
  GetPrintableReports_episodereports_reports as dReport,
  GetPrintableReports_patient as dPatient,
} from "../../PrintableReports/types/GetPrintableReports";
import {
  GetPrintableCombineCtAtomicAuditLog_patient as ctAtomicPatient,
  GetPrintableCombineCtAtomicAuditLog_ctcombineAtomic as ctAtomicData,
} from "../../PrintableReports/types/GetPrintableCombineCtAtomicAuditLog";
import getPrintableReports from "../../PrintableReports/GetPrintableReportQuery";
import getPrintableCtAtomicReport from "../../PrintableReports/GetPrintableCombineCtAtomicQuery";
import getPrintableAuditLogReport from "../../PrintableReports/GetPrintableAuditLogQuery";
import authService from "../../../services/authService";
import get from "lodash/get";
import { useHistory } from "react-router-dom";
import MarkAsViewedMutation from "../../common/MarkAsViewedMutation/MarkAsViewedMutation";
import { MarkAsViewed } from "../../common/MarkAsViewedMutation/types/MarkAsViewed";
import { InsertLogs } from "../../common/AuditLogs/types/InsertLogs";
import AuditLogMutation from "../../common/AuditLogs/AuditLogMutation";
import { updatePatientepisodesCache } from "../../../utils/updateApolloCache";
import setViewed from "../../../utils/setViewed";
import * as Constants from "../../../constants";
import {
  GetCombinedReport_combinedReport as ctReport,
  GetCombinedReportVariables as ctEpisode,
} from "../../CombinedReport/types/GetCombinedReport";
import {
  GetPrintableAuditLog,
  GetPrintableAuditLogVariables,
  GetPrintableAuditLog_getAuditLogs as ctAuditLogs,
} from "../../PrintableReports/types/GetPrintableAuditLog";
import { QueueDownloads, QueueDownloadsVariables } from "../../PatientDetailsPage/types/QueueDownloads";
import { MutationTuple } from "@apollo/client";
import QueueDownloadsMutation from "../../PatientDetailsPage/QueueDownloadsMutation";
import SuccessPopover from "../../common/SuccessPopover/SuccessPopover";
import AddOnTestDialog from "../../AddOnTest/AddOnTest";
import theme from "../../theme";
import { GetPatient_patient } from "../../PatientDetailsPage/types/GetPatient";
import { GetAddOnTests } from "../../AddOnTest/types/GetAddOnTests";
import GetAddOnTestsQuery from "../../AddOnTest/GetAddOnTestsQuery";
import { useQuery } from "@apollo/client";
import { GetStateFromSuburb } from "../../AddOnTest/types/GetStateFromSuburb";
import GetStateFromSuburbQuery from "../../AddOnTest/GetStateFromSuburbQuery";
import { ausTimeZones, getDiffInHours } from "../../../utils/date";
import PrintFrame from "../../ReportViewer/PrintFrame";
import EorderDialog from "../../EorderDialog/EorderDialog";
import {
  ADDON_IGNORE,
  EORDER_BLACKLIST,
  EORDER_FEATURE,
  EORDER_USE_IFRAME,
  getSiteOption,
  showEorderButton,
} from "../../../utils/siteOptions";
import { APPROVAL_STATUS } from "../../../constants";
import { CumulativeContext } from "../../../context/CumulativeContext";
import _ from "lodash";
import { UpdatePanelStatusPrinted, UpdatePanelStatusPrintedVariables } from "../types/UpdatePanelStatusPrinted";
import updatePanelStatusPrintedMuation from "../updatePanelStatusPrintedMutation";
import { CLINCAL_TRIAL_USER_TYPE, INTERUM_STATUS } from "../../../constants";
import { validateProvider } from "../../../utils/provider";
import {
  GetPrintableCombineCtAtomic,
  GetPrintableCombineCtAtomicVariables,
} from "../../PrintableReports/types/GetPrintableCombineCtAtomic";
import { isTenantVet } from "../../../services/tenantService";
import GetPatient from "../../PatientDetailsPage/GetPatient";
import { GetPatient as GetPatientQueryType } from "../../PatientDetailsPage/types/GetPatient";
import { downloadHelper } from "../../../utils/downloadHelper";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(timezone);

type PrintMenuProps = {
  reports: Report[];
  episode: Episode;
  ctReport?: (ctReport | null)[];
  ctEpisode?: ctEpisode;
  allPatients: boolean;
  deliveryMode: string;
  patientId: number;
  sortBy?: string | null;
  printCombined: boolean;
  smscEnabled?: boolean;
  patient?: any;
  reportingProviderNo: string;
  className?: string;
  isAddon?: boolean | false;
  onClickAction?: () => void;
  setReportsSelected?: (arg0: any) => void;
};

const PrintMenu: React.FC<PrintMenuProps> = ({
  reports,
  episode,
  allPatients,
  deliveryMode,
  patientId,
  sortBy,
  printCombined,
  smscEnabled,
  patient,
  reportingProviderNo,
  className,
  isAddon,
  onClickAction,
  setReportsSelected,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [reportsToPrint, setReportsToPrint] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const setError = useContext(AppContext).setError;
  const history = useHistory();
  const mobility = authService.isMobility();
  const [queueDownloads] = useMutation<QueueDownloads, QueueDownloadsVariables>(QueueDownloadsMutation);
  const setSuccess = useContext(AppContext).setSuccess;
  const [openAddOnTestDialog, setOpenAddOnTestDialog] = React.useState(false);
  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [addOnTests, setAddOnTests] = React.useState<string[]>([]);
  const [addOnTestRules, setAddOnTestRules] = React.useState<any[]>([]);
  const [currentClock, setCurrentClock] = React.useState<number>(0);
  const [ageInHours, setAgeInHours] = React.useState<number>(0);
  const [sendState, setSendState] = React.useState<string>("");
  const [openEorderDialog, setOpenEorderDialog] = React.useState(false);
  const [showGenEorderButton, setShowGenEorderButton] = React.useState(false);
  const siteOptions = getSiteOption(EORDER_BLACKLIST) as string[];
  const [ready, setReady] = React.useState(false);
  const { atomics, setAtomics } = useContext(CumulativeContext);
  const eOrderFeature = (getSiteOption(EORDER_FEATURE) as string) === "ON";
  const eOrderUseIframe = (getSiteOption(EORDER_USE_IFRAME) as string) === "ON";
  const [isValidProvider, setIsValidProvider] = React.useState(false);
  const [patientInfo, setPatientInfo] = useState<GetPatient_patient | undefined>();

  const { data: patientData } = useQuery<GetPatientQueryType>(GetPatient, {
    variables: { patientId: patientId },
  });

  useEffect(() => {
    setReportsSelected?.(reportsToPrint);
  }, [reportsToPrint]);

  useEffect(() => {
    if (patientData?.patient) {
      setPatientInfo(patientData?.patient);
    }

    if (reportingProviderNo && reportingProviderNo !== "") {
      setShowGenEorderButton(showEorderButton(siteOptions, reportingProviderNo));

      authService.getProviders().map((value: string) => {
        if (validateProvider(value)) {
          setIsValidProvider(true);
        }
      });
    }
  }, [reportingProviderNo, siteOptions, patientData?.patient]);

  const isDesktop = useContext(AppContext).isDesktop;

  const [markAsViewed] = useMutation<MarkAsViewed>(MarkAsViewedMutation, {
    ignoreResults: true,
  });
  const [auditLog] = useMutation<InsertLogs>(AuditLogMutation, {
    ignoreResults: true,
  });

  const client = useApolloClient();

  const onMarkAsViewed = (reportId: string) => {
    updatePatientepisodesCache(client, reportId, {
      patientId: patientId,
      providers: authService.getProviders(),
      allPatients: allPatients,
      sortBy: sortBy,
      showRemovedPanels: authService.getShowDeletedPanels(),
    });
  };

  const reportPrintArray = (): string[] =>
    Object.keys(reportsToPrint)
      .map((key: any) => !!reportsToPrint[key] && key)
      .filter((key: any) => !!key);

  const reportPrintHasCumulative = (): boolean => {
    return reports
      .filter((r) => r.cumulative)
      .map((r) => (r.reportid ? r.reportid.toString() : ""))
      .some((id) => reportPrintArray().includes(id));
  };

  const allReportsSelected = (): boolean => reportPrintArray().length === reports.length;

  const receiveMessage = (event: any): void => {
    if (event.data && event.data === "eResults2.0 Print Ready") {
      window.removeEventListener("message", receiveMessage, false);
      setIsLoading(false);
      setAnchorEl(null);
    }
    if (event.data && event.data === "PRINT_DATA_LOADED") {
      updateEpisodeStatus(Object.keys(reportsToPrint).map((x) => parseInt(x)));
    }
  };

  const [updatePanelStatusPrinted] = useMutation<UpdatePanelStatusPrinted, UpdatePanelStatusPrintedVariables>(
    updatePanelStatusPrintedMuation,
  );

  const updateEpisodeStatus = (reportIds: number[]) => {
    const userId = parseInt(authService.getUserId());
    updatePanelStatusPrinted({
      variables: {
        input: {
          reportIds,
          userId,
        },
      },
    }).then(async (result: any) => {
      if (result && result.data) {
        if (result.data.updatePanelStatusPrinted) {
          if (result.data.updatePanelStatusPrinted.length > 0) {
            const newAtomics = _.cloneDeep(atomics);
            let shouldResetStatus = false;
            result.data.updatePanelStatusPrinted.forEach(async (approveData: any) => {
              const panelApprovalStatus = approveData.csPanelApprovalStatus
                ? approveData.csPanelApprovalStatus
                : APPROVAL_STATUS.Printed;

              shouldResetStatus = panelApprovalStatus == APPROVAL_STATUS.Printed;

              newAtomics.forEach((newAtomic) => {
                if (newAtomic?.reportId === approveData.reportId) {
                  if (newAtomic?.csPanelApprovalStatus !== undefined) {
                    newAtomic.csPanelApprovalStatus = panelApprovalStatus;
                  }
                }
              });
            });
            if (shouldResetStatus) {
              newAtomics.forEach((newAtomic) => {
                if (newAtomic) {
                  // this will trigger status recalculation
                  newAtomic.status = "";
                }
              });
            }
            setAtomics(newAtomics);
          }
        }
      }
    });
  };

  const onPrintClick = (event: any) => {
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setReportsToPrint({});
    setAnchorEl(null);
    setIsLoading(false);
  };

  const onSelectAllChange = (): void => {
    setIsLoading(false);
    if (allReportsSelected()) {
      setReportsToPrint({});
    } else {
      const allReports: any = {};
      reports.forEach((report: any) => (allReports[report.reportid] = true));
      setReportsToPrint(allReports);
    }
  };

  const onSelectReportChange = (reportid: string): void => {
    setIsLoading(false);
    // if(!printCombined){
    setReportsToPrint({
      ...reportsToPrint,
      [reportid]: !reportsToPrint[reportid],
    });
    // }else{
    //   setCtReportsToPrint({
    //     ...ctReportsToPrint,
    //     [reportid]: !reportsToPrint[reportid],
    //   });
    // }
  };

  const allowPending =
    authService.getClinicalTrial() !== CLINCAL_TRIAL_USER_TYPE.Standard && authService.getReportView() === "2"
      ? true
      : false;

  const hasFinalReports: boolean = reports.some((report) =>
    allowPending
      ? report.interimStatus !== INTERUM_STATUS.Final &&
        report.interimStatus !== INTERUM_STATUS.Preliminary &&
        report.interimStatus !== INTERUM_STATUS.FinalCorrected &&
        report.interimStatus !== INTERUM_STATUS.Pending
      : report.interimStatus !== INTERUM_STATUS.Final &&
        report.interimStatus !== INTERUM_STATUS.Preliminary &&
        report.interimStatus !== INTERUM_STATUS.FinalCorrected,
  );

  const pushMarkView = () => {
    const selectedPanels: any[] = [];
    const getAllSelectedPanels = new Promise<void>((resolve) => {
      Object.keys(reportsToPrint).forEach((reportId, index, array) => {
        reports.forEach((panel: any) => {
          if (panel.reportid.toString() === reportId) {
            selectedPanels.push(panel);
          }
        });
        if (index === array.length - 1) resolve();
      });
    });

    getAllSelectedPanels.then(() => {
      selectedPanels.forEach((panel) => {
        setViewed(allPatients, markAsViewed, auditLog, onMarkAsViewed, panel);
      });
    });
  };

  const logDownloaded = async () => {
    const value = JSON.stringify({
      reportIds: reportPrintArray().map((r) => parseInt(r)),
      useragent: navigator.userAgent,
    });
    const action = "DOWNLOAD";
    await auditLog({
      variables: {
        audit: {
          value,
          action,
        },
      },
    });
  };

  const printReports = () => {
    setIsLoading(true);
    window.addEventListener("message", receiveMessage, false);

    // Defer until rerender, prevents jank
    if (!authService.fetchToken()?.accessToken) {
      history.push("/login", { from: history.location });
    } else {
      if (printCombined) {
        // eslint-disable-next-line no-console
        ctCombinedReportsHandler(Constants.AuditAction.PRINT_COMPRESSED);
      } else {
        setTimeout(() => {
          const reportsCsv: string = reportPrintArray().reverse().join(",");
          pushMarkView();
          const iframe: any = document.querySelector("#printFrame");
          iframe.src = `/patient-print/${episode.patientId}/${allPatients ? "1" : "0"}/labnumber/${
            episode.labnoDigitOnly
          }?ids=${reportsCsv}`;
        }, 200);
      }
    }
  };

  const printCombineReports = () => {
    setIsLoading(true);
    window.addEventListener("message", receiveMessage, false);

    // Defer until rerender, prevents jank
    if (!authService.fetchToken()?.accessToken) {
      history.push("/login", { from: history.location });
    } else {
      setTimeout(() => {
        const reportsCsv: string = reportPrintArray().reverse().join(",");
        pushMarkView();
        const iframe: any = document.querySelector("#printFrame");
        iframe.src = `/combine-atomic-print/patient/${episode.patientId}/labnumber/${episode.labnoDigitOnly}?downloadMode=0&ids=${reportsCsv}`;
      }, 200);
    }
  };

  const queryCombineCtAtomicVariables = (queryAction: Constants.AuditAction): GetPrintableCombineCtAtomicVariables => ({
    patientId: episode.patientId ? episode.patientId : 0,
    labnoDigitOnly: episode.labnoDigitOnly || "",
    ids: reportPrintArray()
      .map((r) => parseInt(r))
      .reverse(),
    queryAction: queryAction,
  });

  const queryAuditLogVariables = (reportIds: number[], labNoDigitOnly: string): GetPrintableAuditLogVariables => {
    return {
      action: "CLINTRIAL",
      reportIds,
      labNoDigitOnly,
      auditLog: true,
    };
  };

  const [getPrintableCtAtomicReportQuery, { error: cterror }] = useLazyQuery<GetPrintableCombineCtAtomic>(
    getPrintableCtAtomicReport,
    {
      variables: queryCombineCtAtomicVariables(Constants.AuditAction.DOWNLOAD),
    },
  );

  const [getPrintableAuditLogReportQuery, { error: alerror }] = useLazyQuery<GetPrintableAuditLog>(
    getPrintableAuditLogReport,
    {
      variables: queryAuditLogVariables([], ""),
    },
  );

  if (alerror) {
    const errorMessage = Constants.NO_DB_CONNECTION;

    setError(errorMessage);
  }

  const ctCombinedReportsHandler = async (queryAction: Constants.AuditAction) => {
    const variables = queryCombineCtAtomicVariables(queryAction);
    const downRepData: any = {};
    const ctdata = await getPrintableCtAtomicReportQuery({
      variables: variables,
      fetchPolicy: "network-only",
    });
    const hasValidCtData = (data: GetPrintableCombineCtAtomic | undefined) =>
      data && data.ctcombineAtomic && data.patient;

    if (hasValidCtData(ctdata.data) && variables.labnoDigitOnly && variables.ids) {
      const alvariables = queryAuditLogVariables(variables.ids, variables.labnoDigitOnly);
      const aldata = await getPrintableAuditLogReportQuery({
        variables: alvariables,
        fetchPolicy: "network-only",
      });

      const ctAtomicResults = get(ctdata.data, "ctcombineAtomic") || ({} as ctAtomicData);
      const ctatomicPatient: ctAtomicPatient = get(ctdata.data, "patient") || ({} as ctAtomicPatient);
      const userHospital = get(ctdata.data, "getUserHospital");

      const ctAuditLog: any = {};
      ctAuditLog.auditlog = true;
      if (aldata.data) {
        const ctauditlogs: ctAuditLogs[] = get(aldata.data, "getAuditLogs") || ({} as ctAuditLogs);
        ctAuditLog.auditlogs = ctauditlogs;
      }

      downRepData.reportDisclaimer = userHospital?.reportDisclaimer ? userHospital.reportDisclaimer : "";
      downRepData.patient = ctatomicPatient;
      downRepData.reports = ctAtomicResults;
      downRepData.auditlogs = ctAuditLog;
    }

    if (queryAction === Constants.AuditAction.PRINT_COMPRESSED) {
      setIsLoading(true);
      window.addEventListener("message", receiveMessage, false);

      authService.setReportsBlob({ reportData: [downRepData] });
      const tokenValue = "session-storage";

      if (!authService.fetchToken()?.accessToken) {
        history.push("/login", { from: history.location });
      } else {
        setTimeout(() => {
          //Marked all report id as viewed..
          //   setPrint(true);
          if (variables.ids) {
            updateEpisodeStatus(variables.ids);
          }

          const iframe: any = document.querySelector("#printFrame");
          iframe.src = `/combine-atomic-delegate-print/patients?token=${tokenValue}&frame=printFrame`;
        }, 200);
      }
    } else if (queryAction === Constants.AuditAction.DOWNLOAD) {
      setIsLoading(true);
      const url = `${import.meta.env.VITE_ACL_REPORT_URL}/ctreport`;

      axios
        .post<any>(
          url,
          {
            output: "pdf",
            data: {
              tz: dayjs.tz.guess(),
              orders: [downRepData],
            },
            template: "ctreport-template.html",
          },
          { responseType: "blob" },
        )
        .then((response) => {
          downloadHelper(response.data, `reports-${episode.labnoDigitOnly}.pdf`, true).then(() => {
            if (variables.ids) {
              updateEpisodeStatus(variables.ids);
            }

            setIsLoading(false);
            setReportsToPrint({});
            setAnchorEl(null);
            setError("");
            pushMarkView();
          });
        })
        .catch((e) => {
          setIsLoading(false);
          setReportsToPrint({});
          setError(e.message ? e.message : e);
          setAnchorEl(null);
        });
    }
  };

  const queryVariables = (): GetPrintableReportsVariables => ({
    patientId: episode.patientId,
    providers: authService.getProviders(),
    labnoDigitOnly: episode.labnoDigitOnly,
    includePdf: true,
    allPatients: allPatients,
    ids: reportPrintArray()
      .map((r) => parseInt(r))
      .reverse(),
  });

  const [getPrintableReportsQuery, { error }] = useLazyQuery<GetPrintableReports>(getPrintableReports, {
    variables: queryVariables(),
  });

  const downloadReportsHandler = async () => {
    const queryData = await getPrintableReportsQuery({ variables: queryVariables() });
    const hasValidData = (data: GetPrintableReports | undefined) =>
      data && data.episodereports && data.episodereports.reports;

    if (hasValidData(queryData.data) && !printCombined) {
      const url = `${import.meta.env.VITE_ACL_REPORT_URL}/report`;
      const dreports = get(queryData.data, "episodereports.reports") || ([] as dReport[]);
      const dpatient: dPatient = get(queryData.data, "patient") || ({} as dPatient);

      const downRepData: any = {};
      downRepData.patient = dpatient;
      downRepData.reports = dreports.filter(
        (r: { reportid: any }) => reportPrintArray().find((p) => p === (r.reportid || 0).toString()) !== undefined,
      );
      downRepData.tz = dayjs.tz.guess();

      try {
        const response = await axios.post<any>(
          url,
          {
            output: "pdf",
            data: downRepData,
            template: isTenantVet() ? "gribbles-template.html" : "report-template.html",
          },
          { responseType: "blob" },
        );
        await downloadHelper(response.data, `reports-${episode.labnoDigitOnly}.pdf`);
        setIsLoading(false);
        setReportsToPrint({});
        setAnchorEl(null);
        setError("");
        pushMarkView();
      } catch (e) {
        setIsLoading(false);
        setReportsToPrint({});
        setError(e.message ? e.message : e);
        setAnchorEl(null);
      }
    }
  };

  if (error) {
    const errorMessage = Constants.NO_DB_CONNECTION;

    setError(errorMessage);
  }

  if (cterror) {
    const errorMessage = Constants.NO_DB_CONNECTION;

    setError(errorMessage);
  }

  const downloadReports = async () => {
    setIsLoading(true);
    if (printCombined) {
      await ctCombinedReportsHandler(Constants.AuditAction.DOWNLOAD);
    } else {
      await logDownloaded();
      await downloadReportsHandler();
    }
  };

  const onQueueDownloads = (queueDownloads: MutationTuple<QueueDownloads, QueueDownloadsVariables>[0]) => async () => {
    try {
      setIsLoading(true);

      const reportIds: number[] = [];

      Object.keys(reportsToPrint).forEach((report: any) => {
        reportIds.push(parseInt(report));
      });

      const result = await queueDownloads({
        variables: {
          input: {
            reportIds,
          },
        },
      });

      if (get(result, "data.queueDownloads") === "Queued report(s)") {
        setIsLoading(false);
        setReportsToPrint({});
        setSuccess(
          "Report(s) queued for download. <br> Please note that only this report will be sent. Any future updates will not be received.",
        );
        return <SuccessPopover />;
      }
    } catch (e) {
      setIsLoading(false);

      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const [getAddOnTests, { data: addOnTestData, loading: addOnTestLoading, error: addOnTestError }] =
    useLazyQuery<GetAddOnTests>(GetAddOnTestsQuery, {
      variables: {
        state: "",
        hours: 0,
      },
    });

  React.useEffect(() => {
    if (addOnTestData && addOnTestData.getAddOnTests.length > 0) {
      const testNames = addOnTestData.getAddOnTests.map((test) => test.testName);

      setAddOnTests(testNames);
      setAddOnTestRules(addOnTestData.getAddOnTests);
    } else {
      setAddOnTests([]);
      setAddOnTestRules([]);
    }
  }, [addOnTestData]);

  if (addOnTestError) {
    console.error("Error", addOnTestError);
  }

  const sendSuburb = reports.filter((o) => o.lab?.suburb);
  const ignoreProviders = getSiteOption(ADDON_IGNORE) as string[];
  const isIgnore = ignoreProviders.includes(reports[0].referredProviderNo as string);

  const {
    data: suburbStateData,
    loading: suburbStateLoading,
    error: suburbStateError,
  } = useQuery<GetStateFromSuburb>(GetStateFromSuburbQuery, {
    variables: {
      suburb: sendSuburb.length > 0 ? sendSuburb[0].lab?.suburb : "",
    },
    skip: sendSuburb.length === 0,
  });

  React.useEffect(() => {
    if (!isIgnore && suburbStateData && !suburbStateLoading) {
      let suburbState = authService.getState();

      if (suburbStateData.getStateFromSuburb && suburbStateData.getStateFromSuburb.state) {
        suburbState = suburbStateData.getStateFromSuburb.state;
      }

      if (suburbState.trim() !== "" && suburbState.trim() != null) {
        const currentTime = dayjs(dayjs().tz(ausTimeZones(suburbState))).valueOf();
        const dateCollected = new Date(String(reports[0].dateCollected)).toISOString().slice(0, 19).replace("T", " ");
        const sampleAge = getDiffInHours(dateCollected, currentTime);
        // sampleAge = 1;

        setAgeInHours(sampleAge);
        setCurrentClock(currentTime);
        setSendState(suburbState);

        getAddOnTests({
          variables: {
            state: suburbState,
            hours: sampleAge,
          },
        });
      }
    }
  }, [suburbStateData, suburbStateLoading, getAddOnTests, reports, isIgnore]);

  if (suburbStateError) {
    console.error("Error", suburbStateError);
  }

  const handleClickAddOnTest = () => {
    setOpenAddOnTestDialog(true);
  };

  const handleAddOnTestClose = () => {
    setOpenAddOnTestDialog(false);
  };

  const handleClickEorder = () => {
    setOpenEorderDialog(true);
  };

  const handleEorderDialogClose = () => {
    setOpenEorderDialog(false);
  };

  const handleClickEorderLink = () => {
    setOpenAddOnTestDialog(false);
    setOpenEorderDialog(true);
  };

  return (
    <Box alignSelf="center">
      {!mobility &&
        isDesktop &&
        eOrderFeature &&
        showGenEorderButton &&
        isValidProvider &&
        deliveryMode === Constants.DELIVERY_MODE_EORDER && (
          <>
            {eOrderUseIframe ? (
              <NewEorderMenuButton
                onClick={handleClickEorder}
                eorderFormReady={ready}
                smscEnabled={smscEnabled}
                addOnTestEnabled={addOnTests && addOnTests.length > 0}
              />
            ) : (
              <EorderMenuIcon
                onClick={handleClickEorder}
                eorderFormReady={ready}
                smscEnabled={smscEnabled}
                addOnTestEnabled={addOnTests && addOnTests.length > 0}
              />
            )}

            <EorderDialog
              open={openEorderDialog}
              fullscreen={false}
              onClose={handleEorderDialogClose}
              patient={patient}
              reportingProviderNo={reportingProviderNo as string}
              setReady={setReady}
            />
          </>
        )}

      {!addOnTestLoading &&
        !addOnTestError &&
        addOnTests &&
        addOnTests.length > 0 &&
        isAddon &&
        deliveryMode === Constants.DELIVERY_MODE_ADDON && (
          <>
            <PlusMenuIcon
              largeIcon={isDesktop ?? true}
              onClick={handleClickAddOnTest}
              smscEnabled={smscEnabled}
              class={className ? className : !isDesktop ? styles.printcomponentwhite : styles.printcomponentpurple}
            />
            <AddOnTestDialog
              open={openAddOnTestDialog}
              fullscreen={fullscreen}
              sampleAge={reports[0].dateCollected}
              ageInHours={ageInHours}
              patient={patientInfo as GetPatient_patient}
              reports={reports}
              testNames={addOnTests}
              currentClock={currentClock}
              sendState={sendState}
              testRules={addOnTestRules}
              onClose={handleAddOnTestClose}
              onClick={handleClickEorderLink}
              linkToForm={eOrderFeature && showGenEorderButton}
            />
          </>
        )}

      {!isDesktop && openEorderDialog && (
        <EorderDialog
          open={openEorderDialog}
          fullscreen={false}
          onClose={handleEorderDialogClose}
          patient={patientInfo as GetPatient_patient}
          reportingProviderNo={reportingProviderNo as string}
          setReady={setReady}
        />
      )}

      {deliveryMode === Constants.DELIVERY_MODE_SEND && !isAddon && smscEnabled && (
        <SendMenuIcon onClick={onPrintClick} class={className ? className : styles.printcomponent} />
      )}

      {deliveryMode === Constants.DELIVERY_MODE_DOWNLOAD && (
        <DownloadMenuIcon
          onClick={onPrintClick}
          class={className ? className : !isDesktop ? styles.printcomponentwhite : styles.printcomponentpurple}
        />
      )}

      {deliveryMode === Constants.DELIVERY_MODE_PRINT && !mobility && (
        <PrintMenuIcon
          onClick={onPrintClick}
          class={className ? className : !isDesktop ? styles.printcomponentwhite : styles.printcomponentpurple}
        />
      )}

      {deliveryMode === Constants.DELIVERY_MODE_SHARE && !allPatients && (
        <ShareMenuIcon
          onClick={onPrintClick}
          class={className ? className : !isDesktop ? styles.printcomponentwhite : styles.printcomponentpurple}
        />
      )}

      <PrintPopover anchorEl={anchorEl} handleClose={handleClose}>
        <Box m={2}>
          <Typography variant="h4" color="primary" className={styles.menuTitle}>
            {deliveryMode === Constants.DELIVERY_MODE_SEND ? "Send to SMSC" : deliveryMode}
          </Typography>
          <Box display="flex" flexDirection="column">
            <PrintCheckBox
              name="Full report (All panels)"
              value={allReportsSelected()}
              onChange={() => onSelectAllChange()}
              disabled={hasFinalReports}
            />

            <Divider className={styles.divider} />

            {reports.map((report: Report) => (
              <PrintCheckBox
                key={`${report.testname}`}
                name={`${report.testname}`}
                value={!!reportsToPrint[`${report.reportid}`]}
                onChange={() => onSelectReportChange(`${report.reportid}`)}
                disabled={
                  allowPending
                    ? report.interimStatus !== INTERUM_STATUS.Final &&
                      report.interimStatus !== INTERUM_STATUS.Preliminary &&
                      report.interimStatus !== INTERUM_STATUS.FinalCorrected &&
                      report.interimStatus !== INTERUM_STATUS.Pending
                    : report.interimStatus !== INTERUM_STATUS.Final &&
                      report.interimStatus !== INTERUM_STATUS.Preliminary &&
                      report.interimStatus !== INTERUM_STATUS.FinalCorrected
                }
              />
            ))}

            <Box
              mt={2}
              display="flex"
              justifyContent={deliveryMode !== Constants.DELIVERY_MODE_PRINT ? "flex-end" : "space-between"}
            >
              {deliveryMode === Constants.DELIVERY_MODE_PRINT && !printCombined ? (
                <Button
                  variant="contained"
                  disabled={isLoading || reportPrintArray().length === 0 || !reportPrintHasCumulative()}
                  color="primary"
                  onClick={printCombineReports}
                  //For now, only Print Combined will be use until we move to report service
                  // endIcon={!downloadMode ? <PrintIcon /> : <GetAppIcon />}
                >
                  {isLoading ? (
                    <Box width={"100%"} textAlign="center">
                      <CircularProgress size={16} />
                    </Box>
                  ) : (
                    deliveryMode + " Combined"
                  )}
                </Button>
              ) : (
                ""
              )}

              {deliveryMode === Constants.DELIVERY_MODE_SHARE ? (
                <Button
                  variant="contained"
                  disabled={isLoading || reportPrintArray().length === 0}
                  color="primary"
                  onClick={onClickAction ? () => onClickAction() : () => true}
                >
                  {isLoading ? (
                    <Box width={"100%"} textAlign="center">
                      <CircularProgress size={16} />
                    </Box>
                  ) : (
                    deliveryMode
                  )}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  disabled={isLoading || reportPrintArray().length === 0}
                  color="primary"
                  onClick={
                    deliveryMode !== Constants.DELIVERY_MODE_PRINT
                      ? deliveryMode === Constants.DELIVERY_MODE_SEND
                        ? onQueueDownloads(queueDownloads)
                        : downloadReports
                      : printReports
                  }
                  // endIcon={!downloadMode ? <PrintIcon /> : <GetAppIcon />}
                >
                  {isLoading ? (
                    <Box width={"100%"} textAlign="center">
                      <CircularProgress size={16} />
                    </Box>
                  ) : (
                    deliveryMode
                  )}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </PrintPopover>
      {deliveryMode === Constants.DELIVERY_MODE_PRINT && (
        <>
          <PrintFrame />
        </>
      )}

      {/* <SuccessPopover /> */}
    </Box>
  );
};

export default PrintMenu;
