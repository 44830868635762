import React, { useState, useContext, useMemo, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { withRouter, RouteComponentProps, useLocation } from "react-router";
import { Grid, Box, Typography, Button, Chip } from "@mui/material";
import styles from "./PatientListTable.module.css";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import authService from "../../services/authService";
import GetEpisodesQuery from "./GetEpisodesQuery";
import { GetEpisodes, GetEpisodes_episodesResult } from "./types/GetEpisodes";
import HtmlTooltip from "../common/HtmlTooltip/HtmlTooltip";
import { formatDate, dateFromNow } from "../../utils/date";
import Loading from "../Loading/Loading";
import queryString from "query-string";
import AdvancedSearch from "../../model/advanced-search";
import PatientDetailsTooltip from "../PatientDetailsTooltip/PatientDetailsTooltip";
import AppContext from "../../context/AppContext";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PullDownContent, PullToRefresh, RefreshContent, ReleaseContent } from "react-js-pull-to-refresh";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PreviewDialog from "../Preview/Preview";
import { INTERUM_STATUS } from "../../constants";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { isTenantVet } from "../../services/tenantService";
import theme from "../theme";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

type PatientListTableProps = {
  episodes: GetEpisodes_episodesResult["episodes"];
  allPatients: boolean;
  isSpecificLabnumberSearch: boolean;
  variables: any;
};

export const PatientListTable: React.FC<PatientListTableProps> = (props) => {
  const { episodes } = props;

  const isDesktop = useContext(AppContext).isDesktop;
  const previewMode = useContext(AppContext).previewMode;
  const openNewTab = authService.getOpenNewTab();
  const isMobility = authService.isMobility();
  const [patientClick, setPatientClick] = React.useState("");
  const [panelClick, setPanelClick] = React.useState("");
  const history = useHistory();

  const formatDateCreated = (dateCreated: string | null): string => {
    return dayjs(dateCreated || "") >= dayjs().startOf("day").utc()
      ? dateFromNow(dateCreated || "-", true)
      : dayjs(dateCreated || "", "YYYY-MM-DD HH:mm:ssZ") >= dayjs().subtract(1, "days").startOf("day").utc() &&
          dayjs(dateCreated || "", "YYYY-MM-DD HH:mm:ssZ") < dayjs().startOf("day").utc()
        ? "Yesterday"
        : dayjs(dateCreated || "").year() !== dayjs().utc().year()
          ? formatDate(dateCreated || "", "DD MMM  YYYY", false)
          : formatDate(dateCreated || "", "DD MMM", false);
  };

  const handlePatientClick = (patientUrl: string) => {
    setPatientClick(patientUrl);
  };

  const handlePanelClick = (panelUrl: string) => {
    setPanelClick(panelUrl);
  };

  useEffect(() => {
    const adSearch: AdvancedSearch = props.variables.adSearch;
    if (patientClick !== "" && panelClick === "") {
      if (isDesktop && !isMobility && openNewTab) {
        window.open(`${patientClick}`, "_blank");
      } else {
        history.push(`${patientClick}`, { search: "?" + adSearch.toQueryString() });
      }
      setPatientClick("");
      setPanelClick("");
    } else if (panelClick !== "") {
      if (isDesktop && !isMobility && openNewTab) {
        window.open(`${panelClick}`, "_blank");
      } else {
        history.push(`${panelClick}`, { search: "?" + adSearch.toQueryString() });
      }
      setPanelClick("");
      setPatientClick("");
    }
  }, [patientClick, panelClick]);

  const patientComponents = useMemo(() => {
    if (episodes) {
      return episodes.map((episode: any, index: any) => (
        <Box
          key={index}
          mx={isDesktop ? 3 : 0}
          my={0.2}
          px={2}
          py={1}
          className={`patient ${!episode.isRead ? styles.isUnread : ""} ${styles.tableRow}`}
        >
          <Grid
            container
            alignItems="flex-start"
            key={index}
            onClick={() => {
              handlePatientClick(
                `/patient/${episode.patientid}/${props.allPatients ? "1" : "0"}/labnumber/${episode.labnoDigitOnly}`,
              );
            }}
          >
            <Grid item xs={"auto"} sm={"auto"} md={"auto"}>
              <Box mr={2}>
                {episode.isRead ? <DraftsOutlinedIcon color="disabled" /> : <MailOutlinedIcon />}
                {episode.urgentStatus && (
                  <PriorityHighIcon htmlColor="#ffffff" fontSize="small" className={styles.urgentEpisode} />
                )}
              </Box>
            </Grid>
            <Grid container item xs sm md>
              <Grid item xs sm md lg={2}>
                <HtmlTooltip
                  classes={{ tooltip: styles.patientDetailTooltip }}
                  title={
                    <PatientDetailsTooltip
                      patientId={episode.patientid}
                      allPatients={props.allPatients}
                    ></PatientDetailsTooltip>
                  }
                >
                  {episode.hasAbnormalStatus ? (
                    <Typography className={`${!episode.isRead ? "bold" : ""}`} color="error">
                      {episode.fullnameTitleCase}
                    </Typography>
                  ) : (
                    <Typography className={`${!episode.isRead ? "bold" : ""}`}>{episode.fullnameTitleCase}</Typography>
                  )}
                </HtmlTooltip>
              </Grid>
              <Grid item sx={{ display: { xs: "block", lg: "none" } }} xs="auto">
                <Typography variant="body2" align="right">
                  {formatDateCreated(episode.dateCreated)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={1}>
                <Typography variant="body2" color="grey.100">
                  {formatDate(episode.dob, "DD MMM YYYY", true)}
                </Typography>
              </Grid>
              <Grid item xs={12} lg>
                <Typography component={"span"} color="grey.100">
                  {isDesktop && episode.panels.length > 0 && !authService.getHidePanel() ? (
                    (episode.panels as any[]).map((panel: any, index: number) => {
                      return (
                        <HtmlTooltip
                          classes={{ tooltip: styles.reportDetailTooltip }}
                          enterDelay={1000}
                          enterNextDelay={1000}
                          key={index}
                          title={
                            previewMode ? (
                              <PreviewDialog
                                ids={[panel.reportid]}
                                previewData={{
                                  patientid: episode.patientid.toString(),
                                  labNumber: episode.labnoDigitOnly || "",
                                  allpatient: props.allPatients ? "1" : "0",
                                }}
                                variables={props.variables}
                                panel={panel}
                              ></PreviewDialog>
                            ) : (
                              ""
                            )
                          }
                          disableInteractive={false}
                        >
                          <Chip
                            label={
                              <Typography
                                component={"span"}
                                variant="caption"
                                color={panel.hasAbnormalStatus && panel.isRead ? "error" : "inherit"}
                              >
                                {panel.testname}{" "}
                                {panel.urgentStatus && (
                                  <PriorityHighIcon
                                    htmlColor={panel.isRead ? theme.colorSchemes.light.palette.error.main : "#ffffff"}
                                    fontSize="small"
                                    className={styles.urgentPanel}
                                  />
                                )}
                              </Typography>
                            }
                            //variant={panel!.isRead ? "outlined" : "default"}
                            //color="primary"
                            size="small"
                            className={
                              panel.interimStatus !== INTERUM_STATUS.Final &&
                              panel.interimStatus !== INTERUM_STATUS.FinalCorrected &&
                              panel.interimStatus !== INTERUM_STATUS.Preliminary
                                ? styles.chipPending
                                : panel.isRead
                                  ? styles.chipRead
                                  : panel.hasAbnormalStatus
                                    ? styles.chipUnreadAbnormal
                                    : styles.chipUnread
                            }
                            style={panel.urgentStatus ? { paddingRight: "5px" } : {}}
                            onClick={() => {
                              let uri = `/patient/${episode.patientid}/${props.allPatients ? "1" : "0"}/labnumber/${
                                episode.labnoDigitOnly
                              }`;
                              if (!episode.labnoDigitOnly?.includes("/")) {
                                uri += `/${panel.labnumber}`;
                              }
                              handlePanelClick(uri);
                            }}
                          />
                        </HtmlTooltip>
                      );
                    })
                  ) : isDesktop && episode.panels.length > 0 && authService.getHidePanel() ? (
                    (episode.panels as any[]).map((panel: any, index: number) => {
                      return (
                        <Chip
                          key={index}
                          label={
                            <Typography
                              variant="caption"
                              color={panel.hasAbnormalStatus && panel.isRead ? "error" : "inherit"}
                            >
                              {panel.interimStatus !== INTERUM_STATUS.Final &&
                              panel.interimStatus !== INTERUM_STATUS.FinalCorrected &&
                              panel.interimStatus !== INTERUM_STATUS.Preliminary
                                ? "Pending"
                                : panel.hasAbnormalStatus
                                  ? "Abnormal"
                                  : "Normal"}
                            </Typography>
                          }
                          size="small"
                          className={
                            panel.interimStatus !== INTERUM_STATUS.Final &&
                            panel.interimStatus !== INTERUM_STATUS.FinalCorrected &&
                            panel.interimStatus !== INTERUM_STATUS.Preliminary
                              ? styles.chipPending
                              : panel.isRead
                                ? styles.chipRead
                                : panel.hasAbnormalStatus
                                  ? styles.chipUnreadAbnormal
                                  : styles.chipUnread
                          }
                          style={panel.urgentStatus ? { paddingRight: "5px" } : {}}
                          onClick={() => {
                            handlePanelClick(
                              panel.labnumber.includes("/")
                                ? `/patient/${episode.patientid}/${props.allPatients ? "1" : "0"}/labnumber/${
                                    panel.labnumber
                                  }`
                                : `/patient/${episode.patientid}/${props.allPatients ? "1" : "0"}/labnumber/${
                                    episode.labnoDigitOnly
                                  }/${panel.labnumber}`,
                            );
                          }}
                        />
                      );
                    })
                  ) : (
                    <div
                      className={styles.lineClamp}
                      style={{
                        maxWidth: isDesktop ? window.innerWidth * 0.4 : window.innerWidth * 0.7,
                      }}
                    >
                      {!authService.getHidePanel() ? episode.allEpisodeTests : ""}
                    </div>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={1}>
                <div className={styles.lineClamp}>
                  {episode.referrer && <Typography>{episode.referrer}</Typography>}
                </div>
                &nbsp;
              </Grid>
              <Grid item sx={{ display: { xs: "none", lg: "block" } }} sm={2} md={2} lg={1}>
                <Typography variant="body2" align="right" className={`${!episode.isRead ? "bold" : ""}`}>
                  {formatDateCreated(episode.dateCreated)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ));
    }
  }, [
    episodes,
    isDesktop,
    openNewTab,
    props.allPatients,
    props.isSpecificLabnumberSearch,
    props.variables,
    previewMode,
    isMobility,
  ]);

  return (
    <Box my={2} id="patient-list-table">
      {patientComponents}
    </Box>
  );
};

type PatientListTableContainerProps = {
  history: RouteComponentProps["history"];
  setShowLoadMore: (showLoadMore: boolean) => void;
  showLoadMore: boolean;
  open: boolean;
} & RouteComponentProps;

const PatientListTableContainer: React.FC<PatientListTableContainerProps> = (props) => {
  let providers: string[] = [];

  if (document.readyState === "complete") {
    document.body.style.overflowY = "auto";
  }

  if (authService.getProviderSelected() !== "0") providers.push(authService.getProviderSelected());
  else providers = authService.getProviders();

  const location = useLocation();
  const advancedSearch: AdvancedSearch = AdvancedSearch.fromQueryString(queryString.parse(location.search));
  if (authService.getDefaultWardCode() !== "" && !advancedSearch.ward) {
    advancedSearch.ward = authService.getDefaultWardCode();
  }

  if (advancedSearch.allpatients) {
    advancedSearch.ward = undefined;
  }

  const { setError, isDesktop } = useContext(AppContext);
  const [loadingmore, setloadingmore] = useState(false);

  const { autoRefreshResult, refreshResult, setRefreshResult } = useContext(AppContext);

  const increment = 50;

  const isSpecificLabnumberSearch =
    advancedSearch.labnumber && advancedSearch.labnumber.length > 0
      ? true
      : advancedSearch.panel && advancedSearch.panel.length > 0
        ? true
        : advancedSearch.paneldept && advancedSearch.paneldept > 0
          ? true
          : advancedSearch.referredEndDate && advancedSearch.referredEndDate.length > 0
            ? true
            : false;

  const enableSearch = () => {
    const searchNow = sessionStorage.getItem("searchNow") === "true";
    if (authService.getAutoSearch()) {
      return true;
    } else {
      if (searchNow) {
        return true;
      }
    }
    return false;
  };
  const {
    data,
    loading,
    error: errorFetch,
    fetchMore,
    refetch,
  } = useQuery<GetEpisodes>(GetEpisodesQuery, {
    variables: {
      providers: providers,
      limit: increment,
      adSearch: advancedSearch,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    // see the details about this problem here:
    // https://github.com/apollographql/apollo-client/issues/6327
    nextFetchPolicy: "cache-first",
    skip: !enableSearch(),
  });

  useEffect(() => {
    if (refreshResult) {
      (async () => {
        const result = await refetch();

        if (result.data.episodesResult.episodes.length > 0) {
          setRefreshResult(false);
        }
      })();
    }

    // refetch latest data every 15 minutes
    if (autoRefreshResult && !authService.isMobility()) {
      const interval = setInterval(() => {
        (async () => {
          const result = await refetch();

          if (result.data.episodesResult.episodes.length > 0) {
            setRefreshResult(false);
          }
        })();
      }, 900000);

      return () => clearInterval(interval);
    }
  }, [refetch, refreshResult, setRefreshResult, autoRefreshResult]);

  //let isSearchPage = false;
  let allP = false;
  let isAdvanceSarchPage = false;

  Object.keys(advancedSearch).forEach((key) => {
    if (typeof (advancedSearch as any)[key] !== "undefined") {
      isAdvanceSarchPage = true;
    }
  });

  if (isAdvanceSarchPage) {
    allP = advancedSearch.allpatients ? true : false;
  }

  // if (!loadingmore) {
  //   return <Loading />;
  // }

  let lastReportId = data && data.episodesResult.lastReport ? data.episodesResult.lastReport.reportid : null;

  const episodes = data && data.episodesResult && data.episodesResult.episodes ? data.episodesResult.episodes : [];

  if (!loading) {
    if (!lastReportId || (data && data.episodesResult.episodes.length === 0)) {
      props.setShowLoadMore(false);
    } else {
      props.setShowLoadMore(true);
    }

    if (episodes.length === 0 && advancedSearch.fullname && !props.open) {
      return (
        <Box ml={isDesktop ? 13 : 1} className={styles.nodata}>
          <Typography variant="h4">
            No patients found for <strong>{advancedSearch.fullname}</strong>
          </Typography>
          <Typography>
            Tip: Try searching by firstname and surname using a comma, e.g. Smith, Robert Jones or using the advanced
            search
          </Typography>
        </Box>
      );
    }

    if (!errorFetch && (!episodes || episodes.length === 0)) {
      return (
        <Box ml={isDesktop ? 13 : 1} className={styles.nodata}>
          <Typography variant="h4">
            {!authService.getAutoSearch() && !(sessionStorage.getItem("searchNow") === "true")
              ? "Please search for a patient."
              : "No results found matching your search criteria"}
          </Typography>
        </Box>
      );
    }
  }

  const loadMoreData = async () => {
    setloadingmore(true);
    setError("");
    try {
      await fetchMore({
        variables: {
          providers: providers,
          lastReportId: lastReportId,
          adSearch: advancedSearch,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult === undefined) {
            props.setShowLoadMore(true);
            return prev;
          }

          lastReportId = fetchMoreResult.episodesResult.lastReport
            ? fetchMoreResult.episodesResult.lastReport.reportid
            : lastReportId;
          setloadingmore(false);
          if (
            !lastReportId ||
            !fetchMoreResult.episodesResult ||
            !fetchMoreResult.episodesResult.episodes ||
            fetchMoreResult.episodesResult.episodes.length === 0
          ) {
            props.setShowLoadMore(false);
            return prev;
          }

          const merged = Object.assign({}, prev, {
            episodesResult: {
              __typename: "EpisodesResult",
              lastReport: fetchMoreResult.episodesResult.lastReport,
              episodes: [...prev.episodesResult.episodes, ...fetchMoreResult.episodesResult.episodes],
            },
          });
          return merged;
        },
      });
    } catch {
      // This error occurs when you click on an episode while `fetchmore` is
      // still running. This try catch just supresses the error
      //console.log('fetchMore caught exception')
      //console.log(e)
    }
  };

  return (
    <>
      {errorFetch && <ErrorPopover />}
      {authService.isMobility() ? (
        <PullToRefresh
          pullDownContent={<PullDownContent label="Pull down to refresh patient list..." />}
          releaseContent={<ReleaseContent label="Release to refresh patient list..." />}
          refreshContent={<RefreshContent />}
          pullDownThreshold={200}
          onRefresh={() => {
            refetch();
            return Promise.resolve();
          }}
          triggerHeight={"auto"}
          backgroundColor="white"
          startInvisible={true}
        >
          <div style={{ overflow: "scroll" }}>
            <div style={{ textAlign: "center" }}>
              <ArrowDropDownIcon />
            </div>
            <PatientListTable
              episodes={episodes}
              allPatients={allP}
              isSpecificLabnumberSearch={isSpecificLabnumberSearch}
              variables={{
                providers: providers,
                limit: increment,
                adSearch: advancedSearch,
              }}
            />
          </div>
        </PullToRefresh>
      ) : (
        <PatientListTable
          episodes={episodes}
          allPatients={allP}
          isSpecificLabnumberSearch={isSpecificLabnumberSearch}
          variables={{
            providers: providers,
            limit: increment,
            adSearch: advancedSearch,
          }}
        />
      )}

      {(loading || loadingmore) && <Loading />}
      {!loading && props.showLoadMore && (
        <Box width={1} display="flex" justifyContent="center" my={3}>
          <Button
            variant="outlined"
            color="primary"
            className={`${styles.loadMoreButton} ${
              isTenantVet() ? styles.loadMoreButtonVet : styles.loadMoreButtonNormal
            }`}
            onClick={loadMoreData}
            endIcon={<ExpandMoreIcon />}
          >
            Load more results
          </Button>
        </Box>
      )}
      {/* <PreviewDialog open={dialogOpen} fullscreen={false} previewData={previewData} setOpen={setDialogOpen} /> */}
    </>
  );
};

export default withRouter(PatientListTableContainer);
