import React, { useState } from "react";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";
import DialogBoxShareEmail from "./DialogBoxShareEmail";
import DialogResultsUserShare from "./DialogBoxShareResultsUser";
import DialogBoxShareSMSC from "./DialogBoxShareSMSC";
import { useDialogStyles } from "./styles/dialogStyles";
import { getSiteOption } from "../../utils/siteOptions";
import { SHARE_TO_SMSC } from "../../utils/siteOptions";
interface DialogBoxShareProps {
  open: boolean;
  onClose: () => void;
  onSetOpenShare: (st: boolean) => void;
  reports: Record<string, boolean>;
  patient?: number;
  labno?: string;
}

const useStyles = useDialogStyles;

const DialogBoxShare: React.FC<DialogBoxShareProps> = (props) => {
  const classes = useStyles();
  const smscEnabled: boolean = (getSiteOption(SHARE_TO_SMSC) as string) === "ON";

  const [value, setValue] = React.useState("email");
  const [openShareEmail, setOpenShareEmail] = useState(false);
  const [openShareUser, setOpenShareUser] = useState(false);
  const [openShareSMSC, setOpenShareSMSC] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleOpenShareType = (selectedVal: string) => {
    props.onSetOpenShare(false);
    if (selectedVal == "email") {
      setOpenShareEmail(true);
    } else if (selectedVal == "eresults") {
      setOpenShareUser(true);
    }
    if (selectedVal == "smsc") {
      setOpenShareSMSC(true);
    }
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        maxWidth={"md"}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogBox }}
      >
        <DialogTitle>
          <DialogBoxTitle title="Share To..." width={200} closeButton handleClose={props.onClose} />
        </DialogTitle>

        <DialogContent>
          <Container className={classes.shareContainer}>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel labelPlacement="end" value={"email"} control={<Radio />} label="Email" />
              <FormControlLabel
                labelPlacement="end"
                value={"eresults"}
                control={<Radio />}
                label="Another eResults User"
              />
              {smscEnabled && (
                <FormControlLabel
                  labelPlacement="end"
                  value={"smsc"}
                  control={<Radio />}
                  label="Your practice management software (via SMSC)"
                />
              )}
            </RadioGroup>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleOpenShareType(value)} variant="contained" color="primary">
            Share
          </Button>
        </DialogActions>
      </Dialog>
      <DialogBoxShareEmail
        open={openShareEmail}
        onClose={() => setOpenShareEmail(false)}
        onBack={() => {
          setOpenShareEmail(false);
          props.onSetOpenShare(true);
        }}
        reportsToSende={{
          selected: props.reports,
          patient: props.patient,
          labno: props.labno,
        }}
      />
      <DialogResultsUserShare
        open={openShareUser}
        setOpenShareEmail={setOpenShareEmail}
        onClose={() => setOpenShareUser(false)}
        onBack={() => {
          setOpenShareUser(false);
          props.onSetOpenShare(true);
        }}
        reports={props.reports}
      />

      <DialogBoxShareSMSC
        open={openShareSMSC}
        onClose={() => setOpenShareSMSC(false)}
        onBack={() => {
          setOpenShareSMSC(false);
          props.onSetOpenShare(true);
        }}
        reports={props.reports}
      />
    </>
  );
};

export default DialogBoxShare;
