import React, { useContext, useEffect, useRef, useState } from "react";
import {
  AppBar,
  Box,
  Tab,
  Link,
  Tabs,
  Theme,
  useMediaQuery,
  useTheme,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CpdStyledHeader from "./CpdStyledHeader";
import MainNavigation from "../MainNavigation/MainNavigation";
import styles from "./CPDprograms.module.css";
import logostyles from "../MainNavigation/MainNavigation.module.css";
import authService from "../../services/authService";
import { useHistory, useParams } from "react-router";
import { useQuery } from "@apollo/client";
import imageEresults from "../../assets/eResults Logo - White.png";
import imageEresultsG from "../../assets/eResults Logo - White-green.svg";
import imageAcl from "../../assets/clinicallabs logo.png";
import imageVet from "../../assets/vet-logo.png";
import { isTenantVet } from "../../services/tenantService";
import AppContext from "../../context/AppContext";
import CPDcytologyTab0 from "./CPDcytologyTab0";
import CPDcytologyTab1 from "./CPDcytologyTab1";
import CPDcytologyTab3 from "./CPDcytologyTab3";
import CPDcytologyTab2 from "./CPDcytologyTab2";
import { getCytologyVerify } from "./types/getCytologyVerify";
import GetCytologyVerifyQuery from "./GetCytologyVerifyQuery";
import Loading from "../Loading/Loading";
import { GetDoctorData, GetDoctorData_getDoctorData } from "../EorderDialog/types/GetDoctorData";
import GetDoctorDataQuery from "../EorderDialog/GetDoctorDataQuery";
import { NO_DB_CONNECTION } from "../../constants";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";
import axios from "axios";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import printJS from "print-js";
import { Waypoint } from "react-waypoint";
import { downloadHelper } from "../../utils/downloadHelper";
import dayjs from "dayjs";

export type CPDcytologyProps = {
  display?: string;
  ml?: number;
  style?: React.CSSProperties;
};

interface PathParams {
  id: string;
  tabpage?: string;
}

interface CytologyTab {
  index: number;
  label: string;
}

function samePageLinkNavigation(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  if (
    event.defaultPrevented ||
    event.button !== 0 || // ignore everything but left-click
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false;
  }
  return true;
}

const CPDcytologyDetailPage: React.FC<CPDcytologyProps> = () => {
  const scroll = useRef(false);
  scroll.current = false;

  const { isDesktop } = useContext(AppContext);
  const theme = useTheme<Theme>();
  const matches: boolean = useMediaQuery(theme.breakpoints.up("sm"));
  const params: PathParams = useParams() as PathParams;
  const mobility = authService.isMobility();
  const offsetHeight = !isDesktop || mobility ? 162 : 138;
  const history = useHistory();
  const setError = useContext(AppContext).setError;
  const [drData, setDrData] = useState<GetDoctorData_getDoctorData>();
  const [isDownloading, setIsDownloading] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isActioned, setIsActioned] = useState(false);
  const [base64String, setBase64String] = useState("");
  const [breakdown, setBreakdown] = useState("");
  const [tab0Loading, setTab0Loading] = useState(false);
  const [tab1Loading, setTab1Loading] = useState(false);
  const [tab2Loading, setTab2Loading] = useState(false);
  const [tab3Loading, setTab3Loading] = useState(false);
  const [topPosition, setTopPosition] = useState("-210px");
  const [tabIndex, setTabIndex] = useState(0); //default to first tab

  const {
    data: verifyData,
    loading: verifyLoading,
    error: verifyError,
  } = useQuery<getCytologyVerify>(GetCytologyVerifyQuery, {
    variables: {
      id: parseFloat(params.id),
      providers: authService.getCpdProviderValues(),
    },
    // fetchPolicy: "no-cache",
  });

  const {
    data: doctorData,
    loading: doctorLoading,
    error: doctorError,
  } = useQuery<GetDoctorData>(GetDoctorDataQuery, {
    variables: {
      provider: localStorage.getItem("CytologyReportProvider"),
    },
    // fetchPolicy: "no-cache",
  });

  const cytologyTabs: CytologyTab[] = [
    {
      index: 0,
      label: "Dashboard Summary",
    },
    {
      index: 1,
      label: "CST Patient Demographics",
    },
    {
      index: 2,
      label: "Results Breakdown",
    },
    {
      index: 3,
      label: "Sample Adequacy",
    },
  ];

  useEffect(() => {
    setError("");
    if (verifyData) {
      if (!verifyData.getCytologyVerify.verify) {
        history.replace("/cpd-cytology");
      }
    }
    if (verifyError) {
      history.replace("/cpd-cytology");
    }

    if (doctorData && doctorData.getDoctorData) {
      setDrData(doctorData.getDoctorData);
    }

    if (isActioned) {
      const url = `${import.meta.env.VITE_ACL_REPORT_URL}/report`;

      const canvas = document.getElementById("dashboardSummary") as HTMLCanvasElement;
      const summary = canvas.toDataURL("image/png");
      const canvas2 = document.getElementById("demographic1") as HTMLCanvasElement;
      const demographic1 = canvas2.toDataURL("image/png");
      const canvas3 = document.getElementById("demographic2") as HTMLCanvasElement;
      const demographic2 = canvas3.toDataURL("image/png");
      const canvas4 = document.getElementById("adequacy") as HTMLCanvasElement;
      const adequacy = canvas4.toDataURL("image/png");

      const fullname = [];
      if (drData?.title) fullname.push(drData.title.toLocaleUpperCase());
      if (drData?.givenName) fullname.push(drData.givenName);
      if (drData?.middleName) fullname.push(drData.middleName);
      if (drData?.surname) fullname.push(drData.surname);

      const downData: any = {
        doctor: {
          surname: drData?.surname || "",
          title: drData?.title || "",
          fullname: fullname.join(" "),
          street: drData?.addressLine1,
          city: drData?.city,
          state: "",
          postcode: drData?.postcode,
        },
        header: dayjs(localStorage.getItem("CytologyReportPeriod")).format("MMYYYY"),
        period: dayjs(localStorage.getItem("CytologyReportPeriod")).format("MMMM YYYY"),
        reports: [
          {
            title: "Dashboard Summary",
            images: [base64String, summary],
          },
          {
            title: "CST Patient Demographics",
            images: [demographic1, demographic2],
            text: "The graphs do not include any patients under 25 years of age although results for these patients are included in the other areas of this report.",
          },
          {
            title: "Results Breakdown",
            images: [breakdown],
          },
          {
            title: "Sample Adequacy",
            images: [adequacy],
            text: "This item represents the adequacy of HPV samples for molecular testing only.",
          },
        ],
      };

      if (breakdown.length > 0) {
        axios
          .post<any>(
            url,
            {
              template: "cytology-report-template.html",
              output: "pdf",
              data: downData,
            },
            { responseType: "blob" },
          )
          .then((response) => {
            if (isPrinting) {
              const reader = new FileReader();
              reader.readAsDataURL(response.data);
              reader.onloadend = () => {
                const base64data = (reader.result as string).split(",")[1];
                printJS({ printable: base64data, type: "pdf", base64: true });
                setIsPrinting(false);
              };
            } else {
              downloadHelper(response.data, `cytology-report-${params.id}.pdf`);
              setIsDownloading(false);
            }

            setIsActioned(false);
          })
          .catch((e) => {
            setError(e.message ? e.message : e);
            setIsActioned(false);
            setIsDownloading(false);
            setIsPrinting(false);
          });
      }
    }

    if (mobility || (!mobility && !isDesktop)) {
      setTopPosition("-230px");
    } else {
      setTopPosition("-210px");
    }
  }, [verifyData, verifyError, doctorData, isActioned, isPrinting, breakdown, isDesktop, mobility]);

  const handleReportDownload = (print = false) => {
    setIsActioned(true);

    if (print) {
      setIsPrinting(true);
    } else {
      setIsDownloading(true);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // event.type can be equal to focus with selectionFollowsFocus.
    if (
      event.type !== "click" ||
      (event.type === "click" && samePageLinkNavigation(event as React.MouseEvent<HTMLAnchorElement, MouseEvent>))
    ) {
      setTabIndex(newValue);
    }
  };

  if (doctorError) {
    setError(NO_DB_CONNECTION);
  }

  const onWaypointEnter = (index: number) => {
    setTabIndex(index);
  };

  return (
    <>
      <AppBar elevation={0} className={`${styles.headerBar} ${styles.hideForPrinting}`}>
        <MainNavigation
          subHeader={`Cytology Evaluation Program ${
            verifyData && verifyData.getCytoReport
              ? " - " +
                dayjs(verifyData.getCytoReport.reportdate as string).format("MMMM YYYY") +
                " (" +
                verifyData.getCytoReport.providerno +
                ")"
              : ""
          }`}
          showBackButton
          mainHeader={CpdStyledHeader()}
          backText="REPORT LIST"
          backPath="/cpd-cytology"
          hideSeparator
          isCpd
          hideProviders
          displayACL={isDesktop ? true : false}
        />
      </AppBar>
      <div className={`${styles.hideForPrinting}`} style={{ height: offsetHeight }} />
      <Box
        visibility="visible"
        className={styles.tabContainer}
        style={{ position: "fixed", width: "100%", zIndex: "100" }}
      >
        <img
          src={isTenantVet() ? imageEresultsG : imageEresults}
          alt="acl logo"
          className={`${styles.displayForPrintingOnly} ${matches ? logostyles.logo_135px : logostyles.logo_100px}`}
        />

        <Tabs
          value={tabIndex}
          onChange={handleChange}
          className={styles.tabPanel}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force nav tabs example"
        >
          {cytologyTabs.map(
            (
              d, //to={`/cpd-diabetes/${d.index}`}
            ) => (
              <Tab
                key={d.index}
                component={Link}
                className={`${!isDesktop ? styles.tabHeaderMobile : styles.tabHeader} ${styles.tabHeaderInactive} ${
                  tabIndex === d.index ? styles.tabHeaderSelected : styles.tabHeaderInactive
                }`}
                label={d.label}
                href={`#${d.index}`}
              />
            ),
          )}
        </Tabs>
        <img
          src={isTenantVet() ? imageVet : imageAcl}
          alt="acl logo"
          className={`${styles.displayForPrintingOnly} ${
            isTenantVet() ? logostyles.logo_156px : logostyles.logo_185px
          }`}
        />
      </Box>
      {(verifyLoading || doctorLoading) && <Loading boxStyle={{ paddingTop: "50px" }} />}
      {verifyData && (
        <Box mt={9}>
          <Box mt={-1} zIndex={200} mr={1} textAlign={"right"} position={"fixed"} width={"100%"}>
            <IconButton
              onClick={() => handleReportDownload(true)}
              disabled={isPrinting}
              className={`${isDesktop ? styles.cancelButton : styles.cancelButtonMobile}`}
            >
              {isPrinting || tab0Loading || tab1Loading || tab2Loading || tab3Loading ? (
                <CircularProgress size={24} thickness={5} />
              ) : (
                <PrintOutlinedIcon titleAccess="Print" color="primary" fontSize={isDesktop ? "large" : "small"} />
              )}
            </IconButton>
            <IconButton
              onClick={() => handleReportDownload()}
              disabled={isDownloading}
              className={`${isDesktop ? styles.cancelButton : styles.cancelButtonMobile}`}
            >
              {isDownloading || tab0Loading || tab1Loading || tab2Loading || tab3Loading ? (
                <CircularProgress size={24} thickness={5} />
              ) : (
                <SaveAltIcon titleAccess="Download" color="primary" fontSize={isDesktop ? "large" : "small"} />
              )}
            </IconButton>
          </Box>
          <Waypoint
            fireOnRapidScroll={false}
            key={0}
            bottomOffset={"45%"}
            onEnter={() => {
              if (!tab0Loading) {
                onWaypointEnter(0);
              }
            }}
          >
            <Box position={"relative"}>
              <Box id={"0"} position={"absolute"} top={topPosition} />
              <CPDcytologyTab0
                chart1="dashboardSummary"
                chart2={setBase64String}
                isLoading={setTab0Loading}
                isActioned={isActioned}
              />
            </Box>
          </Waypoint>
          <Waypoint
            fireOnRapidScroll={false}
            key={1}
            bottomOffset={"45%"}
            onEnter={() => {
              if (!tab1Loading) {
                onWaypointEnter(1);
              }
            }}
          >
            <Box position={"relative"}>
              <Box id={"1"} position={"absolute"} top={topPosition} />
              <CPDcytologyTab1 chart1="demographic1" chart2="demographic2" isLoading={setTab1Loading} />
            </Box>
          </Waypoint>
          <Waypoint
            fireOnRapidScroll={false}
            key={2}
            bottomOffset={"45%"}
            onEnter={() => {
              if (!tab2Loading) {
                onWaypointEnter(2);
              }
            }}
          >
            <Box position={"relative"}>
              <Box id={"2"} position={"absolute"} top={topPosition} />
              <CPDcytologyTab2 chart1={setBreakdown} isLoading={setTab2Loading} isActioned={isActioned} />
            </Box>
          </Waypoint>
          <Waypoint
            fireOnRapidScroll={false}
            key={3}
            bottomOffset={"45%"}
            onEnter={() => {
              if (!tab3Loading) {
                onWaypointEnter(3);
              }
            }}
          >
            <Box position={"relative"}>
              <Box id={"3"} position={"absolute"} top={topPosition} />
              <CPDcytologyTab3 chart1="adequacy" isLoading={setTab3Loading} />
              <Box mb={22} />
            </Box>
          </Waypoint>
        </Box>
      )}
      <ErrorPopover />
    </>
  );
};
export default CPDcytologyDetailPage;
