import React, { useContext, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";
import authService from "../../services/authService";
import { useMutation, useQuery } from "@apollo/client";
import { GetSMSCLink, GetSMSCLink_getSMSCLink_data } from "./types/GetSMSCLink";
import getSMSCLinkQuery from "./getSMSCLinkQuery";
import Loading from "../Loading/Loading";
import { ClearSMSCLink } from "./types/ClearSMSCLink";
import clearSMSCLinkMutation from "./clearSMSCLinkMutation";
import { UpdateSMSCLink, UpdateSMSCLinkVariables } from "./types/UpdateSMSCLink";
import updateSMSCLinkMutation from "./updateSMSCLinkMutation";
import { QueueDownloadClinicIds, QueueDownloadClinicIdsVariables } from "./types/QueueDownloadClinicIds";
import queueDownloadClinicIdsMutation from "./queueDownloadClinicIdsMutation";
import AppContext from "../../context/AppContext";
import { isTenantVet } from "../../services/tenantService";

import FeedbackMutation from "../Feedback/FeedbackMutation";
import { UserFeedback, UserFeedbackVariables } from "../Feedback/types/UserFeedback";
import { useDialogStyles } from "./styles/dialogStyles";

interface DialogBoxShareSMSCProps {
  open: boolean;
  onClose: () => void;
  onBack: () => void;
  reports: Record<string, boolean>;
}

const useStyles = useDialogStyles;

const onlyUnique = (value: any, index: number, array: any[]) => {
  return array.indexOf(value) === index;
};

const DialogBoxShareSMSC: React.FC<DialogBoxShareSMSCProps> = (props) => {
  const classes = useStyles();

  const { setSuccess } = useContext(AppContext);

  const [page, setPage] = React.useState(1);
  const [selectedClinicIds, setSelectedClinicIds] = React.useState<string[]>([]);

  // const [currentDialogOpen, setCurrentDialogOpen] = React.useState<boolean>(false);
  const [smscLinks, setSmscLinks] = React.useState<GetSMSCLink_getSMSCLink_data[]>([]);

  const [clearPreferredSMSC] = useMutation<ClearSMSCLink>(clearSMSCLinkMutation);

  const [updatePreferredSMSC] = useMutation<UpdateSMSCLink, UpdateSMSCLinkVariables>(updateSMSCLinkMutation, {
    variables: {
      input: {
        clinicIds: selectedClinicIds,
      },
    },
  });
  const [sendFeedback] = useMutation<UserFeedback, UserFeedbackVariables>(FeedbackMutation);
  const [queueDownloadClinicIds, { loading: loadingQueue }] = useMutation<
    QueueDownloadClinicIds,
    QueueDownloadClinicIdsVariables
  >(queueDownloadClinicIdsMutation, {
    variables: {
      input: {
        reportIds: Object.keys(props.reports ?? {}).map((key) => parseInt(key)),
        clinicIds: selectedClinicIds,
      },
    },
  });

  const { data: smscLinkData, loading: smscLinkLoading } = useQuery<GetSMSCLink>(getSMSCLinkQuery, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (smscLinkData) {
      if (smscLinkData.getSMSCLink.data.length === 0) {
        setPage(3);
      }
      setSmscLinks(smscLinkData.getSMSCLink.data);
      const smscPreferredData = smscLinkData.getSMSCLink.data.filter((smscLink) => smscLink.preferred);
      const selected = smscPreferredData ? smscPreferredData.map((smsc) => (smsc.clinicid ? smsc.clinicid : "")) : [];

      setSelectedClinicIds(selected);
      if (selected.length > 0) {
        setPage(2);
      }
    }
  }, [smscLinkData]);

  const onClose = () => {
    if (smscLinks.filter((smscLink) => smscLink.preferred).length > 0) {
      setPage(2);
    } else if (smscLinks.length == 0) {
      setPage(3);
    } else {
      setPage(1);
    }
    props.onClose();
  };

  const useOnce = () => {
    if (selectedClinicIds.length > 0) {
      clearPreferredSMSC();
      const tempSmscLinks = smscLinks.map((smscLink: GetSMSCLink_getSMSCLink_data) => {
        return { ...smscLink, preferred: false };
      });
      setSmscLinks(tempSmscLinks);
      setPage(2);
    }
  };

  const useAlways = () => {
    if (selectedClinicIds.length > 0) {
      updatePreferredSMSC({
        variables: {
          input: {
            clinicIds: selectedClinicIds,
          },
        },
      });
      const tempSmscLinks = smscLinks.map((smscLink: GetSMSCLink_getSMSCLink_data) => {
        const preferred = selectedClinicIds.includes(smscLink.clinicid || "");
        return { ...smscLink, preferred: preferred };
      });
      setSmscLinks(tempSmscLinks);
      setPage(2);
    }
  };

  const doQueueDownloads = () => {
    queueDownloadClinicIds({
      variables: {
        input: {
          reportIds: Object.keys(props.reports ?? {}).map((key) => parseInt(key)),
          clinicIds: selectedClinicIds,
        },
      },
    }).then(() => {
      setSuccess("Queued downloads for selected SMSC links");
      if (smscLinks.filter((smscLink) => smscLink.preferred).length > 0) {
        setPage(2);
      } else {
        setPage(1);
      }
      onClose();
    });
  };

  const sendRequestSMSC = async () => {
    const state = authService.getState() ? authService.getState() : "";
    const providers: string[] = authService.getProviders();
    const selectedProvider = providers[0];
    const name: string = authService.getName();

    await sendFeedback({
      variables: {
        input: {
          providerno: selectedProvider,
          phone: "",
          email: "",
          name: name,
          surname: "",
          type: "usersupport-ACL",
          text: "Please enable SMSC Link for my account",
          state: state,
          source: (isTenantVet() ? "Gribbles " : "") + "eResults",
        },
      },
    });
  };
  return (
    <>
      <Dialog
        open={props.open}
        onClose={onClose}
        maxWidth={"md"}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogBox }}
      >
        <DialogTitle>
          <DialogBoxTitle
            title={page === 1 || page === 3 ? "Select your SMSC Link" : "Confirm & Send"}
            width={200}
            closeButton
            handleClose={onClose}
          />
        </DialogTitle>

        <DialogContent>
          {page === 1 && (
            <>
              <Box>
                <Typography>We have the following SMSC link(s) associated with your account:</Typography>
              </Box>
              <hr />
              {smscLinkLoading && <Loading />}
              {smscLinks.map((smscLink) => {
                const clinicId = smscLink.clinicid;
                return (
                  <Box m={2} key={`chk_clinic_id_${clinicId}`}>
                    <FormControlLabel
                      labelPlacement="end"
                      value={clinicId}
                      control={<Checkbox checked={selectedClinicIds.includes(clinicId || "")} />}
                      label={clinicId}
                      onChange={(event: any) => {
                        if (clinicId) {
                          if (event.target.checked) {
                            setSelectedClinicIds([...selectedClinicIds, clinicId].filter(onlyUnique));
                          } else {
                            setSelectedClinicIds(selectedClinicIds.filter((id) => id !== clinicId).filter(onlyUnique));
                          }
                        }
                      }}
                    />
                  </Box>
                );
              })}
              <hr />
            </>
          )}
          {page == 2 && (
            <>
              <Box m={2}>
                <Typography>
                  By clicking ‘Send’ you intend to share the selected results to the following SMSC link:
                </Typography>
              </Box>
              <hr />
              {selectedClinicIds.map((clinicId) => {
                return (
                  <Box m={2} key={`t_clinic_id_${clinicId}`}>
                    <Typography>{clinicId}</Typography>
                  </Box>
                );
              })}
              <hr />
            </>
          )}

          {page == 3 && (
            <>
              <Box mt={2}>
                <Typography>
                  We have no SMSC links associated with your account. <u> Share via email?</u>
                </Typography>
              </Box>
              <Box mt={2} mb={2}>
                <Typography>
                  To enable this feature please click ‘Request SMSC Link’ below and our eHealth Client IT team will
                  contact you shortly.
                </Typography>
              </Box>
            </>
          )}

          {page == 4 && (
            <Typography>
              Request has sent to ehealth team. our eHealth Client IT team will contact you shortly.
            </Typography>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          {page === 1 && (
            <>
              <Button
                onClick={() => {
                  props.onBack();
                }}
                variant="contained"
                color="secondary"
              >
                Back
              </Button>
              <Button onClick={useOnce} variant="contained" color="primary" disabled={selectedClinicIds.length === 0}>
                Use Once
              </Button>

              <Button onClick={useAlways} variant="contained" color="primary" disabled={selectedClinicIds.length === 0}>
                Use Always
              </Button>
            </>
          )}
          {page === 2 && (
            <>
              <Button
                onClick={() => {
                  setPage(1);
                }}
                variant="contained"
                color="secondary"
              >
                Set up SMSC
              </Button>

              <Button onClick={doQueueDownloads} variant="contained" color="primary">
                {loadingQueue ? (
                  <Box width={"100%"} textAlign="center">
                    <CircularProgress size={16} />
                  </Box>
                ) : (
                  "Send to PMS"
                )}
              </Button>
            </>
          )}
          {page === 3 && (
            <>
              <Button
                onClick={() => {
                  props.onBack();
                }}
                variant="contained"
                color="secondary"
              >
                Back
              </Button>

              <Button
                onClick={() => {
                  sendRequestSMSC();
                  setPage(4);
                }}
                variant="contained"
                color="primary"
              >
                Request SMSC Link
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogBoxShareSMSC;
