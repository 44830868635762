import { gql } from "@apollo/client";
export default gql`
  query GetSMSCLink {
    getSMSCLink {
      data {
        id
        userid
        clinicid
        clinicname
        status
        providerno
        preferred
      }
    }
  }
`;
