import React, { Fragment, ChangeEvent, useContext, useState, useEffect } from "react";
import { Link as RouterLink, withRouter, useParams, useLocation } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  AppBar,
  Box,
  Typography,
  Link,
  BottomNavigation,
  BottomNavigationAction,
  ButtonGroup,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Scrollbars from "react-custom-scrollbars-2";
import { titleCase } from "title-case";
import queryString from "query-string";
import authService from "../../services/authService";
import { useQuery } from "@apollo/client";
import { CumulativeContext } from "../../context/CumulativeContext";
import GetCumulativeAtomicValuesQuery from "../CumulativePanel/GetCumulativeAtomicValuesQuery";
import {
  GetCumulativeAtomicValues,
  GetCumulativeAtomicValues_atomic,
  GetCumulativeAtomicValues_atomic_atomicData,
} from "../CumulativePanel/types/GetCumulativeAtomicValues";
import Loading from "../Loading/Loading";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import PanelReports from "../PanelReports/PanelReportsPage";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";
import AppContext from "../../context/AppContext";
import CumulativeTable from "../CumulativeTable/CumulativeTable";
import { useWindowSize } from "react-use";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { isEmpty } from "lodash";
import PivotTableChartIcon from "@mui/icons-material/PivotTableChart";
import GetPatientEpisodes from "../PatientDetailsPage/GetPatientEpisodes";
import { GetPatientEpisodes as GetPatientEpisodesQueryType } from "../PatientDetailsPage/types/GetPatientEpisodes";
import GetPatient from "../PatientDetailsPage/GetPatient";
import { GetPatient as GetPatientQueryType } from "../PatientDetailsPage/types/GetPatient";
import CsvDownloader from "react-csv-downloader";
import { Datas } from "react-csv-downloader/dist/esm/lib/csv";
import thm from "../theme";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";

const useStyles = makeStyles(() => ({
  headerBar: {
    backgroundColor: thm.colorSchemes.light.palette.primary.light + " !important;",
    height: "117px",
  },
  title: {
    fontWeight: "bold",
  },
  reportPanel: {
    borderColor: "#e9e9e9",
    borderStyle: "solid",
    borderWidth: "0px 1px 1px 1px",
    marginBottom: "56px",
    //border: 'solid #e9e9e9',
  },
  item: {
    borderTop: "1px solid #e9e9e9",
  },
  headerColumn: {
    minWidth: "140px",
  },
  downloadcsv: {
    color: thm.colorSchemes.light.palette.primary.main + " !important",
  },
  mostRecentValueColumn: {
    minWidth: "75px",
    borderColor: thm.colorSchemes.light.palette.primary.main,
    borderStyle: "solid",
    borderWidth: "0px 3px 3px",
    // minWidth: '110px',
    //border: '3px solid #753bbd'
  },
  valueColumn: {
    overflow: "auto",
  },
  valueColumnFiller: {
    width: "100%",
  },
  olderValueColumn: {
    minWidth: "110px",
    flexGrow: 1,
  },
  rangeColumnContainer: {
    width: "100%",
    backgroundColor: "#f5f9fd",
  },
  rangeColumn: {
    minWidth: "100px",
    backgroundColor: "#f5f9fd",
    // [theme.breakpoints.down('xs')]: {
    //   display: 'none'
    // },
  },
  rangeColumnFiller: {
    width: "100%",
    backgroundColor: "#f5f9fd",
  },
  mostRecentHeader: {
    backgroundColor: thm.colorSchemes.light.palette.primary.main + " !important",
  },
  backButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: thm.colorSchemes.light.palette.primary.light,
  },
  iconMobile: {
    marginRight: "0.5rem",
  },
  tabNavigation: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    borderTop: "1px solid #e2e2e2",
    "& button": {
      color: thm.colorSchemes.light.palette.primary.main,
    },
  },
  graph: {
    color: thm.colorSchemes.light.palette.primary.dark + " !important",
    height: "25px !important",
    width: "25px !important",
  },
  tabNavigationItemLeft: {
    borderColor: thm.colorSchemes.light.palette.primary.main,
    borderStyle: "solid",
    borderWidth: "1px 0px 1px 1px",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    margin: "10px 0px 10px 10px",
  },
  tabNavigationItem: {
    border: "1px solid " + thm.colorSchemes.light.palette.primary.main,
    margin: "10px 0px 10px 0px",
  },
  tabNavigationItemRight: {
    borderColor: thm.colorSchemes.light.palette.primary.main,
    borderStyle: "solid",
    borderWidth: "1px 1px 1px 0px",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    margin: "10px 10px 10px 0px",
  },
  tabNavigationItemSelected: {
    backgroundColor: thm.colorSchemes.light.palette.primary.main,
    color: "#fff",
  },
  dateHeader: {
    backgroundColor: "#FFEEDE",
    height: 50,
  },
}));

const CumulativeMobilePage: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const { patientId, panelId, labnoDigitOnly, allPatients } = useParams<{
    patientId: string;
    panelId: string;
    labnoDigitOnly: string;
    allPatients: string;
  }>();
  const { height } = useWindowSize();
  const [tabValue, setTabValue] = React.useState("cumulative");
  const location = useLocation();
  const qs = queryString.parse(location.search);
  const [showPanelReports, setshowPanelReports] = React.useState(false);
  const scrollRef = React.createRef<Scrollbars>();
  const [hideBottomPanel, setHideBottomPanel] = React.useState(false);
  const { setShowIndGraph, showIndGraph, setShowCulGraph, showCulGraph, currentReportId } =
    useContext(CumulativeContext);

  const handleTabChange = (_event: ChangeEvent<any>, newValue: string) => {
    setTabValue(newValue);
  };

  const providers: string[] = authService.getProviders();
  const appTestname = useContext(AppContext).testName;
  const mobility = authService.isMobility();

  const testname: string = qs && qs.test ? qs.test.toString() : appTestname;

  const [downloadCSV, setDownloadCSV] = React.useState<Datas>([]);
  const [downloadChart, setDownloadChart] = useState(false);
  const [isChartDownloading, setIsChartDownloading] = useState(false);
  const [openChartView, setOpenChartView] = useState(false);

  const callBackCumulativeCSVData = React.useCallback((downloadCSV) => {
    setDownloadCSV(downloadCSV);
  }, []);

  useEffect(() => {
    if (!isChartDownloading) {
      setDownloadChart(false);
    }
  }, [isChartDownloading]);

  const {
    data,
    loading,
    error: errorFetch,
  } = useQuery<GetCumulativeAtomicValues>(GetCumulativeAtomicValuesQuery, {
    variables: {
      providers: providers,
      patientId: Number(patientId),
      panelId: Number(panelId),
      allPatients:
        allPatients &&
        allPatients === "1" &&
        authService.getAllowAllPatients() &&
        authService.getAllowAllPatientsAcknownledged(),
    },
  });

  const getCookie = (cname: string) => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  const defSortEpisode = getCookie("sortEpisode") === "" ? "dateCollected" : getCookie("sortEpisode");

  const { data: patientEpisodesData } = useQuery<GetPatientEpisodesQueryType>(GetPatientEpisodes, {
    variables: {
      patientId: Number(patientId),
      providers: authService.getProviders(),
      allPatients: allPatients === "0" ? false : true,
      sortBy: defSortEpisode,
      showRemovedPanels: authService.getShowDeletedPanels(),
    },
  });

  const { data: patientData } = useQuery<GetPatientQueryType>(GetPatient, {
    variables: { patientId: Number(patientId) },
  });

  const handleOnArrowLeftClick = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft(scrollRef.current.getScrollLeft() - 125);
    }
  };

  const handleOnArrowRightClick = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft(scrollRef.current.getScrollLeft() + 125);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (errorFetch) {
    return <ErrorPopover />;
  }

  const openPanelReports = (value: boolean | false) => {
    setshowPanelReports(value);
  };

  const openGraphPanel = (value: boolean | false) => {
    setShowCulGraph(value);
    setShowIndGraph(value);
    setOpenChartView(false);
  };

  const handleOpenChartView = () => {
    setOpenChartView(true);
  };

  let oldestValueAtomicDate = "";

  if (data && data.atomicNames && data.atomicNames.length > 0 && data.atomic && data.atomic.length > 0) {
    //let result = data.atomic
    const latestResult: GetCumulativeAtomicValues_atomic | null =
      data.atomic && data.atomic[0] ? data.atomic[0] : ({} as GetCumulativeAtomicValues_atomic);
    // let latestAtomic = (data.atomic[0] && data.atomic[0].atomicData) ? data.atomic[0].atomicData : []

    let atomicLoinCInOrders: string[] = data.atomicNames.map((value) =>
      value ? [value.loinc, value.name].join("|") : "",
    );

    let atomicCommentInOrders: string[] = [];

    const allRangesMap: Map<string, GetCumulativeAtomicValues_atomic_atomicData> = new Map();
    if (data.atomic) {
      data.atomic.forEach((a) => {
        if (a && a.atomicData) {
          a.atomicData.forEach((ad) => {
            if (ad) {
              const k = [ad.loinc, ad.name].join("|");
              const val = { ...allRangesMap.get(k) };
              if (!isEmpty(val)) {
                if (!val.range || !val.measurement) {
                  val.range = ad.range;
                  val.measurement = ad.measurement;
                }
              } else {
                allRangesMap.set(k, ad);
              }
            }
          });
        }
      });
    }
    const latestResultWithCompleteRanges = { ...latestResult };
    if (latestResultWithCompleteRanges) {
      latestResultWithCompleteRanges.atomicData = Array.from(allRangesMap.values());
    }

    if (latestResult.atomicData) {
      const extraCodes = latestResult.atomicData.filter((a: any) => {
        if (a.value_type === "FT") {
          return false;
        } else {
          return a ? !atomicLoinCInOrders.includes([a.loinc, a.name].join("|")) : true;
        }
      });

      if (extraCodes.length > 0) {
        atomicLoinCInOrders = atomicLoinCInOrders.concat(
          extraCodes.map((a: any) => (a ? [a.loinc, a.name].join("|") : "")),
        );
      }

      const extraComments = latestResult.atomicData.filter((a: any) => (a && a.value_type === "FT" ? true : false));
      if (extraComments.length > 0) {
        atomicCommentInOrders = atomicCommentInOrders.concat(
          extraComments.map((a: any) => (a ? [a.loinc, a.name].join("|") : "")),
        );
      }
    }

    const oldestValueAtomic: GetCumulativeAtomicValues_atomic | null =
      data.atomic && data.atomic[data.atomic.length - 1]
        ? data.atomic[data.atomic.length - 1]
        : ({} as GetCumulativeAtomicValues_atomic);
    oldestValueAtomicDate = oldestValueAtomic ? oldestValueAtomic.reportDateCollected : "";

    return (
      <Fragment>
        <AppBar elevation={0} position="sticky">
          <Box p={[2, 3]}>
            <Link
              component={RouterLink}
              to={{
                pathname: `/patient/${patientId}/${allPatients}/labnumber/${labnoDigitOnly}`,
                state: location.state,
              }}
            >
              <Typography variant="body2" className={classes.backButton}>
                <ArrowBackIosIcon fontSize="small" className={classes.iconMobile} />
                Back to episodes
              </Typography>
            </Link>
          </Box>
          <Box
            px={[2, 3]}
            pt={1}
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            height={"100%"}
            className={classes.headerBar}
          >
            <Typography className={classes.title} variant="subtitle2" color="textPrimary">
              CUMULATIVE VIEW
            </Typography>
            <Typography className={classes.title} variant="h4" color="primary">
              {titleCase(testname)}
            </Typography>
            <Box display={"flex"} mt={2} justifyContent="flex-end">
              {!showPanelReports ? (
                <>
                  {!showIndGraph && !showCulGraph && (
                    <ButtonGroup size="small">
                      <Button variant="outlined" color="primary" onClick={handleOnArrowLeftClick}>
                        <KeyboardArrowLeftIcon />
                      </Button>
                      <Button variant="outlined" color="primary" onClick={handleOnArrowRightClick}>
                        <KeyboardArrowRightIcon />
                      </Button>
                    </ButtonGroup>
                  )}
                  {!showIndGraph && !showCulGraph && data.atomic.length > 1 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOpenChartView}
                      style={{ marginLeft: "15px" }}
                      size="small"
                    >
                      Chart View {"  "}
                      <StackedBarChartIcon style={{ margin: "0 -5px 0 5px" }} />
                    </Button>
                  )}
                  {(showIndGraph || showCulGraph) && (
                    <>
                      <Button size="small" variant="contained" color="primary" onClick={() => openGraphPanel(false)}>
                        Table View
                        <PivotTableChartIcon style={{ margin: "0 0 0 5px" }} />
                      </Button>
                      <IconButton
                        className={classes.downloadcsv}
                        title="Download Chart"
                        disabled={isChartDownloading}
                        onClick={() => setDownloadChart(true)}
                      >
                        {isChartDownloading && downloadChart ? (
                          <CircularProgress size={25} />
                        ) : (
                          <SaveAltIcon className={classes.downloadcsv} fontSize="small" />
                        )}
                      </IconButton>
                    </>
                  )}
                </>
              ) : (
                ""
              )}
              {!showPanelReports && !mobility && !showCulGraph && !showIndGraph && (
                <CsvDownloader
                  filename={`cumulative-report-${testname}`}
                  separator=","
                  wrapColumnChar=""
                  datas={downloadCSV}
                >
                  <IconButton>
                    <SaveAltIcon className={classes.downloadcsv} fontSize="small" />
                  </IconButton>
                </CsvDownloader>
              )}
            </Box>
          </Box>
        </AppBar>

        {showPanelReports ? (
          <Box>
            <PanelReports
              panelId={Number(panelId)}
              patientId={Number(patientId)}
              date={oldestValueAtomicDate}
              providers={providers}
              allPatients={allPatients && allPatients === "1" ? true : false}
            />
          </Box>
        ) : (
          <Box height={height - 250 + (hideBottomPanel || openChartView ? 55 : 0)}>
            <Scrollbars ref={scrollRef}>
              <CumulativeTable
                totaldata={data}
                data={data.atomic}
                loincOrders={atomicLoinCInOrders}
                commentOrders={atomicCommentInOrders}
                allPatients={allPatients && allPatients === "1" ? true : false}
                latestResultWithCompleteRanges={latestResultWithCompleteRanges}
                callBackCumulativeCSVData={callBackCumulativeCSVData}
                displayType={tabValue === "range" ? "ranges" : "cumulative"}
                providers={providers}
                panelId={parseInt(panelId)}
                patientId={parseInt(patientId)}
                hideMobilePanel={(value: any) => {
                  setHideBottomPanel(value);
                }}
                reportId={currentReportId}
                patient={patientData?.patient}
                episodeData={patientEpisodesData?.patientepisodes.episodes}
                downloadChart={downloadChart}
                setIsChartDownloading={setIsChartDownloading}
                openChartView={openChartView}
              />
            </Scrollbars>
          </Box>
        )}
        {!showIndGraph && !showCulGraph && !hideBottomPanel && (
          <BottomNavigation value={tabValue} onChange={handleTabChange} showLabels className={classes.tabNavigation}>
            <BottomNavigationAction
              value="range"
              label="Ref. Range"
              classes={{ selected: classes.tabNavigationItemSelected }}
              className={classes.tabNavigationItemLeft}
            />
            <BottomNavigationAction
              value="cumulative"
              label="Cumulative"
              classes={{ selected: classes.tabNavigationItemSelected }}
              className={classes.tabNavigationItem}
              onClick={() => openPanelReports(false)}
            />
            <BottomNavigationAction
              value=""
              label="Older Reports"
              classes={{ selected: classes.tabNavigationItemSelected }}
              className={classes.tabNavigationItemRight}
              onClick={() => openPanelReports(true)}
            />
          </BottomNavigation>
        )}
        {/* https://callstack.github.io/react-native-paper/bottom-navigation.html */}
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <AppBar elevation={0} position="sticky">
          <Box p={[2, 3]}>
            <Link component={RouterLink} to={`/Patient/${patientId}/${allPatients}/labnumber/${labnoDigitOnly}`}>
              <Typography variant="body2" className={classes.backButton}>
                <ArrowBackIosIcon fontSize="small" className={classes.iconMobile} />
                Back to episodes
              </Typography>
            </Link>
          </Box>
          <Box
            px={[2, 3]}
            pt={1}
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            height={"100%"}
            className={classes.headerBar}
          >
            <Typography className={classes.title} variant="subtitle2" color="textPrimary">
              CUMULATIVE VIEW
            </Typography>
            <Typography className={classes.title} variant="h4" color="primary">
              {titleCase(testname)}
            </Typography>
          </Box>
        </AppBar>
        <Box>
          <PanelReports
            panelId={Number(panelId)}
            patientId={Number(patientId)}
            date={oldestValueAtomicDate}
            providers={providers}
            allPatients={allPatients && allPatients === "1" ? true : false}
          />
        </Box>
      </Fragment>
    );
  }
};

export default withRouter(CumulativeMobilePage);
