import { GetEpisodeReports_episodereports_reports as Report } from "./../components/PatientReportsCondense/types/GetEpisodeReports";
import { GetPatientEpisodes_patientepisodes_episodes_panels as Panel } from "../components/PatientDetailsPage/types/GetPatientEpisodes";
import toggles from "../toggles";
import authService from "../services/authService";
import { INTERUM_STATUS } from "./../constants";

const exportObj = async (
  allPatients: boolean,
  markAsViewed?: Function,
  auditLog?: Function,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateEpisodeList?: any,
  panel?: Panel | Report,
): Promise<void> => {
  if (allPatients && auditLog) {
    if (panel) {
      const value = JSON.stringify({
        reportid: panel.reportid,
        useragent: navigator.userAgent,
      });
      const action = "VIEW";
      await auditLog({
        variables: {
          audit: {
            value,
            action,
          },
        },
      });
    }
  }
  if (
    !markAsViewed ||
    !updateEpisodeList ||
    !panel ||
    !panel.reportid ||
    !(
      panel.interimStatus === INTERUM_STATUS.Final ||
      panel.interimStatus === INTERUM_STATUS.FinalCorrected ||
      panel.interimStatus === INTERUM_STATUS.Preliminary
    ) ||
    allPatients ||
    !auditLog
  )
    return;

  const reportId = `${panel.reportid}`;

  if (toggles.DEBUG_MARK_AS_VIEWED) console.log("test: ", panel.testname, reportId); //eslint-disable-line

  if (!panel.dateViewed && !authService.getDelegatedUser()) {
    if (toggles.DEBUG_MARK_AS_VIEWED) console.log(`Calling: markAsViewed({ variables: { id: ${reportId}} })`); //eslint-disable-line

    if (toggles.ENABLE_MARK_AS_VIEWED) {
      try {
        await markAsViewed({ variables: { id: reportId } });
        updateEpisodeList(reportId);
      } catch (e) {
        console.error(e);
      }
    }
  } else {
    const value = JSON.stringify({
      reportid: reportId,
      useragent: navigator.userAgent,
    });
    const action = authService.getDelegatedUser() ? "ADMIN_VIEW" : "VIEW";
    await auditLog({
      variables: {
        audit: {
          value,
          action,
        },
      },
    });
    if (toggles.DEBUG_MARK_AS_VIEWED) console.log(`already viewed: ${reportId}`); //eslint-disable-line
  }
};

export default exportObj;
