import React, { Fragment } from "react";
import {
  Box,
  TextField,
  Link,
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { RouteComponentProps, useHistory, useParams } from "react-router-dom";
import AuthLayout from "../common/AuthLayout/AuthLayout";
import DotIcon from "@mui/icons-material/FiberManualRecord";
import DoneIcon from "@mui/icons-material/Done";
import MainNavigation from "../MainNavigation/MainNavigation";
import styles from "../ChangePasswordPage/ChangePasswordPage.module.css";
import { ResetPasswordVariables, ResetPassword } from "./types/ResetPassword";
import { MutationTuple, useMutation, useApolloClient, useLazyQuery, LazyQueryExecFunction } from "@apollo/client";
import get from "lodash/get";
import ResetPasswordMutation from "./ResetPasswordMutation";
import { ApolloError } from "@apollo/client";
import {
  ChangePasswordFormValues,
  validatePassword,
  validationRules,
  convertError,
} from "../ChangePasswordPage/PasswordValidation";
import authService, { SET_AUTH } from "../../services/authService";
import jwt from "jsonwebtoken";
import { ApolloClient } from "@apollo/client";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import advancedSearch from "../../model/advanced-search";
import * as MFATypes from "../../model/MFATypes";
import GetOptionsQuery from "../App/GetOptionsQuery";
import { GetOptions } from "../App/types/GetOptions";
type ResetPasswordBoxType = {
  onSubmit: (token: string, newpassword: string) => Promise<void>;
  error?: ApolloError;
  loading?: boolean;
};

type ResetPasswordPathProps = {
  token: string;
};

type Token = {
  email: string;
  exp: number;
  username?: string;
};

export const ResetPasswordBox: React.FC<ResetPasswordBoxType> = (props) => {
  const { token }: ResetPasswordPathProps = useParams() as ResetPasswordPathProps;
  const { error, loading } = props;
  const hist = useHistory();

  const [values, setValues] = React.useState({
    password: "",
    passwordconfirm: "",
    showPassword: false,
    showPasswordConfirm: false,
  });

  let t: Token | null = null;
  try {
    t = jwt.decode(token) as Token;
    t.exp -= 300; // 5 minutes buffer, just to give the user some time to enter the new password
    if (new Date(t.exp * 1000) < new Date()) {
      // link expired
      t = null;
    }
  } catch {
    t = null;
  }

  if (!t) {
    hist.push("/expired");
    return null;
  }
  const email = t.email;
  const username = t.username;

  const initialValues: ChangePasswordFormValues = {
    oldpassword: "populated",
    password: "",
    passwordconfirm: "",
    message: "",
  };

  const submit = (values: ChangePasswordFormValues) => {
    props.onSubmit(token, values.password);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowPasswordConfrim = () => {
    setValues({ ...values, showPasswordConfirm: !values.showPasswordConfirm });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <AuthLayout title="Reset password" maxWidth={"972px"}>
      <Box mt={4}>
        <Formik initialValues={initialValues} validate={validatePassword} onSubmit={submit}>
          {({ errors, handleChange, handleBlur, handleSubmit, touched }) => {
            const handleCancel = () => {
              hist.push("/login");
            };
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <Typography>
                        Reset password for <b>{email ? email : username}</b>
                      </Typography>
                    </Box>
                    <Box mt={2}>
                      <TextField
                        id="password"
                        name="password"
                        fullWidth
                        label="New Password"
                        type={values.showPassword ? "text" : "password"}
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.password && touched.password}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          ),
                        }}
                      ></TextField>
                    </Box>

                    <Box mt={2}>
                      <TextField
                        id="passwordconfirm"
                        name="passwordconfirm"
                        fullWidth
                        label="Confirm password"
                        type={values.showPasswordConfirm ? "text" : "password"}
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.passwordconfirm && touched.passwordconfirm}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPasswordConfrim}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          ),
                        }}
                      ></TextField>
                    </Box>
                    <Box mb={2}>
                      {errors.message && touched.passwordconfirm && (
                        <Typography color="error">{errors.message}</Typography>
                      )}
                    </Box>
                    <Box>
                      {error &&
                        error.graphQLErrors.map(({ message }, i) => (
                          <Typography key={i} color="error">
                            {convertError(message)}
                          </Typography>
                        ))}
                    </Box>
                    <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                      <Link color="primary" onClick={handleCancel}>
                        Cancel
                      </Link>
                      <Button type="submit" color="primary" variant="contained" disabled={loading}>
                        Reset password and log in
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography>Password requirements</Typography>
                    <List className={styles.list} component="nav" aria-label="main mailbox folders">
                      {validationRules.map((v, i) => (
                        <ListItem disableGutters className={styles.listItem} key={i}>
                          <ListItemIcon className={styles.listIcon}>
                            {v.valid === 1 ? (
                              <DoneIcon className={styles.listIconDone} />
                            ) : (
                              <DotIcon className={`${styles.listIconDot} ${v.valid === -1 ? styles.invalid : ""} `} />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{ variant: "body1" }}
                            className={`${styles.listText} ${v.valid === -1 ? styles.invalid : ""}`}
                            primary={v.text}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </Box>
    </AuthLayout>
  );
};

const onSubmit =
  (
    location: RouteComponentProps["location"] | undefined,
    history: RouteComponentProps["history"] | undefined,
    resetPassword: MutationTuple<ResetPassword, ResetPasswordVariables>[0],
    client: ApolloClient<any>,
    siteOptions: LazyQueryExecFunction<GetOptions, Record<string, never>>,
  ) =>
  async (token: string, newpassword: string) => {
    const useragent = navigator.userAgent;
    const mobility = authService.isMobility();

    let from: any = null;

    const result = await resetPassword({
      variables: {
        input: {
          token,
          newpassword,
          useragent,
        },
      },
    });

    if (result.data) {
      authService.persistAllValues(result.data.changePasswordForToken);
      authService.clearDelegatedUser(); //Clear delegated user in any case if we logged in via normal login.

      if (mobility) {
        localStorage.setItem("x-object", btoa(newpassword));
      }

      client.cache.writeQuery({
        query: SET_AUTH,
        data: { auth: { isAuth: true, __typename: "Auth" } },
      });

      // Get site options
      const options = await siteOptions({});
      if (options.data) {
        authService.setSiteOptions(options.data.getOptions);
      }

      from = get(location, "state.from", "/patients");

      //For the default ward search we are including advancedSearch query string here
      if (authService.getDefaultWardCode()) {
        const wards = authService.getWards();

        const AdSearch: advancedSearch = new advancedSearch();
        AdSearch.wardCode = wards.find((w) => w.code === authService.getDefaultWardCode());
        const newquerystring = `?${AdSearch.toQueryString()}`;
        // eslint-disable-next-line no-const-assign
        from = get(location, "state.from") || `/patients${newquerystring}`;
      }

      const accessToken = get(result, "data.changePasswordForToken.accessToken", "");
      const t = jwt.decode(accessToken) as unknown as MFATypes.MFAToken;
      if (t.userMfaSetup.mfaEnrolled) {
        if (t.userMfaSetup.enrolments.filter((el) => el.mfaId > 1 && el.status === 2).length > 0) {
          authService.setLastViewed(typeof from !== "string" && from.pathname ? from.pathname : from);
          from = "/mfaauthentication";
        }
      } else {
        //Goes back to /enrolment
        from = "/enrolment";
      }
    }

    if (history !== undefined) {
      from = from ? from : get(location, "state.from") || "/patients";
      history.push(from);
    }
  };

const ResetPasswordPage: React.FC<any> = (props) => {
  const { location, history } = props;
  const [resetPassword, { loading, error }] = useMutation<ResetPassword, ResetPasswordVariables>(ResetPasswordMutation);
  const client: ApolloClient<any> = useApolloClient();
  const [siteOptions] = useLazyQuery<GetOptions, Record<string, never>>(GetOptionsQuery);
  if (error) {
    console.error("Error", error);
  }
  return (
    <Fragment>
      <MainNavigation hideTopMenu />
      <ResetPasswordBox
        onSubmit={onSubmit(location, history, resetPassword, client, siteOptions)}
        error={error}
        loading={loading}
      />
    </Fragment>
  );
};

export default ResetPasswordPage;
