import React, { useContext, createRef, useRef, useEffect, useState } from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import { Waypoint } from "react-waypoint";
import { CumulativeContext } from "../../context/CumulativeContext";
import setViewed from "../../utils/setViewed";
import { InsertLogs } from "../common/AuditLogs/types/InsertLogs";
import { MarkAsViewed } from "../common/MarkAsViewedMutation/types/MarkAsViewed";
import { GetEpisodeReports_episodereports_reports } from "../PatientReportsCondense/types/GetEpisodeReports";
import { GetCombinedReport_combinedReport } from "./types/GetCombinedReport";
import markAsViewedMutation from "../common/MarkAsViewedMutation/MarkAsViewedMutation";
import AuditLogMutation from "../common/AuditLogs/AuditLogMutation";
import { updateEpisodeReportsCache, updatePatientepisodesCache } from "../../utils/updateApolloCache";
import authService from "../../services/authService";
import { cloneDeep } from "lodash";
import ResultsCard from "./ResultsCard";
import DialogBoxComment from "../DialogBox/DialogBoxComment";
import { UpdateAtomicStatus, UpdateAtomicStatusVariables } from "./types/UpdateAtomicStatus";
import { AddAtomicComment, AddAtomicCommentVariables } from "./types/AddAtomicComment";
import UpdateAtomicStatusMutation from "./UpdateAtomicStatusMutation";
import AddAtomicCommentMutation from "./AddAtomicCommentMutation";
import { animateScroll } from "react-scroll";
import AppContext from "../../context/AppContext";
import LoadingDialog from "./LoadingDialog";
import * as Constants from "../../constants";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";
import { CommentDialog } from "../DialogBox/CommentDialog";

const SCROLL_DURATION = 0;
const RESET_LAB_NUMBER = "RESET";
const SCROLL_THRESHOLD_MILLISECONDS = 400;

type PatientDetailsProps = {
  patientId: number;
  labnoDigitOnly: string;
  allPatients: boolean;
  reports: GetEpisodeReports_episodereports_reports[] | undefined;
  initialLabNo: string;
  setInitialLabNo?: React.Dispatch<any>;
  sortBy?: string | null;
};

const PatientDetails: React.FC<PatientDetailsProps> = (props) => {
  const waypointTimeout: any = useRef();
  const scroll = useRef(false);
  scroll.current = false;

  const { reports: results, initialLabNo, setInitialLabNo, patientId, labnoDigitOnly, allPatients, sortBy } = props;
  const reports = useRef(results && cloneDeep(results));
  const [viewedReport, setViewedReport] = useState(initialLabNo);
  const client = useApolloClient();
  const { isDesktop } = useContext(AppContext);
  const {
    isCommentsOpen,
    setIsCommentsOpen,
    isPlainComment,
    setIsPlainComment,
    currentAtomic,
    currentCsStatus,
    atomics,
    setAtomics,
    isAtomicLoading,
    setIsAtomicLoading,
    random,
    currentReportId,
    repeatAtomics,
    setRepeatAtomics,
    showCommentsExtra,
  } = useContext(CumulativeContext);

  const [markAsViewed] = useMutation<MarkAsViewed>(markAsViewedMutation, {
    ignoreResults: true,
  });
  const [auditLog] = useMutation<InsertLogs>(AuditLogMutation, {
    ignoreResults: true,
  });

  const [
    updateAtomicStatusMutation,
    { data: updateAtomicStatusData, loading: isUpdateStatusLoading, error: updateAtomicStatusError },
  ] = useMutation<UpdateAtomicStatus, UpdateAtomicStatusVariables>(UpdateAtomicStatusMutation);

  const [
    updateAtomicCommentMutation,
    { data: updateComentData, loading: isUpdateCommentLoading, error: updateCommentError },
  ] = useMutation<AddAtomicComment, AddAtomicCommentVariables>(AddAtomicCommentMutation);

  const refs = atomics.reduce((acc: { [index: string]: any }, value1) => {
    acc[value1?.reportLabNumber ? value1.reportLabNumber : initialLabNo] = createRef();
    return acc;
  }, {});

  //update atomic loading spinner
  useEffect(() => {
    setIsAtomicLoading(isUpdateStatusLoading || isUpdateCommentLoading);
  }, [isUpdateStatusLoading, isUpdateCommentLoading]);

  //resolving Add Comment
  useEffect(() => {
    if (updateComentData && updateComentData.addAtomicComment) {
      const result = updateComentData.addAtomicComment;
      const newAtomics = cloneDeep(atomics);
      if (result) {
        newAtomics.forEach((newAtomic) => {
          newAtomic?.atomicData?.forEach((a) => {
            if (a) {
              if (
                newAtomic.reportId === currentReportId &&
                (a.atomicId ? a.atomicId : 0) === (currentAtomic && currentAtomic.atomicId ? currentAtomic.atomicId : 0)
              ) {
                a.atomicId = result.atomicId;
                a.csUserId = result.csUserId;
                a.csDatetime = result.csDatetime;
                a.csComment = result.comment;
                a.noActionRequired = result.noActionRequired;
                a.actionRequired = result.actionRequired;
                a.dCF = result.dCF;
              }

              if (newAtomic.reportId === currentReportId && a.atomicId === null) {
                a.atomicId = result.atomicId;
                a.csUserId = result.csUserId;
                a.csDatetime = result.csDatetime;
                a.csComment = result.comment;
                a.noActionRequired = result.noActionRequired;
                a.actionRequired = result.actionRequired;
                a.dCF = result.dCF;
              }
            }
          });
        });
        setAtomics(newAtomics);
      }
    }
  }, [updateComentData]);

  useEffect(() => {
    if (results && initialLabNo !== RESET_LAB_NUMBER) {
      const viewedNumber = initialLabNo || results[0].labnumber;
      if (viewedNumber) {
        setViewedReport(viewedNumber);
      }
    }

    if (refs) {
      const scrollElem: Element | null = document.querySelector("#scrollElemChild");

      if (!scrollElem) {
        const scrollBars = document.querySelector("#scrollBars");
        const scrollElem = scrollBars && scrollBars.children[0];
        if (scrollElem) scrollElem.id = "scrollElemChild";
      }

      const scrollToPanel = (id: any) => {
        const elem = refs[id] && refs[id].current;
        if (elem) {
          scroll.current = true;
          animateScroll.scrollTo(elem.offsetTop - 108, {
            duration: SCROLL_DURATION,
            containerId: "scrollElemChild",
          });

          setTimeout(() => {
            scroll.current = false;
            if (setInitialLabNo) {
              setInitialLabNo(RESET_LAB_NUMBER); // Reset the selected lab number
            }
          }, SCROLL_DURATION);
        }
      };

      if (initialLabNo && isDesktop) {
        scrollToPanel(initialLabNo);
      }
    }
  }, [updateAtomicStatusData, atomics, setAtomics, initialLabNo, refs, setInitialLabNo]);

  useEffect(() => {
    if (results && viewedReport !== RESET_LAB_NUMBER) {
      const report = results.find((result) => result.labnumber === viewedReport);
      setViewed(allPatients, markAsViewed, auditLog, onMarkAsViewed, report);
    }
  }, [viewedReport]);

  const updateReports = (reports: any, reportId: string) => {
    if (!reports.current) return;
    reports.current = reports.current.map((report: any) => {
      if (report.reportid === parseInt(reportId)) {
        return { ...report, dateViewed: "viewed" };
      }
      return report;
    });
  };

  const onMarkAsViewed = (reportId: string) => {
    updateReports(reports, reportId);

    updateEpisodeReportsCache(client, reportId, {
      patientId: patientId,
      providers: authService.getProviders(),
      labnoDigitOnly: labnoDigitOnly,
      allPatients: allPatients,
      includePdf: false,
      showRemovedPanels: authService.getShowDeletedPanels(),
    });

    updatePatientepisodesCache(client, reportId, {
      patientId: patientId,
      providers: authService.getProviders(),
      allPatients: allPatients,
      sortBy: sortBy,
      showRemovedPanels: authService.getShowDeletedPanels(),
    });
  };

  const debounceMarkAsViewed = (callback: Function) => {
    clearTimeout(waypointTimeout.current);
    waypointTimeout.current = setTimeout(() => callback(), SCROLL_THRESHOLD_MILLISECONDS);
  };

  const onWaypointEnter = (waypointEvent: Waypoint.CallbackArgs, index: number) => {
    if (!scroll.current) {
      if (waypointEvent.event) {
        if (waypointEvent.previousPosition === "below") {
          debounceMarkAsViewed(() =>
            setViewed(props.allPatients, markAsViewed, auditLog, onMarkAsViewed, results && results[index]),
          );
        }
      }
    }
  };

  const onWaypointLeave = (waypointEvent: Waypoint.CallbackArgs, index: number) => {
    if (!scroll.current && waypointEvent.event) {
      if (waypointEvent.currentPosition === "below" && index > 0) {
        clearTimeout(waypointTimeout.current);
        debounceMarkAsViewed(() =>
          setViewed(props.allPatients, markAsViewed, auditLog, onMarkAsViewed, results && results[index - 1]),
        );
      }
    }
  };

  const handleClose = () => {
    setIsCommentsOpen(false);
    setIsPlainComment(false);

    const newAtomics = cloneDeep(atomics);
    setAtomics(newAtomics);
  };

  const updateAtomicStatusHandler = (dlg: CommentDialog) => {
    if (currentAtomic && currentAtomic.atomicId) {
      updateAtomicStatusMutation({
        variables: {
          input: {
            atomicId: currentAtomic.atomicId,
            comment: dlg.comment,
            csStatus: currentCsStatus,
            csUserId: Number(authService.getUserId()),
            noActionRequired: dlg.noActionRequired,
            actionRequired: dlg.actionRequired,
            dCF: dlg.dCF,
          },
        },
      });
    }
    setIsCommentsOpen(false);
  };

  //resolving Update Atomic
  useEffect(() => {
    if (updateAtomicStatusData && updateAtomicStatusData.updateAtomicStatus) {
      const result = updateAtomicStatusData.updateAtomicStatus[0];
      if (result) {
        const newAtomics = cloneDeep(atomics);

        newAtomics.forEach((newAtomic) => {
          newAtomic?.atomicData?.forEach((a) => {
            if (a && a?.atomicId === currentAtomic?.atomicId) {
              a.csStatus = result.csStatus;
              a.csName = result.csName;
              a.csUserId = result.csUserId;
              a.csDatetime = result.csDatetime;
              a.csComment = result.comment;
              a.noActionRequired = result.noActionRequired;
              a.actionRequired = result.actionRequired;
              a.dCF = result.dCF;
            }
          });
        });
        setAtomics(newAtomics);
      }
    }
  }, [updateAtomicStatusData]);

  //handle errors
  if (updateAtomicStatusError || updateCommentError) {
    return <ErrorPopover />;
  }

  const updateAtomicCommentHandler = (dlg: CommentDialog) => {
    if ((currentAtomic && currentAtomic.atomicId) || currentReportId) {
      if (!showCommentsExtra) {
        const newObj: any = {};
        if (currentAtomic) {
          newObj.csRepeatComment = dlg.comment;
          newObj.atomicId = currentAtomic.atomicId;
          newObj.name = currentAtomic.name;
          const currentReportObj = results?.find((el) => {
            return el.reportid == currentReportId;
          });
          if (currentReportObj) {
            newObj.reportTestName = currentReportObj.testname;
          }
        }
        const newRepeatAtomics = [...repeatAtomics, newObj];
        setRepeatAtomics(newRepeatAtomics);
      }
      //only update comment if user put in text
      if (dlg.comment) {
        updateAtomicCommentMutation({
          variables: {
            input: {
              atomicId: Number(currentAtomic?.atomicId),
              comment: dlg.comment,
              noActionRequired: dlg.noActionRequired,
              actionRequired: dlg.actionRequired,
              dCF: dlg.dCF,
              username: authService.getUsername(),
              csUserId: Number(authService.getUserId()),
              reportId: currentReportId,
            },
          },
        });
      }
    }

    setIsCommentsOpen(false);
    setIsPlainComment(false);
  };

  const isOffline = () => {
    if (currentReportId) {
      const atomic = atomics.find((a) => a?.reportId === currentReportId);
      if (atomic && atomic.csPanelApprovalStatus === Constants.APPROVAL_STATUS.Offline) {
        return true;
      }
    }

    return false;
  };

  return (
    <div>
      {atomics.map((episode: GetCombinedReport_combinedReport | null, index: number) => {
        if (episode) {
          return (
            <Waypoint
              fireOnRapidScroll={false}
              key={index}
              bottomOffset={"45%"}
              onEnter={(event: Waypoint.CallbackArgs) => onWaypointEnter(event, index)}
              onLeave={(event: Waypoint.CallbackArgs) => onWaypointLeave(event, index)}
            >
              <div>
                <ResultsCard
                  episode={episode}
                  Key={index}
                  refs={refs}
                  allPatients={allPatients}
                  patientId={patientId}
                  sortBy={sortBy}
                ></ResultsCard>
              </div>
            </Waypoint>
          );
        }
      })}
      <DialogBoxComment
        open={isCommentsOpen}
        onClose={handleClose}
        onSubmit={(dlg: CommentDialog) => {
          if (isPlainComment) {
            updateAtomicCommentHandler(dlg);
          } else {
            updateAtomicStatusHandler(dlg);
          }
        }}
        atomic={currentAtomic}
        reportId={currentReportId}
        random={random}
        isOffline={isOffline()}
      />
      <LoadingDialog setOpen={isAtomicLoading} message="Applying changes to atomic, please wait..." />
    </div>
  );
};

export default PatientDetails;
