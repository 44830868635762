import React, { useContext, useEffect, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { Box, Typography, Button } from "@mui/material";
import styles from "./HospAdminUserListTable.module.css";
import authService from "../../services/authService";
import Loading from "../Loading/Loading";
import AppContext from "../../context/AppContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PullDownContent, PullToRefresh, RefreshContent, ReleaseContent } from "react-js-pull-to-refresh";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import hospitalUser from "./GetUserQuery";
import { GetHospitalUser, GetHospitalUser_hospitalReports_hospitalReports } from "./types/GetHospitalUser";
import ErrorPopover from "../common/ErrorPopover/ErrorPopover";

import { useQuery } from "@apollo/client";

import HospAdminUserListData from "./HospAdminUserListData";

const loadHospUser = false;

type HospAdminUserListTableProps = {
  history: RouteComponentProps["history"];
  searchText: string;
} & RouteComponentProps;

const HospAdminUserListTable: React.FC<HospAdminUserListTableProps> = (props) => {
  const { isDesktop } = useContext(AppContext);
  const [loadingmore, setLoadingmore] = useState(true);

  const hospid: string = authService.getHospID();
  const initStart = 1;
  const [start, setStart] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [dataCnt, setDataCnt] = useState(0);
  const [hospitalReports, setHospitalReports] = useState<GetHospitalUser_hospitalReports_hospitalReports[]>([]);

  const {
    data,
    loading,
    error: errorFetch,
    refetch,
  } = useQuery<GetHospitalUser>(hospitalUser, {
    variables: {
      hospid: hospid,
      start: initStart,
      pageSize: pageSize,
      search: props.searchText,
    },
    fetchPolicy: "network-only",
    // see the details about this problem here:
    // https://github.com/apollographql/apollo-client/issues/6327
    nextFetchPolicy: "cache-first",
  });

  const users = data && data.hospitalUsers && data.hospitalUsers.users ? data.hospitalUsers.users : [];

  useEffect(() => {
    if (data && data.hospitalUsers && data.hospitalUsers.users) {
      if (dataCnt === users.length) {
        setLoadingmore(false);
      }

      setHospitalReports(data.hospitalReports.hospitalReports);

      if (loadHospUser) refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, props.searchText, dataCnt, users.length, refetch]);

  if (errorFetch) {
    return <ErrorPopover />;
  }

  if (users.length === 0 && !loading) {
    return (
      <Box ml={isDesktop ? 13 : 1} className={styles.nodata}>
        <Typography variant="h4">
          No users found for <strong></strong>
        </Typography>
        <Typography>
          Tip: Try searching by firstname and surname using a comma, e.g. Smith, Robert Jones or using the advanced
          search
        </Typography>
      </Box>
    );
  }

  if ((!users || users.length === 0) && !loading) {
    return (
      <Box ml={isDesktop ? 13 : 1} className={styles.nodata}>
        <Typography variant="h4">No results found matching your search criteria</Typography>
      </Box>
    );
  }

  return (
    <>
      {loading && <Loading />}
      {authService.isMobility() ? (
        <PullToRefresh
          pullDownContent={<PullDownContent label="Pull down to refresh user list..." />}
          releaseContent={<ReleaseContent label="Release to refresh user list..." />}
          refreshContent={<RefreshContent />}
          pullDownThreshold={200}
          onRefresh={() => {
            refetch();
            return Promise.resolve();
          }}
          triggerHeight={50}
          backgroundColor="white"
          startInvisible={true}
        >
          <div style={{ textAlign: "center" }}>
            <ArrowDropDownIcon />
          </div>
          <HospAdminUserListData users={users} hospitalReports={hospitalReports} />
        </PullToRefresh>
      ) : (
        <HospAdminUserListData users={users} hospitalReports={hospitalReports} />
      )}

      {loadingmore && (
        <Box width={1} display="flex" justifyContent="center" my={3}>
          <Button
            variant="outlined"
            color="primary"
            className={styles.loadMoreButton}
            onClick={() => {
              const newStart = start + 1;
              setStart(newStart);
              setPageSize(pageSize * newStart);
              const newCnt = users.length;
              setDataCnt(newCnt);
            }}
            endIcon={<ExpandMoreIcon />}
          >
            Load more results
          </Button>
        </Box>
      )}
    </>
  );
};

export default withRouter(HospAdminUserListTable);
