import { ApolloError, useApolloClient, useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Grid,
  Typography,
  Checkbox,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import React, { Fragment, useContext, useState } from "react";
import authService from "../../services/authService";
import AuthLayout from "../common/AuthLayout/AuthLayout";
import MainNavigation from "../MainNavigation/MainNavigation";
import styles from "./DeleteAccountPage.module.css";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import { get } from "lodash";
import AppContext from "../../context/AppContext";
import { RemoveAccount } from "./types/RemoveAccount";
import RemoveAccountMutation from "./RemoveAccountMutation";
import SignOutMutation from "../TopMenu/SignOutMutation";
import { SignOut } from "../TopMenu/types/SignOut";
import { setSearchText } from "../PatientListPage/PatientListPage";
import DialogBoxTitle from "../CommonPopup/DialogBoxTitle";
// import Loading from "../Loading/Loading";

type AccountDeletionBoxType = {
  onSubmit: () => Promise<void>;
  error?: ApolloError;
  loading?: boolean;
  submitSuccess: boolean;
  errorMessage?: string;
};

export const AccountDeletionBox: React.FC<AccountDeletionBoxType> = (props) => {
  const { error, loading, submitSuccess } = props;

  const initialValues = {
    message: "",
  };

  const isDesktop = useContext(AppContext).isDesktop;
  const hist = useHistory();

  const [confirmAccountDeletion, setConfirmAccountDeletion] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const client = useApolloClient();
  const [confirmDeleteAccount, setConfirmDeleteAccount] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const submit = () => {
    if (!confirmAccountDeletion) {
      setErrorMessage("Please confirm accout deletion");
      return;
    }

    setErrorMessage("");
    setConfirmDeleteAccount(true);
  };

  const handleBack = () => {
    if (authService.getExpired()) {
      authService.clearAll();
      // TODO: shouldn't need to explicitly navigate to '/' if we are behind a <PrivateRoute />
      client.resetStore();
      hist.push("/login");
    } else if (get(hist.location, "state.from")) {
      hist.push(get(hist.location, "state.from", ""));
    } else {
      hist.goBack();
    }
  };

  const handleDeleteAccount = () => {
    setDisableButton(true);
    props.onSubmit();
  };

  return (
    <Grid container className={isDesktop ? styles.mainBox : styles.mainBoxM}>
      <Grid item xs={12}>
        <AuthLayout title="Delete Account" maxWidth={"900px"}>
          <Box mt={5}>
            <Box textAlign={"right"} mb={5} mt={-8}>
              <Link color="primary" onClick={handleBack} className={styles.backLink}>
                Back
              </Link>
            </Box>
            <Formik initialValues={initialValues} onSubmit={submit}>
              {({ errors, handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <span>
                      <Box>
                        {error &&
                          error.graphQLErrors.map(({ message }, i) => (
                            <Typography key={i} color="error">
                              {message}
                            </Typography>
                          ))}
                      </Box>
                      <Box>{errors.message && <Typography color="error">{errors.message}</Typography>}</Box>
                      <Box>
                        <Box mt={2} alignItems="center">
                          <Typography variant="h6">
                            <b>{authService.getUsername()}</b>, are you sure you want to permanently delete your
                            eResults account?
                          </Typography>
                          <ul>
                            <li>
                              <Typography className={styles.ullist}>
                                Deleting your account is not reversible. Once deleted we cannot retrieve it.
                              </Typography>
                            </li>
                            <li>
                              <Typography className={styles.ullist}>
                                You will loose all access to the eResults portal, including any activities, functions or
                                programs that were contained within the portal.
                              </Typography>
                            </li>
                            <li>
                              <Typography className={styles.ullist}>
                                You will stop receiving all email and/or mobile notifications that you have opted into
                                through eResults.
                              </Typography>
                            </li>
                            <li>
                              <Typography className={styles.ullist}>
                                Although your account will be permanently removed, eResults may still retain relevant
                                data to preserve data consistently.
                              </Typography>
                            </li>
                            <li>
                              <Typography className={styles.ullist}>
                                Your data will be protected and not be shared outside the domain of eResults.
                              </Typography>
                            </li>
                          </ul>
                        </Box>
                        <Box style={{ marginTop: "20px" }}>
                          <hr />
                        </Box>
                      </Box>
                    </span>
                    <Box mt={2} display="block" justifyContent="space-between" alignItems="center">
                      <Typography color="primary" className={styles.subHeading}>
                        Please acknowledge and confirm:
                      </Typography>
                      <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                        <Checkbox
                          checked={confirmAccountDeletion}
                          name="confirmAccountDeletion"
                          color="primary"
                          onChange={(_, checked) => {
                            setConfirmAccountDeletion(checked);
                          }}
                        />
                        <Typography>
                          I understand that after acknowledging this disclaimer, my account will be deleted with
                          immediate effect and that I will no longer have access to the eResults portal. As a
                          confimation of the deletion of my account, I will receive a notification via email when it is
                          complete.
                        </Typography>
                      </Box>
                    </Box>
                    <Box mt={2} display="flex" justifyContent="center">
                      {<Typography color="error">{errorMessage}</Typography>}
                    </Box>
                    <Box mt={2} display="flex" justifyContent="flex-end" alignItems="center">
                      {submitSuccess && <Box className={styles.successMessage}>Successfully saved your changes.</Box>}
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={loading || !confirmAccountDeletion}
                      >
                        Confirm
                      </Button>
                    </Box>
                  </form>
                );
              }}
            </Formik>
          </Box>
          <Dialog open={confirmDeleteAccount} onClose={() => setConfirmDeleteAccount(false)}>
            <DialogTitle>
              <DialogBoxTitle title="Please confirm" width={260} />
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to permanently delete your eResults account?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button disabled={disableButton} className={styles.Btn} onClick={() => setConfirmDeleteAccount(false)}>
                No
              </Button>
              <Button disabled={disableButton} className={styles.Btn} onClick={handleDeleteAccount} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </AuthLayout>
      </Grid>
    </Grid>
  );
};

const DeleteAccountPage: React.FC<any> = () => {
  const [success, setSuccess] = useState(false);
  const [removeAccount, { loading, error }] = useMutation<RemoveAccount>(RemoveAccountMutation);
  const history = useHistory();
  const mobility = authService.isMobility();
  const client = useApolloClient();

  const [signOut] = useMutation<SignOut>(SignOutMutation, {
    ignoreResults: true,
  });

  if (error) {
    console.error("Error", error);
  }

  const onSubmit = () => async () => {
    try {
      setSuccess(false);

      await removeAccount().then(async (response) => {
        if (response.data?.removeAccount) {
          if (authService.isCognito()) {
            authService.clearAll();
            const signoutUrl = import.meta.env.VITE_LOGOUT_URL;
            if (signoutUrl) {
              window.location.replace(`${signoutUrl}`);
            }
          } else {
            await signOut({});

            authService.clearAll();
            client.resetStore();
            setSearchText("");
            localStorage.removeItem("searchtext");

            if (mobility) {
              history.push("/login?logout");
            } else {
              history.push("/login");
            }
          }
        }
      });
    } catch {
      setTimeout(function () {
        setSuccess(false);
      }, 3000);
    }
  };

  return (
    <Fragment>
      <MainNavigation hideTopMenu />
      <AccountDeletionBox onSubmit={onSubmit()} error={error} loading={loading} submitSuccess={success} />
    </Fragment>
  );
};

export default DeleteAccountPage;
